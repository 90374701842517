import React, { useEffect, useState,useRef  } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function EditAssets({ assetData, onClose, id, row }) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  // State declarations
  const [formData, setFormData] = useState({
    assets_category_id: "",
    item_code: "",
    acquisiton_date: "",
    acquisition_cost: "",
    supplier: "",
    asset_name: "",
    description: "",
    purchase_invoice_id: "",
    warranty_expiry_date: "",
    department: "",
    depreciation_rate: "",
    parent_assets_id: "",
    assetsFile: "",
  });

  const [assetType, setAssetType] = useState("new");
  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [parentNames, setParentNames] = useState([]);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const userId = Cookies.get("employee_no");

  useEffect(() => {
    if (row) {
      console.log(row.id);
      setFormData({
        id: row.id || "",
        assets_category_id: row.assets_category_id || "",
        item_code: row.item_code || "",
        acquisiton_date: row.acquisiton_date
          ? new Date(row.acquisiton_date).toISOString().split("T")[0]
          : "",
        acquisition_cost: row.acquisition_cost || "",
        supplier: row.supplier || "",
        asset_name: row.asset_name || "",
        description: row.description || "",
        purchase_invoice_id: row.purchase_invoice_id || "",
        warranty_expiry_date: row.warranty_expiry_date
          ? new Date(row.warranty_expiry_date).toISOString().split("T")[0]
          : "",
        department: row.department || "",
        depreciation_rate: row.depreciation_rate || "",
        parent_assets_id: row.parent_assets_id || "",
        assetsFile: row.assetsFile || "",
      });
    }
  }, [row]);
  // Fetch categories and departments
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/assets/assets/getAssetsCategoryIDs`
        );
        setCategories(response.data);

        // Pre-select the category if available
        if (formData.assets_category_id) {
          fetchParentNames(formData.assets_category_id); // Load parent assets for the category
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchDepartments = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/assets/assets/getDepartments`
        );
        setDepartments(response.data?.data || []);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    const fetchParentNames = async (categoryId) => {
      try {
        const response = await axios.get(
          `${backendUrl}/assets/assets/getAllAssetsNameByCategoryID`,
          { params: { categoryID: categoryId } }
        );
        setParentNames(response.data);
      } catch (error) {
        console.error("Error fetching parent names:", error);
      }
    };

    if (assetData) {
      setFormData(assetData); // Populate form with selected row data
    }

    fetchCategories();
    fetchDepartments();
  }, [backendUrl, assetData, id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "assets_category_id") {
      fetchParentNames(value);
    }
  };
  const fetchParentNames = async (categoryId) => {
    try {
      const response = await axios.get(
        `${backendUrl}/assets/assets/getAllAssetsNameByCategoryID`,
        { params: { categoryID: categoryId } }
      );
      setParentNames(response.data);
    } catch (error) {
      console.error("Error fetching parent names:", error);
    }
  };

  useEffect(() => {
    console.log("Form data after update:", formData);
  }, [formData]);

  const handleClick = () => {
    fileInputRef.current.click();  // Trigger the file input click
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Log the selected file
    console.log("Selected file:", file);

    if (file) {
      // Validate file type (if needed)
      if (file.type !== "application/pdf") {
        console.log("File type is not PDF:", file.type);
        alert("Only PDF files are allowed. Please upload a valid PDF.");
        return;
      }

      // Update formData with the selected file
      setFormData((prevDetails) => ({
        ...prevDetails,
        assetsFile: file,
      }));

      console.log("Form data after update:", formData); // Log formData after update
    }
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault(); // Prevent form default behavior

    // Validate required fields
    const requiredFields = [
      "assets_category_id",
      "item_code",
      "acquisition_cost",
      "supplier",
      "asset_name",
      "description",
      "purchase_invoice_id",
      "warranty_expiry_date",
    ];

    if (assetType === "new") {
      requiredFields.push("department", "depreciation_rate");
    }

    // Ensure all required fields are filled
    for (const field of requiredFields) {
      if (!formData[field]) {
        alert(`Please fill in the ${field.replace("_", " ")} field.`);
        return;
      }
    }

    if (!formData.id) {
      alert("Asset ID is missing.");
      return;
    }

    try {
      // Create FormData object for submission
      const submissionData = new FormData();

      // Append all non-empty form data
      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null && value !== "") {
          submissionData.append(key, value);
        }
      });

      // Check if file is selected before appending it
   
      // Add other data to FormData
      submissionData.append(
        "isExistingAsset",
        assetType === "existing" ? "Existing" : "New"
      );
      submissionData.append("created_by", userId || "defaultUserId");

      // Log the submission for debugging
      console.log(
        "Request URL:",
        `${backendUrl}/assets/assets/updateAssets/${formData.id}`
      );
      for (let pair of submissionData.entries()) {
        console.log(pair[0] + ":", pair[1]);
      }

      // Make the PUT request to update asset data
      const response = await axios.put(
        `${backendUrl}/assets/assets/updateAssets/${formData.id}`,
        submissionData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      alert("Asset updated successfully!");
      setNotification({
        message: "Asset updated successfully!",
        type: "success",
      });

      setTimeout(() => {
        if (typeof onClose === "function") onClose();
      }, 2000);
    } catch (error) {
      console.error("Error updating asset:", error);
      setNotification({
        message: error.response?.data?.error || "An unexpected error occurred.",
        type: "error",
      });
    }
  };

  const handleClose = () => {
    if (onClose) onClose();
  };
  const handleEditModalClose = (e) => {
    if (e) e.preventDefault();
    if (e) e.stopPropagation();
    if (onClose) onClose(); // Close modal logic
    setIsEditModalOpen(false); // Close the modal
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
      {notification.message && (
        <div
          className={`${
            notification.type === "success" ? "bg-green-500" : "bg-red-500"
          } text-white text-center py-2 rounded mb-4`}
        >
          {notification.message}
        </div>
      )}
      <div className="max-w-8xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4 text-center">Edit Asset</h2>

        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-4 w-2/3 mx-auto">
            <div className="w-1/3">
              <label htmlFor="asset_type" className="block text-gray-500 mb-2">
                Asset Type
              </label>
              <select
                id="asset_type"
                name="asset_type"
                className="w-full p-2 border rounded-lg"
                value={assetType}
                onChange={(e) => setAssetType(e.target.value)}
              >
                <option value="new">New Asset</option>
                <option value="existing">Existing Asset</option>
              </select>
            </div>
          </div>
        </div>

        <form className="px-28 mt-8">
          <div className="grid grid-cols-3 gap-6 text-[20px]">
            <div className="w-full">
              <label className="block text-gray-500 mb-2">
                Asset Category ID
              </label>
              <select
                name="assets_category_id"
                className="w-full p-2 border rounded-lg"
                value={formData.assets_category_id || ""} // Ensure correct value
                onChange={handleInputChange}
              >
                <option value="">-- Select Category ID --</option>
                {categories.map((category) => (
                  <option key={category.value} value={category.value}>
                    {category.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="w-full">
              <label className="block text-gray-700 mb-2">Item Code*</label>
              <input
                type="text"
                name="item_code"
                value={formData.item_code}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            {assetType === "existing" && (
              <div className="w-full">
                <label
                  htmlFor="parent_assets_id"
                  className="block text-gray-500 mb-2"
                >
                  Parent Asset Id
                </label>
                <select
                  id="parent_assets_id"
                  name="parent_assets_id"
                  className="w-full p-2 border rounded-lg"
                  onChange={handleInputChange}
                  value={formData.parent_assets_id || ""}
                >
                  <option value="">-- Select Parent Id --</option>
                  {parentNames
                    .filter((parent) => parent.label) // Exclude entries with null labels
                    .map((parent) => (
                      <option key={parent.value} value={parent.value}>
                        {parent.label}
                      </option>
                    ))}
                </select>
              </div>
            )}

            <div className="w-full">
              <label className="block text-gray-700 mb-2">
                Acquisition Date*
              </label>
              <input
                type="date"
                name="acquisiton_date"
                value={formData.acquisiton_date}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="w-full">
              <label className="block text-gray-700 mb-2">
                Acquisition Cost*
              </label>
              <input
                type="number"
                name="acquisition_cost"
                value={formData.acquisition_cost}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="w-full">
              <label className="block text-gray-700 mb-2">Supplier*</label>
              <input
                type="text"
                name="supplier"
                value={formData.supplier}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="w-full">
              <label className="block text-gray-700 mb-2">Asset Name*</label>
              <input
                type="text"
                name="asset_name"
                value={formData.asset_name}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="w-full">
              <label className="block text-gray-700 mb-2">Description*</label>
              <input
                type="text"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="w-full">
              <label className="block text-gray-700 mb-2">
                Purchase Invoice ID*
              </label>
              <input
                type="text"
                name="purchase_invoice_id"
                value={formData.purchase_invoice_id}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="w-full">
              <label className="block text-gray-700 mb-2">
                Warranty Expiry Date*
              </label>
              <input
                type="date"
                name="warranty_expiry_date"
                value={formData.warranty_expiry_date}
                onChange={handleInputChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            {assetType === "new" && (
              <>
                <div className="w-full">
                  <label className="block text-gray-500 mb-2">Department</label>
                  <select
                    name="department"
                    className="w-full p-2 border rounded-lg"
                    value={formData.department || ""}
                    onChange={handleInputChange}
                  >
                    <option value="">-- Select Department --</option>
                    {departments.map((dept) => (
                      <option key={dept.department} value={dept.department}>
                        {dept.department}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-full">
                  <label className="block text-gray-700 mb-2">
                    Depreciation Rate*
                  </label>
                  <input
                    type="text"
                    name="depreciation_rate"
                    value={formData.depreciation_rate}
                    onChange={handleInputChange}
                    className="w-full p-2 border border-gray-300 rounded"
                  />
                </div>
              </>
            )}
          </div>{" "}
          <div className="w-full mt-6">
            
           <button type="button" onClick={handleClick} className="px-4 py-2 bg-black text-white rounded-md cursor-pointer">
        Upload File
      </button>
            <input
              id="assetsFile"
              type="file"
              name="assetsFile"
              className="hidden"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
          </div>{" "}
          <div className="flex justify-end mt-4">
            <button
              className="px-4 py-2 bg-red-500 text-white rounded-md mt-12 grid justify-items-end"
              onClick={handleClose}
            >
              Close
            </button>
            <span className="ml-4"></span>
            <button
              type="button"
              className="px-4 py-2 bg-black text-white rounded-md mt-12 grid justify-items-end"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </form>
      </div>
      <div>
        <button onClick={() => setIsModalOpen(true)}></button>
        {isModalOpen && (
          <EditAssets
            id={selectedId} // Ensure `selectedId` is defined
            onClose={handleEditModalClose} // Pass the `handleClose` function
          />
        )}
      </div>
      );
    </div>
  );
}

export default EditAssets;
