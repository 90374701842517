import React, { useState } from "react";
import axios from "axios";
import Notification from "../../../components/notification/notification";
import Cookies from "js-cookie";

const FileUpload = ({ setUploadedFiles, onClose }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const userId = Cookies.get("employee_no"); // Fetch the logged-in user's ID from cookies

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  const handleUpload = async () => {
    if (!userId) {
      setNotification({
        message: "User ID is required to upload files.",
        type: "error",
      });
      return;
    }

    setUploading(true);

    // Create a FormData object to send files and additional data
    const formData = new FormData();

    // Append the logged-in user's ID to the "created_by" field
    formData.append("created_by", userId);

    // Append each file
    files.forEach((file) => {
      formData.append("file", file);
    });

    try {
      const response = await axios.post(
        `${backendUrl}/budget/budgetBulkUpload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data.message);
      setNotification({
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      console.error("Error during file upload:", error);
      setNotification({
        message: error.response?.data?.error || "An error occurred.",
        type: "error",
      });
    } finally {
      setUploading(false);
      setFiles([]);
      onClose(); // Close the modal after upload
    }
  };

  return (
    <div className="max-w-md p-6 mx-auto mt-10 border rounded-lg shadow-lg">
      <h2 className="mb-4 text-2xl font-bold text-center">Upload</h2>

      {/* Notification Alert */}
      <Notification
        message={notification.message}
        type={notification.type}
        onClose={handleCloseNotification}
      />

      {/* File Upload Section */}
      <div className="p-6 text-center border-2 border-green-500 border-dashed rounded-lg">
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="hidden"
          id="fileInput"
        />

        <label
          htmlFor="fileInput"
          className="block text-green-600 cursor-pointer"
        >
          <div className="flex flex-col items-center justify-center">
            <div className="mb-2 text-3xl">📂</div>
            <div className="text-lg font-medium">
              Drag & drop files or <span className="underline">Browse</span>
            </div>
            <div className="mt-1 text-sm text-gray-500">
              Supported format: PDF/CSV
            </div>
          </div>
        </label>
      </div>

      {/* Upload Button */}
      <button
        onClick={handleUpload}
        className="w-full py-2 mt-6 text-white transition bg-green-600 rounded-lg hover:bg-green-700"
        disabled={!files.length} // Disable if no files selected
      >
        {uploading ? "Uploading..." : "Upload Files"}
      </button>

      {uploading && (
        <div className="mt-4 text-center">
          <p>
            Uploading - {files.length} {files.length === 1 ? "file" : "files"}
          </p>
          <div className="relative pt-1">
            <div className="flex items-center justify-between mb-2">
              <div className="text-right">
                <span className="inline-block text-xs font-semibold text-green-600">
                  Progress
                </span>
              </div>
            </div>
            <div className="flex h-2 mb-4 overflow-hidden text-xs bg-green-200 rounded">
              <div
                style={{ width: "100%" }}
                className="flex flex-col justify-center text-center text-white bg-green-500 shadow-none whitespace-nowrap"
              ></div>
            </div>
          </div>
        </div>
      )}

      {files.length > 0 && (
        <div className="mt-6">
          <h3 className="mb-2 text-xl font-bold">Files to Upload</h3>
          {files.map((file, index) => (
            <div
              key={index}
              className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
            >
              <span>{file.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
