import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import * as XLSX from "xlsx";

const CustomerSubLedger = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    const { customer_id } = useParams();
    const [subLedgerEntries, setSubLedgerEntries] = useState([]);
    const [filters, setFilters] = useState({
        start_date: "",
        end_date: "",
    });
    const [pagination, setPagination] = useState({
        page: 1,
        page_size: 20,
        total: 0,
        total_pages: 0,
    });

    const fetchSubLedger = async () => {
        try {
            const response = await axios.get(
                `${backendUrl}/receivable/customer/getCustomerSubLedger/${customer_id}`,
                {
                    params: {
                        ...filters,
                        page: pagination.page,
                        page_size: pagination.page_size,
                    },
                }
            );

            const { subLedgerEntries, pagination: responsePagination } =
                response.data.response;

            setSubLedgerEntries(subLedgerEntries || []);
            setPagination((prev) => ({
                ...prev,
                total: responsePagination.total,
                total_pages: responsePagination.total_pages,
            }));
        } catch (error) {
            console.error("Error fetching sub-ledger:", error);
            setSubLedgerEntries([]);
        }
    };

    useEffect(() => {
        fetchSubLedger();
    }, [customer_id, filters, pagination.page, pagination.page_size]);

    const handleDateChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({ ...prev, [name]: value }));
    };

    const handlePageChange = (newPage) => {
        setPagination((prev) => ({ ...prev, page: newPage }));
    };

    const handlePageSizeChange = (e) => {
        setPagination((prev) => ({
            ...prev,
            page_size: parseInt(e.target.value, 10),
            page: 1,
        }));
    };

    const exportToExcel = () => {
        if (subLedgerEntries.length > 0) {
            const data = subLedgerEntries.map((entry) => ({
                "Transaction Date": new Date(entry.date).toLocaleDateString(),
                "Transaction ID": entry.transactionId,
                Description: entry.description,
                Origin: entry.origin,
                "Related ID": entry.relatedId,
                "Related Date": new Date(entry.relatedDate).toLocaleDateString(),
                Amount: entry.amount,
                Type: entry.type,
                Balance: entry.balance,
            }));

            const worksheet = XLSX.utils.json_to_sheet(data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "CustomerSubLedger");

            XLSX.writeFile(workbook, `CustomerSubLedger_${customer_id}.xlsx`);
        }
    };

    return (
        <div className="mx-10 mt-5">
            <h2 className="text-3xl font-semibold mb-4">Customer Sub-Ledger</h2>

            {/* Date Range Filters */}
            <div className="flex flex-wrap gap-4 mb-5">
                <input
                    type="date"
                    name="start_date"
                    value={filters.start_date}
                    onChange={handleDateChange}
                    className="border p-2 rounded"
                />
                <input
                    type="date"
                    name="end_date"
                    value={filters.end_date}
                    onChange={handleDateChange}
                    className="border p-2 rounded"
                />
                <button
                    onClick={fetchSubLedger}
                    className="bg-black text-white px-4 py-2 rounded"
                >
                    Apply Filters
                </button>
                <button
                    onClick={exportToExcel}
                    className="bg-black text-white px-4 py-2 rounded ml-auto"
                >
                    Export to Excel
                </button>
            </div>

            {/* Sub-Ledger Table */}
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                <tr>
                    <th className="px-4 py-2 border">Transaction Date</th>
                    <th className="px-4 py-2 border">Transaction ID</th>
                    <th className="px-4 py-2 border">Description</th>
                    <th className="px-4 py-2 border">Origin</th>
                    <th className="px-4 py-2 border">Related ID</th>
                    <th className="px-4 py-2 border">Related Date</th>
                    <th className="px-4 py-2 border">Amount</th>
                    <th className="px-4 py-2 border">Type</th>
                    <th className="px-4 py-2 border">Balance</th>
                </tr>
                </thead>
                <tbody>
                {subLedgerEntries.map((entry, index) => (
                    <tr key={index}>
                        <td className="px-4 py-2 border">
                            {new Date(entry.date).toLocaleDateString()}
                        </td>
                        <td className="px-4 py-2 border">{entry.transactionId}</td>
                        <td className="px-4 py-2 border">{entry.description}</td>
                        <td className="px-4 py-2 border">{entry.origin}</td>
                        <td className="px-4 py-2 border">{entry.relatedId}</td>
                        <td className="px-4 py-2 border">
                            {new Date(entry.relatedDate).toLocaleDateString()}
                        </td>
                        <td className="px-4 py-2 border text-right">
                            {parseFloat(entry.amount).toLocaleString()}
                        </td>
                        <td className="px-4 py-2 border text-right">{entry.type}</td>
                        <td className="px-4 py-2 border text-right">
                            {parseFloat(entry.balance).toLocaleString()}
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex justify-between items-center mt-4">
                <div>
                    Page {pagination.page} of {pagination.total_pages}
                </div>
                <div className="flex items-center gap-2">
                    <label htmlFor="pageSize">Rows per page:</label>
                    <select
                        id="pageSize"
                        value={pagination.page_size}
                        onChange={handlePageSizeChange}
                        className="border p-1 rounded"
                    >
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div>
                <div className="flex gap-2">
                    <button
                        onClick={() => handlePageChange(Math.max(1, pagination.page - 1))}
                        className="border px-3 py-1 rounded"
                        disabled={pagination.page === 1}
                    >
                        Previous
                    </button>
                    <button
                        onClick={() =>
                            handlePageChange(
                                Math.min(pagination.total_pages, pagination.page + 1)
                            )
                        }
                        className="border px-3 py-1 rounded"
                        disabled={pagination.page === pagination.total_pages}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CustomerSubLedger;
