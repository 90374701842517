import React, { useEffect, useRef, useState } from "react";
import { IoAdd } from "react-icons/io5";
import { FaEdit, FaTrash } from "react-icons/fa";
import axios from "axios";
import Notification from "../../../components/notification/notification";
// import Add_purchase_popup from "./add_purchase_invoice_popup";
// import Edit_purchase_popup from "./edit_purchase_invoice_popup";

const Assigen_account = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;

  const [seletedValue, setSeletedValue] = useState({
    account_id: null,
    party_type_id: null,
  });

  const [createdValue, setCreatedValue] = useState({
    account_id: null,
    party_type_id: null,
  });

  const [account, setAccount] = useState();
  const [account2, setAccount2] = useState();
  const [party, setParty] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default rows per page
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    page_size: 20,
    total: 0,
    total_pages: 0,
  });

  const [notification, setNotification] = useState({ message: "", type: "" });

  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);

  const actionsRef = useRef(null); // Ref for the "actions" element
  const tableRef = useRef(null); // Ref for the table element

  const handleRowSelect = (id) => {
    if (selectedRow === id) {
      setSelectedRow(null);
      setDropdownVisible(false);
    } else {
      setSelectedRow(id);
      setDropdownVisible(true);
    }
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const editePopup = () => {
    setIsOpenEdit(!isOpenEdit);
  };

  const handleEdit = () => {
    console.log("Edit row: ", selectedRow);
    setIsOpenEdit(true);
  };

  const handleDelete = async () => {
    console.log("Delete row: ", selectedRow);
    try {
      const response = await axios.put(
        `${backendUrl}/account/getFirstAndSecondLevelAccTypes`
      );
    } catch (error) {
      console.error("Error fetching sample IDs:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeletedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setCreatedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle fetching table data
  const getTableData = async () => {
    try {
      const params = {
        page_size: rowsPerPage,
        page: pagination.current_page,
        ...seletedValue, // Include your filter parameters
      };

      const response = await axios.get(
        `${backendUrl}/account/getAllAccountPTAssociations`,
        { params }
      );

      console.log("-----assigen_account-------");
      console.log(response.data);
      console.log("-----assigen_account-------");

      // Ensure you set both data and pagination state
      if (response.data) {
        console.log(response.data);
        setTableData(response.data.data); // Set the data array
        setPagination(response.data.pagination); // Set the pagination info
      } else {
        console.error("Unexpected response format:", response.data);
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching sample details:", error);
      setTableData([]);
    }
  };
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1, // Reset to first page whenever rows per page changes
    }));
  };

// Function to handle removing assigned account
const removeAssignedAccount = async (row) => {
  const params = {
    account_id: row.account_id,
    party_type_id: row.party_type_id,
  };

  try {

    const response = await axios.delete(
      `${backendUrl}/account/removeAssignedAccFromPT`,
      {
        data: params, // Pass the data here for DELETE requests
      }
    );

    setNotification({
      message: response.data.message,
      type: "success",
    });
    getTableData();
  } catch (error) {
    setNotification({
      message: error.data.message,
      type: "error",
    });
    console.log("Error removing the assigned account:", error);
  }
};


  // Handler for closing dropdown and deselecting row on outside click
  const handleClickOutside = (event) => {
    if (actionsRef.current && !actionsRef.current.contains(event.target)) {
      setSelectedRow(null);
      setDropdownVisible(false);
    }
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  // Reset Form
  const handleResetForm = () => {
    setIsResetConfirmationOpen(false);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleSaveForm = async () => {
    console.log(seletedValue);
    try {
      const response = await axios.post(
        `${backendUrl}/account/assignAccountsToPartyTypes`,
        createdValue
      );

      // console.log(response.data.message)
      setNotification({
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      console.error("Error", error);
      setNotification({
        message: error.response.data.error,
        type: "error",
      });
    }

    getTableData();
    setIsSaveConfirmationOpen(false);
    
  };

  const getAccount = async () => {
   
    try {
      const response = await axios.get(
        `${backendUrl}/account/getNotAssignedAccsForSelectedPT/${createdValue.party_type_id}`
      );
      setAccount(response.data);
    } catch (error) {
      console.error("Error fetching sample IDs:", error);
    }
  };

  const getparty = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/account/getAllPartyTypes`
      );

      setParty(response.data);
    } catch (error) {
      console.error("Error fetching sample IDs:", error);
    }
  };

  const handleButtonClick = () => {
    setIsSaveConfirmationOpen(true);
  };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };


  const getAllAccont = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/account/getAllAssignedAccs`
      );

      console.log(response.data)
      setAccount2(response.data);
    } catch (error) {
      console.error("Error fetching sample IDs:", error);
    }
  }

  // Close dropdown and deselect row if clicking outside the table
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); //

  // Call the API whenever rowsPerPage or currentPage changes
  useEffect(() => {
    getTableData();
    // console.log(seletedValue)
  }, [rowsPerPage, pagination.current_page, seletedValue]);

  useEffect(() => {
    getparty();
    getAllAccont()
  }, []);

  useEffect(() => {
    getAccount();
  }, [createdValue.party_type_id]);

  return (
    <div className="mx-10 mt-5 text-xl">
      <div className="overflow-visible">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold text-black">
              Assign Accounts to a Party Type
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col md:flex-row md:items-center md:gap-4">
              {/* Party Type */}
              <div className="w-full md:w-[500px] relative">
                <label
                  htmlFor="party_type_id"
                  className="block mb-2 text-sm font-medium text-black"
                >
                  Party Type
                </label>
                <select
                  id="party_type_id"
                  name="party_type_id"
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                  onChange={handleChange2}
                  value={createdValue.party_type_id}
                >
                  <option value=""></option>
                  {party && party.length > 0 ? (
                    party.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.party_type}
                      </option>
                    ))
                  ) : (
                    <option disabled>No Account available</option>
                  )}
                </select>
              </div>

              {/* Account To Assign */}
              {createdValue.party_type_id !== null ? (
                <div className="w-full md:w-[500px] relative">
                  <label
                    htmlFor="accountToAssign"
                    className="block mb-2 text-sm font-medium text-black"
                  >
                    Account To Assign
                  </label>
                  <select
                    id="account_id"
                    name="account_id"
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                    onChange={handleChange2}
                     value={createdValue.account_id}
                  >
                    {account && account.length > 0 ? (
                      account.map((type) => (
                        <option key={type.account_id} value={type.account_id}>
                          {type.account_name}
                        </option>
                      ))
                    ) : (
                      <option disabled>No accounts available</option>
                    )}
                  </select>
                </div>
              ) : (
                <div className="w-full md:w-[500px] relative">
                  <label
                    htmlFor="accountToAssign"
                    className="block mb-2 text-sm font-medium text-black"
                  >
                    Account To Assign
                  </label>
                  <select
                    id="account_id"
                    name="account_id"
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                    disabled
                  >
                    <option value="">Select a party type first</option>
                  </select>
                </div>
              )}
            </div>
            {/* Notification Alert */}
            <Notification
              message={notification.message}
              type={notification.type}
              onClose={handleCloseNotification}
            />

            {/* Button aligned to the right */}
            <div className="flex justify-end w-full mt-4 md:mt-0">
              <button
                type="button"
                className="p-2 px-4 bg-green-600 text-white rounded hover:bg-green-700 transition-colors"
                onClick={handleButtonClick}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr className="mt-10"></hr>
      <div className="my-4 overflow-x-auto mt-10" ref={tableRef}>
        {/* Scrollable Table Container with Increased Height */}
        <div className="max-h-[600px] overflow-y-auto">
          <div className="w-full md:w-[500px] relative">
            <label
              htmlFor="party_type_id"
              className="block mb-2 text-sm font-medium text-black"
            >
             Search Party Type
            </label>
            <select
              id="party_type_id"
              name="party_type_id"
              className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
              onChange={handleChange}
              value={seletedValue.party_type_id}
            >
              <option value=""></option>
              {party && party.length > 0 ? (
                party.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.party_type}
                  </option>
                ))
              ) : (
                <option disabled>No Account available</option>
              )}
            </select>

            <div className="w-full md:w-[500px] relative">
              <label
                htmlFor="accountToAssign"
                className="block mb-2 text-sm font-medium text-black"
              >
                Search Account
              </label>
              <select
                id="account_id"
                name="account_id"
                className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                onChange={handleChange}
                 value={seletedValue.account_id}
              >
                <option value=""></option>
                {account2 && account2.length > 0 ? (
                  account2.map((type) => (
                    <option key={type.account_id} value={type.account_id}>
                      {type.account_name}
                    </option>
                  ))
                ) : (
                  <option disabled>No Account available</option>
                )}
              </select>
            </div>
          </div>

          {/* TailwindCSS Table */}
          <table className="min-w-full bg-white border border-black mt-12">
            <thead className="bg-gray-200">
              {/* Background color to distinguish header */}
              <tr>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Account
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Party Type
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Remove Account
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    selectedRow === row
                      ? "bg-blue-600 text-white"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => handleRowSelect(row)}
                >
                  <td className="px-6 py-4 border-b border-black">
                    {row.account_name}
                  </td>

                  <td className="px-6 py-4 border-b border-black">
                    {row.party_type}
                  </td>

                  <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                    <button
                      className="px-4 py-2 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75 transition duration-300"
                      onClick={() => removeAssignedAccount(row)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4 gap-2">
        {[20, 40, 60].map((size) => (
          <button
            key={size}
            onClick={() => handleRowsPerPageChange(size)}
            className={`px-4 py-2 ${
              rowsPerPage === size ? "bg-gray-800 text-white" : "bg-gray-300"
            } rounded`}
          >
            {size}
          </button>
        ))}
        <button
          onClick={() =>
            setPagination((prevPagination) => ({
              ...prevPagination,
              current_page: Math.min(
                prevPagination.current_page + 1,
                prevPagination.total_pages
              ),
            }))
          }
          disabled={pagination.current_page >= pagination.total_pages}
          className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
        >
          Load More
        </button>
      </div>

      {isSaveConfirmationOpen && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
          <div className="p-8 bg-white rounded-lg">
            <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
            <p>Are you sure you want to save the form data?</p>
            <div className="flex justify-end gap-4 mt-6">
              <button
                className="px-4 py-2 text-white bg-green-500 rounded-xl"
                onClick={handleSaveForm}
              >
                Yes, Save
              </button>
              <button
                className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                onClick={handleCloseSaveConfirmation}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isResetConfirmationOpen && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
          <div className="p-8 bg-white rounded-lg">
            <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
            <p>
              Are you sure you want to reset the form? This action cannot be
              undone.
            </p>
            <div className="flex justify-end gap-4 mt-6">
              <button
                className="px-4 py-2 text-white bg-red-500 rounded-xl"
                onClick={handleResetForm}
              >
                Yes, Reset
              </button>
              <button
                className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                onClick={handleCloseResetConfirmation}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Assigen_account;
