import React, { useEffect, useState, useRef } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import axios from "axios";
import Cookies from "js-cookie";
import ReactSelect from "react-select";

function EditBill({ billData, onClose, row,editePopup }) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const fileInputRef = useRef(null);

  // State declarations
  const [formData, setFormData] = useState({
    id: "",
    bill_no: "",
    bill_date: "",
    amount: "",
    supplier_id: "",
    due_date: "",
    debit_account_id: "",
    credit_account_id: "",
    billFile: "",
  });

  const [suppliers, setSuppliers] = useState([]); // List of suppliers for dropdown
  const [allAccounts, setAllAccounts] = useState([]); // List of accounts for dropdowns
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const userId = Cookies.get("employee_no");

  useEffect(() => {
    if (row) {
      console.log(row.id);
      setFormData({
        id: row.id || "",
        bill_no: row.bill_no || "",
        bill_date: row.bill_date
          ? new Date(row.bill_date).toISOString().split("T")[0]
          : "",
        amount: row.amount || "",
        supplier_id: row.supplier_id || "",
        due_date: row.due_date
          ? new Date(row.due_date).toISOString().split("T")[0]
          : "",
        debit_account_id: row.debit_account_id || "",
        credit_account_id: row.credit_account_id || "",
        billFile: row.billFile || "",
      });
    }
  }, [row]);

  // Fetch dropdown data for suppliers and accounts
  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/payable/accountsPayable/getCreditors`
        );
        const supplierData = Array.isArray(response.data)
          ? response.data
          : response.data.data || [];
        setSuppliers(
          supplierData.map((supplier) => ({
            id: supplier.supplier_id,
            name: supplier.supplier_name,
          }))
        );
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };

    const fetchAccounts = async () => {
      try {
        const params = { is_enabled: 1 };
        const response = await axios.get(
          `${backendUrl}/account/getAllAccountsByFilters`, {params}
        );
        const formattedAccounts = response.data.data.map((item) => ({
          id: item.account_id,
          code: item.account_code,
          name: item.account_name,
        }));
        setAllAccounts(formattedAccounts);
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    };

    fetchSuppliers();
    fetchAccounts();
  }, [backendUrl]);

  const validateFormData = () => {
    const requiredFields = ["bill_no", "bill_date", "amount", "supplier_id"];
    for (const field of requiredFields) {
      if (!formData[field]) {
        alert(`Please fill in the ${field.replace("_", " ")} field.`);
        return false;
      }
    }
    return true;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Handle account dropdown selection
  const handleAccountChange = (selectedOption, action) => {
    setFormData((prevDetails) => ({
      ...prevDetails,
      [action.name]: selectedOption ? selectedOption.value : "",
    }));
  };

  useEffect(() => {
    console.log("Form data after update:", formData);
  }, [formData]);

  const handleClick = () => {
    fileInputRef.current.click(); // Trigger the file input click
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Log the selected file
    console.log("Selected file:", file);

    if (file) {
      // Validate file type (if needed)
      if (file.type !== "application/pdf") {
        console.log("File type is not PDF:", file.type);
        alert("Only PDF files are allowed. Please upload a valid PDF.");
        return;
      }

      // Update formData with the selected file
      setFormData((prevDetails) => ({
        ...prevDetails,
        billFile: file,
      }));

      console.log("Form data after update:", formData); // Log formData after update
    }
  };

  const handleSubmit = async () => {
    // Check if the approval_status is "Approved" or "Submitted"

  
    if (!validateFormData()) return;


    try {
    const submissionData = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null && value !== "") {
        submissionData.append(key, value);
      }
    });
    submissionData.append("created_by", userId || "defaultUserId");


    console.log(
      "Request URL:",
      `${backendUrl}/payable/bill/updateBill/$/${formData.id}`
    );
    for (let pair of submissionData.entries()) {
      console.log(pair[0] + ":", pair[1]);
    }

 
      // Use the correct ID in the URL
      const response = await axios.put(
        `${backendUrl}/payable/bill/updateBill/${formData.id}`,
        submissionData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      alert("Bill updated successfully!");
      setNotification({
        message: "Bill updated successfully!",
        type: "success",
      });
      window.location.reload(); // Refresh the page
    } catch (error) {
      console.error("Error updating bill:", error);
      alert("An error occurred while updating the bill.");
    }
  };

  const handleClose = () => {
    if (onClose) onClose();
  };
  const handleEditModalClose = (e) => {
    if (e) e.preventDefault();
    if (e) e.stopPropagation();
    if (onClose) onClose(); // Close modal logic
    setIsEditModalOpen(false); // Close the modal
  };
  return (
    <div className="p-6 bg-white rounded shadow">
      <div className="max-w-8xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Edit Billing Form
        </h2>
      
      </div>

      <form className="px-28 mt-8">
        <div className="grid grid-cols-3 gap-6 text-[20px]">
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Bill No</label>
            <input
              type="text"
              name="bill_no"
              value={formData.bill_no}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Bill Date*</label>
            <input
              type="date"
              name="bill_date"
              value={formData.bill_date}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Amount</label>
            <input
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="w-full">
            <label htmlFor="supplier_id" className="block text-gray-500 mb-2">
              Supplier
            </label>
            <ReactSelect
              options={suppliers.map((supplier) => ({
                value: supplier.id,
                label: supplier.name,
              }))}
              name="supplier_id"
              onChange={(selectedOption) =>
                setFormData((prevDetails) => ({
                  ...prevDetails,
                  supplier_id: selectedOption ? selectedOption.value : "",
                }))
              }
              value={
                suppliers.find(
                  (supplier) => supplier.id === formData.supplier_id
                )
                  ? {
                      value: formData.supplier_id,
                      label: suppliers.find(
                        (supplier) => supplier.id === formData.supplier_id
                      ).name,
                    }
                  : null
              }
              isClearable
              placeholder="Select Supplier"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Due Date*</label>
            <input
              type="date"
              name="due_date"
              value={formData.due_date}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="w-full">
            <label
              htmlFor="debit_account_id"
              className="block text-gray-500 mb-2"
            >
              Account to Debit
            </label>
            <ReactSelect
              options={allAccounts.map((account) => ({
                value: account.id,
                label: `${account.code} - ${account.name}`,
              }))}
              name="debit_account_id"
              onChange={handleAccountChange}
              value={
                allAccounts.find(
                  (account) => account.id === formData.debit_account_id
                )
                  ? {
                      value: formData.debit_account_id,
                      label: allAccounts.find(
                        (account) => account.id === formData.debit_account_id
                      ).name,
                    }
                  : null
              }
              isClearable
              placeholder="Select Account to Debit"
            />
          </div>

          <div className="w-full ">
            <label
              htmlFor="credit_account_id"
              className="block text-gray-500 mb-2"
            >
              Account to Credit
            </label>
            <ReactSelect
              options={allAccounts.map((account) => ({
                value: account.id,
                label: `${account.code} - ${account.name}`,
              }))}
              name="credit_account_id"
              onChange={handleAccountChange}
              value={
                allAccounts.find(
                  (account) => account.id === formData.credit_account_id
                )
                  ? {
                      value: formData.credit_account_id,
                      label: allAccounts.find(
                        (account) => account.id === formData.credit_account_id
                      ).name,
                    }
                  : null
              }
              isClearable
              placeholder="Select Account to Credit"
            />
          </div>
        </div>

        <div className="w-full mt-6">
          <div className="flex">
            <button
              type="button"
              onClick={handleClick}
              className="px-4 py-2 bg-black text-white rounded-md cursor-pointer"
            >
              Upload File
            </button>
            <input
              id="billFile"
              type="file"
              name="billFile"
              className="hidden"
              onChange={handleFileChange}
              ref={fileInputRef}
            />
          </div>
        </div>
        <div className="flex justify-end mt-4">
        
          <button
            type="button"
            className="px-4 py-2 bg-black text-white rounded-md mt-12 grid justify-items-end"
            onClick={handleSubmit}
          >
            Save
          </button>
        </div>
      </form>
      <div>
        <button onClick={() => setIsModalOpen(true)}></button>
        {isModalOpen && (
          <EditBill
            id={selectedId} // Ensure `selectedId` is defined
            onClose={handleEditModalClose} // Pass the `handleClose` function
          />
        )}
      </div>
    </div>
  );
}

export default EditBill;
