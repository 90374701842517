import React from "react";

const PaymentDocumentCard = ({ document, paymentType }) => {
    // Ensure numeric fields are parsed as numbers
    const amount = parseFloat(document.amount);
    const settledAmount = parseFloat(document.settled_amount);
    const unsettledAmount = parseFloat(document.unsettled_amount);

    return (
        <div className="border p-4 rounded-lg shadow-sm bg-gray-50">
            {/* Card Title */}
            <h4 className="font-semibold text-base text-gray-800 mb-3">
                {document?.document_id}
            </h4>

            {/* Details */}
            <div className="space-y-2 text-sm text-gray-600">
                {paymentType === "Bill pay" && (
                    <div className="flex justify-between">
                        <span className="font-medium text-gray-700">Bill No:</span>
                        <span>{document?.bill_no}</span>
                    </div>
                )}
                {paymentType === "Pay" && (
                    <div className="flex justify-between">
                        <span className="font-medium text-gray-700">PO ID:</span>
                        <span>{document?.po_id}</span>
                    </div>
                )}
                <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Amount:</span>
                    <span>{amount?.toFixed(2)} LKR</span>
                </div>
                <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Settled:</span>
                    <span>{settledAmount?.toFixed(2)} LKR</span>
                </div>
                <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Unsettled:</span>
                    <span>{unsettledAmount?.toFixed(2)} LKR</span>
                </div>
                <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Due Date:</span>
                    <span>{new Date(document?.due_date).toLocaleDateString()}</span>
                </div>
            </div>
        </div>
    );
};

export default PaymentDocumentCard;
