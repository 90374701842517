import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import ReactSelect from "react-select";
import EditBill from "./edit_bill";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { HiDocumentCheck } from "react-icons/hi2";
import { RiDraftFill } from "react-icons/ri";

function Billing() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;

  const [formData, setFormData] = useState({
    bill_no: "",
    bill_date: "",
    amount: "",
    supplier_id: "",
    due_date: "",
    debit_account_id: "",
    credit_account_id: "",
    billFile: null,
  });

  const [suppliers, setSuppliers] = useState([]); // List of suppliers for dropdown
  const [allAccounts, setAllAccounts] = useState([]); // List of accounts for dropdowns
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [billNo, setBillNo] = useState(""); // For Bill No filter
  const [fromBillDate, setFromBillDate] = useState(""); // For Bill Date filter (start date)
  const [toDueDate, setToDueDate] = useState(""); // For Due Date filter (end date)
  const [actionsVisible, setActionsVisible] = useState(false); // Dropdown visibility
  const [selectedRow, setSelectedRow] = useState(null); // Selected row for actions
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [debouncedFilters, setDebouncedFilters] = useState({
    approvalStatus: "",
    billNo: "",
    fromBillDate: "", // Single date filter
    toDueDate: "",
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilters({
        approvalStatus,
        billNo,
        fromBillDate, // Single date filter
        toDueDate, // Single date filter
      });
    }, 300); // Debounce delay

    return () => clearTimeout(handler);
  }, [approvalStatus, billNo, fromBillDate, toDueDate]);

  const [pagination, setPagination] = useState({
    current_page: 1,
    total_pages: 0,
    total_records: 0,
    rows_per_page: 10,
  });

  const userId = Cookies.get("employee_no");
  const actionsRef = useRef(null);

  const handleFileDownload = (id) => {
    console.log("Download file for ID:", id); // Debugging step
    if (!id) {
      alert("No file available to download.");
      return;
    }

    const downloadUrl = `${backendUrl}/payable/bill/downloadBill/${id}`;
    console.log("Attempting to download from URL:", downloadUrl);

    try {
      window.open(downloadUrl, "_blank");
    } catch (error) {
      console.error("Error opening download link:", error);
      alert("Unable to download the file. Please try again.");
    }
  };

  // Validate form data
  const validateFormData = () => {
    const requiredFields = ["bill_no", "bill_date", "amount", "supplier_id"];
    for (const field of requiredFields) {
      if (!formData[field]) {
        alert(`Please fill in the ${field.replace("_", " ")} field.`);
        return false;
      }
    }
    return true;
  };

  // Fetch Table Data
  useEffect(() => {
    const fetchTableData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${backendUrl}/payable/bill/getBills`,
          {
            params: {
              page_size: rowsPerPage,
              page: pagination.current_page,
              approval_status: debouncedFilters.approvalStatus || undefined,
              search: debouncedFilters.billNo || undefined,
              start_date: debouncedFilters.fromBillDate || undefined, // Exact match
              end_date: debouncedFilters.toDueDate || undefined, // Exact match
            },
          }
        );

        console.log("API Response Data:", response.data);

        if (response.data) {
          setTableData(response.data.data || []);
          setPagination({
            current_page: response.data.pagination.current_page,
            total_pages: response.data.pagination.total_pages,
            total_records: response.data.pagination.total_records,
          });
        }
      } catch (error) {
        console.error("Error fetching table data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTableData();
  }, [debouncedFilters, rowsPerPage, backendUrl]);

  // Fetch dropdown data for suppliers and accounts
  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/payable/accountsPayable/getCreditors`
        );
        console.log("API Response for Suppliers:", response.data); // Log the response data
        const supplierData = Array.isArray(response.data)
          ? response.data
          : response.data.data || []; // Adjust if response data has nested structure
        setSuppliers(
          supplierData.map((supplier) => ({
            id: supplier.supplier_id, // Adjust field names as per the API response
            name: supplier.supplier_name, // Adjust field names as per the API response
          }))
        );
      } catch (error) {
        console.error("Error fetching suppliers:", error);
      }
    };

    const fetchAccounts = async () => {
      try {
        const params = { is_enabled: 1 };
        const response = await axios.get(
          `${backendUrl}/account/getAllAccountsByFilters`, { params }
        );
        const formattedAccounts = response.data.data.map((item) => ({
          id: item.account_id,
          code: item.account_code,
          name: item.account_name,
        }));
        setAllAccounts(formattedAccounts);
      } catch (error) {
        console.error("Error fetching accounts:", error);
      }
    };

    fetchSuppliers();
    fetchAccounts();
  }, [backendUrl]);

  const fetchBills = async (page = 1) => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${backendUrl}/payable/bill/getBills`, {
        params: {
          page,
          page_size: pagination.rows_per_page,
        },
      });

      const { data, pagination: serverPagination } = response.data;
      setTableData(data || []);
      setPagination({
        ...pagination,
        current_page: serverPagination.current_page,
        total_pages: serverPagination.total_pages,
        total_records: serverPagination.total_records,
      });
    } catch (error) {
      console.error("Error fetching bills:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBills(pagination.current_page);
  }, [pagination.current_page, pagination.rows_per_page]);

  // Cleanup to avoid event listeners piling up
  // Handle row click to select or deselect the row
  const handleRowClick = (row) => {
    if (selectedRow?.id === row.id && actionsVisible) {
      // If the same row is clicked again, close the dropdown
      setSelectedRow(null);
      setActionsVisible(false);
    } else {
      // Select the new row and display the actions
      setSelectedRow(row);
      setActionsVisible(true);
    }
  };

  // Close dropdown when clicking outside
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setActionsVisible(false);
        setSelectedRow(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getAvailableActions = (status) => {
    switch (status) {
      case "Draft":
        return ["Submitted", "Reject"];
      case "Submitted":
        return ["Approve", "Reject"];
      case "Rejected":
        return ["Draft"];
      default:
        return [];
    }
  };

  const handleActionClick = (action) => {
    console.log(`Action "${action}" executed for Row ID: ${selectedRow?.id}`);

    const statusMap = {
      Approve: "Approved",
      Draft: "Draft",
      Submitted: "Submitted",
      Reject: "Rejected",
    };

    if (statusMap[action]) {
      updateRowStatus(statusMap[action]);
    }
    setActionsVisible(false);
  };

  const updateRowStatus = async (status) => {
    try {
      const response = await axios.put(
        `${backendUrl}/payable/bill/checkOrApproveBills/${selectedRow.id}`,
        { approval_status: status, action_by: userId }
      );
      console.log("Status updated successfully:", response.data);

      // Refresh or update local data
      setTableData((prevData) =>
        prevData.map((row) =>
          row.id === selectedRow.id ? { ...row, approval_status: status } : row
        )
      );
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const editePopup = () => {
    setIsOpenEdit(!isOpenEdit);
  };

  const handleEdit = () => {
    console.log("Edit row: ", selectedRow);
    if (selectedRow) {
      setIsOpenEdit(true);
    } else {
      alert("Please select a row to edit.");
    }
  };
  const handleModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedRow(null);
    // Reset URL
  };

  const handleDeleteClick = async (id) => {
    if (!id) {
      alert("No ID selected for deletion.");
      return;
    }

    const confirmDelete = window.confirm(
      "Are you sure you want to delete this asset?"
    );
    if (!confirmDelete) return;

    try {
      // Send DELETE request with necessary payload if required
      const response = await axios.put(
        `${backendUrl}/payable/bill/deleteBill/${selectedRow.id}`,
        {
          deleted_by: userId, // Include user information if required
        }
      );

      if (response.status === 200 || response.status === 204) {
        // Remove the deleted row from the local state
        setTableData((prevData) => prevData.filter((row) => row.id !== id));

        setNotification({
          message: "Asset deleted successfully.",
          type: "success",
        });
      } else {
        setNotification({
          message: response.data?.message || "Failed to delete the asset.",
          type: "error",
        });
      }
    } catch (error) {
      console.error("Error deleting asset:", error);
      setNotification({
        message:
          error.response?.data?.message ||
          "An error occurred while deleting the asset.",
        type: "error",
      });
    }
  };

  const handleApprovalStatusChange = (e) => {
    setApprovalStatus(e.target.value);
    setPagination((prev) => ({ ...prev, current_page: 1 }));
  };

  const handleBillNoChange = (e) => {
    setBillNo(e.target.value);
    setPagination((prev) => ({ ...prev, current_page: 1 })); // Reset pagination
  };

  const handleBillDateChange = (e) => {
    setFromBillDate(e.target.value); // Updated state for exact match
    setPagination((prev) => ({ ...prev, current_page: 1 })); // Reset pagination
  };

  const handleDueDateChange = (e) => {
    setToDueDate(e.target.value); // Updated state for exact match
    setPagination((prev) => ({ ...prev, current_page: 1 })); // Reset pagination
  };

  // Handle input change for text and select fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Handle account dropdown selection
  const handleAccountChange = (selectedOption, action) => {
    setFormData((prevDetails) => ({
      ...prevDetails,
      [action.name]: selectedOption ? selectedOption.value : "",
    }));
  };

  // Handle file upload
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type !== "application/pdf") {
      alert("Only PDF files are allowed. Please upload a valid PDF.");
      return;
    }
    setFormData((prevDetails) => ({
      ...prevDetails,
      billFile: file,
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateFormData()) return;

    const submissionData = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (value !== null && value !== "") {
        submissionData.append(key, value);
      }
    });
    submissionData.append("created_by", userId || "defaultUserId");

    try {
      const response = await axios.post(
        `${backendUrl}/payable/bill/addBill`,
        submissionData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      alert("Bill saved successfully!");
      fetchBills(pagination.current_page); // Refresh the table data

      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.error("Error saving bill:", error);
      alert("An error occurred while saving the bill.");
    }
  };

  const handlePageChange = async (page) => {
    if (page > 0 && page <= pagination.total_pages) {
      setPagination((prev) => ({ ...prev, current_page: page })); // Update the current page state

      try {
        setIsLoading(true); // Optional: Show a loading indicator while fetching
        const response = await axios.get(
          `${backendUrl}/payable/bill/getBills`,
          {
            params: {
              page,
              page_size: rowsPerPage, // Use rowsPerPage to determine page size
              approval_status: debouncedFilters.approvalStatus || undefined,
              search: debouncedFilters.billNo || undefined,
              start_date: debouncedFilters.fromBillDate || undefined,
              end_date: debouncedFilters.toDueDate || undefined,
            },
          }
        );

        if (response.data) {
          setTableData(response.data.data || []); // Update the table data
        }
      } catch (error) {
        console.error("Error fetching data for page:", page, error);
      } finally {
        setIsLoading(false); // Hide the loading indicator
      }
    }
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1, // Reset to first page whenever rows per page changes
    }));
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-8xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Billing Form
        </h2>
      </div>

      <form className="px-28 mt-8">
        <div className="grid grid-cols-3 gap-6 text-[20px]">
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Bill No</label>
            <input
              type="text"
              name="bill_no"
              value={formData.bill_no}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Bill Date*</label>
            <input
              type="date"
              name="bill_date"
              value={formData.bill_date}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Amount</label>
            <input
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="w-full">
            <label htmlFor="supplier_id" className="block text-gray-500 mb-2">
              Supplier
            </label>
            <ReactSelect
              options={suppliers.map((supplier) => ({
                value: supplier.id,
                label: supplier.name,
              }))}
              name="supplier_id"
              onChange={(selectedOption) =>
                setFormData((prevDetails) => ({
                  ...prevDetails,
                  supplier_id: selectedOption ? selectedOption.value : "",
                }))
              }
              value={
                suppliers.find(
                  (supplier) => supplier.id === formData.supplier_id
                )
                  ? {
                      value: formData.supplier_id,
                      label: suppliers.find(
                        (supplier) => supplier.id === formData.supplier_id
                      ).name,
                    }
                  : null
              }
              isClearable
              placeholder="Select Supplier"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Due Date*</label>
            <input
              type="date"
              name="due_date"
              value={formData.due_date}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="w-full">
            <label
              htmlFor="debit_account_id"
              className="block text-gray-500 mb-2"
            >
              Account to Debit
            </label>
            <ReactSelect
              options={allAccounts.map((account) => ({
                value: account.id,
                label: `${account.code} - ${account.name}`,
              }))}
              name="debit_account_id"
              onChange={handleAccountChange}
              value={
                allAccounts.find(
                  (account) => account.id === formData.debit_account_id
                )
                  ? {
                      value: formData.debit_account_id,
                      label: allAccounts.find(
                        (account) => account.id === formData.debit_account_id
                      ).name,
                    }
                  : null
              }
              isClearable
              placeholder="Select Account to Debit"
            />
          </div>

          <div className="w-full ">
            <label
              htmlFor="credit_account_id"
              className="block text-gray-500 mb-2"
            >
              Account to Credit
            </label>
            <ReactSelect
              options={allAccounts.map((account) => ({
                value: account.id,
                label: `${account.code} - ${account.name}`,
              }))}
              name="credit_account_id"
              onChange={handleAccountChange}
              value={
                allAccounts.find(
                  (account) => account.id === formData.credit_account_id
                )
                  ? {
                      value: formData.credit_account_id,
                      label: allAccounts.find(
                        (account) => account.id === formData.credit_account_id
                      ).name,
                    }
                  : null
              }
              isClearable
              placeholder="Select Account to Credit"
            />
          </div>
        </div>

        <div className="w-full mt-6">
          <div className="flex">
            <label
              htmlFor="billFile"
              className="px-4 py-2 bg-black text-white rounded-md cursor-pointer"
            >
              Upload File
            </label>
            <input
              id="billFile"
              type="file"
              name="billFile"
              className="hidden"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <button
          className="px-4 py-2 bg-black text-white rounded-md mt-12"
          type="button"
          onClick={handleSubmit}
        >
          Save
        </button>
      </form>

      <p className="text-3xl  mt-8 flex justify-center font-bold ">
        Bill Table
      </p>

      <div className="mt-5">
        {/* Filters */}
        <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
          <div className="flex flex-col md:flex-row md:items-center md:gap-4">
            {/* Approval Status Filter */}
            <div className="w-full md:w-[200px]">
              <label htmlFor="approvalStatus" className="block font-bold mb-2">
                Approval Status
              </label>
              <select
                id="approvalStatus"
                className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                value={approvalStatus}
                onChange={handleApprovalStatusChange}
              >
                <option value="">-- Select Approval Status --</option>
                <option value="Approved">Approved</option>
                <option value="Draft">Draft</option>
                <option value="Submitted">Submitted</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>

            {/* Bill No Filter */}
            <div className="w-full md:w-[200px]">
              <label htmlFor="search" className="block font-bold mb-2">
                Bill No
              </label>
              <input
                id="search"
                className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                value={billNo}
                onChange={handleBillNoChange}
              />
            </div>

            {/* Bill Date Filter */}
            <div className="w-full md:w-[200px]">
              <label htmlFor="bill_date" className="block font-bold mb-2">
                Start Bill Date
              </label>
              <input
                type="date"
                id="start_date"
                value={fromBillDate} // State for exact match
                onChange={handleBillDateChange}
                className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
              />
            </div>

            {/* Due Date Filter */}
            <div className="w-full md:w-[200px]">
              <label htmlFor="end_date" className="block font-bold mb-2">
                End Bill Date
              </label>
              <input
                type="date"
                id="end_date"
                value={toDueDate} // State for exact match
                onChange={handleDueDateChange}
                className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
              />
            </div>
          </div>

          <div ref={actionsRef} className="relative">
            <button
              className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800 focus:outline-none"
              onClick={() => setActionsVisible(!actionsVisible)}
              disabled={!selectedRow}
            >
              Actions
            </button>
            {actionsVisible && selectedRow && (
              <div
                className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg"
                style={{ width: "150px" }}
                ref={actionsRef}
              >
                {/* Conditionally render the Edit button */}
                {selectedRow.approval_status !== "Approved" &&
                  selectedRow.approval_status !== "Submitted" && (
                    <button
                      className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                      onClick={() => handleEdit(selectedRow.id)}
                    >
                      <FaEdit className="mr-2" />
                      Edit
                    </button>
                  )}

                {/* Conditionally render the Delete button */}
                {selectedRow.approval_status !== "Approved" &&
                  selectedRow.approval_status !== "Submitted" && (
                    <button
                      className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                      onClick={() => handleDeleteClick(selectedRow.id)}
                    >
                      <FaTrash className="mr-2" />
                      Delete
                    </button>
                  )}

                {/* Render other available actions */}
                {getAvailableActions(selectedRow.approval_status).map(
                  (action) => (
                    <button
                      key={action}
                      className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                      onClick={() => handleActionClick(action)}
                    >
                      {action === "Approve" && <TiTick className="mr-2" />}
                      {action === "Draft" && <RiDraftFill className="mr-2" />}
                      {action === "Submitted" && (
                        <HiDocumentCheck className="mr-2" />
                      )}
                      {action === "Reject" && (
                        <IoIosCloseCircleOutline className="mr-2" />
                      )}
                      {action}
                    </button>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-10">
        <table className="w-full bg-white border border-black rounded-lg">
          <thead className="bg-gray-300 ">
            <tr>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[250px]">
                Bill ID
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                Billl No
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[150px]">
                Bill Date
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                Amount
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                Due Date
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                Bill File
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                Approval Status
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                Supplier Name
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.length > 0 ? (
              tableData.map((row) => (
                <tr
                  key={row.id}
                  className={`cursor-pointer ${
                    selectedRow?.id === row.id
                      ? "bg-gray-100"
                      : "hover:bg-gray-200"
                  }`} // Apply hover only when not selected
                  onClick={() => handleRowClick(row)} // Call row click handler
                >
                  <td className="py-2 px-4 text-center border-b">
                    {row.bill_id}
                  </td>
                  <td className="py-2 px-4 text-center border-b">
                    {row.bill_no}
                  </td>
                  <td className="py-2 px-4 text-center border-b">
                    {new Date(row.bill_date).toLocaleDateString()}
                  </td>
                  <td className="py-2 px-4 text-center border-b">
                    {row.amount}
                  </td>
                  <td className="py-2 px-4 text-center border-b">
                    {new Date(row.due_date).toLocaleDateString()}
                  </td>
                  <td className="py-2 px-4 text-center border-b">
                    <button
                      className="px-4 py-2 bg-green-500 text-white rounded-lg"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent dropdown toggle
                        handleFileDownload(row.id);
                      }}
                    >
                      Download
                    </button>
                  </td>
                  <td className="py-2 px-4 text-center border-b">
                    {row.approval_status}
                  </td>
                  <td className="py-2 px-4 text-center border-b">
                    {row.supplier_name}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center py-4">
                  No bills available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Section */}
      <div className="flex justify-between items-center mt-6 mb-6">
        <div>
          <label htmlFor="rowsPerPage" className="mr-2 text-2xl">
            Rows per page:
          </label>
          <select
            id="rowsPerPage"
            value={rowsPerPage}
            onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
            className="p-1 border border-gray-400 rounded"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div className="flex items-center gap-2">
          {Array.from({ length: pagination.total_pages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)} // Pass the correct page number
              className={`px-3 py-1 rounded ${
                pagination.current_page === index + 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 hover:bg-gray-300"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>

      {isOpenEdit && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg relative">
            <button
            type="button"
            onClick={editePopup}
          className="absolute text-4xl top-4 right-4 text-gray-500 hover:text-black"
          >
            <IoIosCloseCircleOutline />
          </button>
            <EditBill editePopup={editePopup} row={selectedRow} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Billing;
