import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import ReactSelect from "react-select";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { HiDocumentCheck } from "react-icons/hi2";
import { RiDraftFill } from "react-icons/ri";
import EditCategory from "./edit_category";

function Categories() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;

  // State to manage form data
  const [formData, setFormData] = useState({
    category_name: "",
    description: "",
    depreciation_account_id: "",
    cumulative_account_id: "",
  });

  const [accounts, setAccounts] = useState([]); // Store all accounts for dropdown
  const [tableData, setTableData] = useState([]); // Store categories for table
  const [approvalStatus, setApprovalStatus] = useState("");
  const [actionsVisible, setActionsVisible] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const userId = Cookies.get("employee_no");
  const actionsRef = useRef(null);

  const [debouncedFilters, setDebouncedFilters] = useState({
    approvalStatus: "",
  });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilters({
        approvalStatus,
      });
    }, 300); // Debounce delay

    return () => clearTimeout(handler);
  }, [approvalStatus]);

  const [pagination, setPagination] = useState({
    current_page: 1,
    total_pages: 0,
    total_records: 0,
    rows_per_page: 10,
  });
  // Fetch accounts from the API

  const fetchAccounts = async () => {
    try {
      const params = { is_enabled: 1 };
      const response = await axios.get(
        `${backendUrl}/account/getAllAccountsByFilters`, {params}
      );
      const formattedAccounts = response.data.data.map((item) => ({
        id: item.account_id,
        name: `${item.account_code} - ${item.account_name}`, // Combine code and name for better display
      }));
      setAccounts(formattedAccounts);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

const fetchCategories = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/assets/assets/getAssetCategories`,
        {
          params: {
            page: pagination.current_page,
            page_size: pagination.rows_per_page,
          },
        }
      );

      let categoriesData = Array.isArray(response.data)
        ? response.data
        : Array.isArray(response.data?.data)
        ? response.data.data
        : [];

      // Apply status filter
      if (debouncedFilters.approvalStatus) {
        categoriesData = categoriesData.filter(
          (category) => category.status === debouncedFilters.approvalStatus
        );
      }

      setTableData(categoriesData);

      if (response.data.pagination) {
        setPagination((prev) => ({
          ...prev,
          total_pages: response.data.pagination.total_pages || 1,
          total_records: response.data.pagination.total_records || 0,
        }));
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  
  useEffect(() => {
    fetchAccounts();
    fetchCategories();
  },  [debouncedFilters, pagination.current_page, pagination.rows_per_page]);

  useEffect(() => {
    console.log("Table Data State Updated:", tableData);
  }, [tableData]);

 

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/assets/assets/getAssetCategories`,
          {
            params: {
              status: debouncedFilters.approvalStatus || undefined,
              page: pagination.current_page,
              page_size: pagination.rows_per_page,
            },
          }
        );

        if (response.data.data) {
          setTableData(response.data.data);
          setPagination({
            ...pagination,
            total_pages: response.data.pagination?.total_pages || 1,
            total_records: response.data.pagination?.total_records || 0,
          });
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  });

  const handleRowClick = (row) => {
    if (selectedRow?.id === row.id && actionsVisible) {
      // If clicking the same row, close the dropdown
      console.log("Unselecting row:", row.id); // Log when unselecting
      setSelectedRow(null);
      setActionsVisible(false);
    } else {
      // Select the new row and show the dropdown
      console.log("Row selected:", row.id); // Log the selected row ID
      setSelectedRow(row);
      setActionsVisible(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setActionsVisible(false);
        setSelectedRow(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getAvailableActions = (status) => {
    switch (status) {
      case "Draft":
        return ["Approve", "Reject"];
      case "Submitted":
        return ["Approve", "Reject"];
      case "Rejected":
        return [""];
      default:
        return [];
    }
  };

  const handleActionClick = (action) => {
    console.log(`Action "${action}" executed for Row ID: ${selectedRow?.id}`);

    const statusMap = {
      Approve: "Approved",
      Draft: "Draft",
      Submitted: "Submitted",
      Reject: "Rejected",
    };

    if (statusMap[action]) {
      updateRowStatus(statusMap[action]);
    }
    setActionsVisible(false);
  };

  const updateRowStatus = async (status) => {
    try {
      const response = await axios.put(
        `${backendUrl}/assets/assets/checkOrApproveAssetsCategories/${selectedRow.id}`,
        { approval_status: status, action_by: userId }
      );
      console.log("Status updated successfully:", response.data);
      // Update table data after successful status change
      setTableData((prevData) =>
        prevData.map((row) =>
          row.id === selectedRow.id ? { ...row, status: status } : row
        )
      );
    } catch (error) {
      console.error("Error updating status:", error);
      if (error.response) {
        // Log the error details from the server response
        console.error("Server Response:", error.response.data);
      }
    }
  };

  const editePopup = () => {
    setIsOpenEdit(!isOpenEdit);
  };

  const handleEdit = () => {
    console.log("Edit row: ", selectedRow);
    if (selectedRow) {
      setIsOpenEdit(true);
    } else {
      alert("Please select a row to edit.");
    }
  };
  const handleModalClose = () => {
    setIsEditModalOpen(false);
    setSelectedRow(null);
    // Reset URL
  };
  // Validate form data
  const validateFormData = () => {
    const requiredFields = [
      "category_name",
      "description",
      "depreciation_account_id",
      "cumulative_account_id",
    ];
    for (const field of requiredFields) {
      if (!formData[field]) {
        alert(`Please fill in the ${field.replace("_", " ")} field.`);
        return false;
      }
    }
    return true;
  };

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateFormData()) return;

    const submissionData = {
      ...formData,
      created_by: userId, // Add user ID to the payload
    };

    try {
      await axios.post(
        `${backendUrl}/assets/assets/addAssetCategories`,
        submissionData
      );
      alert("Category added successfully!");
      setFormData({
        category_name: "",
        description: "",
        depreciation_account_id: "",
        cumulative_account_id: "",
      }); // Reset form
      fetchCategories(); // Refresh categories after adding
    } catch (error) {
      console.error("Error adding category:", error);
      alert("An error occurred while adding the category.");
    }
  };

  const handleApprovalStatusChange = (e) => {
    setApprovalStatus(e.target.value);
    setPagination((prev) => ({ ...prev, current_page: 1 })); // Reset pagination if needed
  };

  const handlePageChange = async (page) => {
    if (page > 0 && page <= pagination.total_pages) {
      setPagination((prev) => ({ ...prev, current_page: page })); // Update the current page state

      try {
        // Optional: Show a loading indicator while fetching
        const response = await axios.get(
          `${backendUrl}/assets/assets/getAssetCategories`,
          {
            params: {
              page,
              page_size: rowsPerPage, // Use rowsPerPage to determine page size
              status: debouncedFilters.approvalStatus || undefined,
            },
          }
        );

        if (response.data) {
          setTableData(response.data.data || []); // Update the table data
        }
      } catch (error) {
        console.error("Error fetching data for page:", page, error);
      } finally {
        // Hide the loading indicator
      }
    }
  };
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1, // Reset to first page whenever rows per page changes
    }));
  };
  // Fetch accounts and categories on component mount

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-8xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4 text-center">Asset Categories</h2>
      </div>

      <form className="px-28 mt-8">
        <div className="grid grid-cols-3 gap-6 text-[20px]">
          {/* Category Name */}
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Category Name</label>
            <input
              type="text"
              name="category_name"
              value={formData.category_name}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          {/* Description */}
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Description</label>
            <input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          {/* Depreciation Account */}
          <div className="w-full">
            <label
              htmlFor="depreciation_account_id"
              className="block text-gray-500 mb-2"
            >
              Depreciation Account
            </label>
            <ReactSelect
              id="depreciation_account_id"
              name="depreciation_account_id"
              options={accounts}
              getOptionLabel={(e) => `${e.name}`} // Display name as label
              getOptionValue={(e) => e.id} // Value will be the account id
              onChange={(selectedOption) =>
                setFormData((prevDetails) => ({
                  ...prevDetails,
                  depreciation_account_id: selectedOption
                    ? selectedOption.id
                    : "",
                }))
              }
              value={accounts.find(
                (acc) => acc.id === formData.depreciation_account_id
              )}
              className="w-full p-2 border rounded-lg"
            />
          </div>

          {/* Cumulative Account */}
          <div className="w-full">
            <label
              htmlFor="cumulative_account_id"
              className="block text-gray-500 mb-2"
            >
              Cumulative Account
            </label>
            <ReactSelect
              id="cumulative_account_id"
              name="cumulative_account_id"
              options={accounts}
              getOptionLabel={(e) => `${e.name}`} // Display name as label
              getOptionValue={(e) => e.id} // Value will be the account id
              onChange={(selectedOption) =>
                setFormData((prevDetails) => ({
                  ...prevDetails,
                  cumulative_account_id: selectedOption
                    ? selectedOption.id
                    : "",
                }))
              }
              value={accounts.find(
                (acc) => acc.id === formData.cumulative_account_id
              )}
              className="w-full p-2 border rounded-lg"
            />
          </div>
        </div>

        <button
          className="px-4 py-2 bg-black text-white rounded-md mt-12 grid justify-items-end"
          type="button"
          onClick={handleSubmit}
        >
          Save
        </button>
      </form>

      <p className="text-3xl mt-8 flex justify-center font-bold ">
        Asset Category Table
      </p>

      <div className="mt-5">
        <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
          <div className="flex flex-col md:flex-row md:items-center md:gap-4">
            <div className="w-full md:w-[200px]">
              <label htmlFor="approvalStatus" className="block font-bold mb-2">
                Approval Status
              </label>
              <select
                id="approvalStatus"
                className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                value={approvalStatus}
                onChange={handleApprovalStatusChange}
              >
                <option value="">-- Select Approval Status --</option>
                <option value="Approved">Approved</option>
                <option value="Draft">Draft</option>

                <option value="Rejected">Rejected</option>
              </select>
            </div>
          </div>

          <div ref={actionsRef} className="relative">
            <button
              className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800 focus:outline-none"
              onClick={() => setActionsVisible(!actionsVisible)}
              disabled={!selectedRow}
            >
              Actions
            </button>
            {actionsVisible && selectedRow && (
              <div
                className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg"
                style={{ width: "150px" }}
                ref={actionsRef}
              >
                {/* Conditionally render the Edit button */}
                {selectedRow.status !== "Approved" &&
                  selectedRow.status !== "Submitted" &&
                  selectedRow.status !== "Rejected" && (
                    <button
                      className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                      onClick={() => handleEdit(selectedRow.id)}
                    >
                      <FaEdit className="mr-2" />
                      Edit
                    </button>
                  )}

                {/* Render other available actions */}
                {getAvailableActions(selectedRow.status).map((action) => (
                  <button
                    key={action}
                    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                    onClick={() => handleActionClick(action)}
                  >
                    {action === "Approve" && <TiTick className="mr-2" />}
                    {action === "Draft" && <RiDraftFill className="mr-2" />}
                    {action === "Submitted" && (
                      <HiDocumentCheck className="mr-2" />
                    )}
                    {action === "Reject" && (
                      <IoIosCloseCircleOutline className="mr-2" />
                    )}
                    {action}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Display Categories in a Table */}
      <div className="overflow-x-auto mt-10">
        <table className="w-full bg-white border border-black rounded-lg mb-8">
          <thead className="bg-gray-300 ">
            <tr>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[150px]">
                Category ID
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                Category Name
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[300px]">
                Description
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[150px]">
                Status
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                Depreciation ID
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                Cumulative ID
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.length > 0 ? (
              tableData.map((row) => (
                <tr
                  key={row.id}
                  className={`cursor-pointer ${
                    selectedRow?.id === row.id
                      ? "bg-gray-100"
                      : "hover:bg-gray-200"
                  }`} // Apply hover only when not selected
                  onClick={() => handleRowClick(row)} // Call row click handler
                >
                  <td className="py-2 px-4 text-center border-b">
                    {row.category_id}
                  </td>
                  <td className="py-2 px-4 text-center border-b">
                    {row.category_name}
                  </td>
                  <td className="py-2 px-4 text-center border-b">
                    {row.description}
                  </td>
                  <td className="py-2 px-4 text-center border-b">
                    {row.status}
                  </td>
                  <td className="py-2 px-4 text-center border-b">
                    {row.depreciation_account_id}
                  </td>
                  <td className="py-2 px-4 text-center border-b">
                    {row.cumulative_account_id}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center py-4">
                  No categories available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-6 mb-6">
        <div>
          <label htmlFor="rowsPerPage" className="mr-2 text-2xl">
            Rows per page:
          </label>
          <select
            id="rowsPerPage"
            value={rowsPerPage}
            onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
            className="p-1 border border-gray-400 rounded"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div className="flex items-center gap-2">
          {Array.from({ length: pagination.total_pages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)} // Pass the correct page number
              className={`px-3 py-1 rounded ${
                pagination.current_page === index + 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 hover:bg-gray-300"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
      {isOpenEdit && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg relative">
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-black"
              onClick={editePopup} // Close modal button
            >
              <IoIosCloseCircleOutline size={30} />
            </button>
            <EditCategory editePopup={editePopup} row={selectedRow} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Categories;
