import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import ReactSelect from "react-select";
import { useNavigate,useLocation  } from "react-router-dom";

function AddDisposal() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const userId = Cookies.get("employee_no");
  const actionsRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);  // Parse the query parameters
  const id = queryParams.get('id');  // Get the 'id' query parameter

  console.log("Received ID:", id);


  // State to manage form data
  const [formData, setFormData] = useState({
    date_of_disposal: "",
    method_of_disposal: "Sold",
    selling_value: "",
    account_deposited_to: "",
    account_credited_to: "",
    account_debited_to: "",
  });



  const [accounts, setAccounts] = useState([]);

    // Validate form data
    const validateFormData = () => {
      const requiredFields = [
        "date_of_disposal",
        "method_of_disposal",
        "selling_value",
        "account_deposited_to",
        "account_credited_to",
        "account_debited_to",
      ];
      for (const field of requiredFields) {
        if (!formData[field]) {
          alert(`Please fill in the ${field.replace("_", " ")} field.`);
          return false;
        }
      }
      return true;
    };

      // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!validateFormData()) return;
  
    try {
      // Make a request to check if the asset is already in the disposal table
      const response = await axios.get(
        `${backendUrl}/assets/assets/getAssetDisposals`, // Endpoint to check if asset is already disposed
        { params: { assets_id: id } }
      );
  
      // If the response contains any disposal for the asset, show an error message
      if (response.data && response.data.length > 0) {
        alert("This asset is already in disposal.");
        return; // Prevent form submission if asset is already disposed
      }
  
      // Prepare the data for submission
      const submissionData = {
        ...formData,
        assets_id: id,
        created_by: userId, // Add user ID to the payload
      };
  
      // Proceed with submitting the disposal if the asset is not already disposed
      await axios.post(
        `${backendUrl}/assets/assets/addAssetDisposals`,
        submissionData
      );
      alert("Disposal added successfully!");
  
      // Reset form after successful submission
      setFormData({
        date_of_disposal: "",
        method_of_disposal: "Sold",
        selling_value: "",
        account_deposited_to: "",
        account_credited_to: "",
        account_debited_to: "",
      });
  
    } catch (error) {
      console.error("Error adding Disposal:", error);
      if (error.response && error.response.data) {
        alert(`Error: ${error.response.data.message || "An error occurred while adding the disposal."}`);
      } else {
        alert("An error occurred while adding the disposal.");
      }
    }
  };
  

  const fetchAccounts = async () => {
    try {
      const params = { is_enabled: 1 };
      const response = await axios.get(
        `${backendUrl}/account/getAllAccountsByFilters`,
        { params }
      );
      const formattedAccounts = response.data.data.map((item) => ({
        id: item.account_id,
        name: `${item.account_code} - ${item.account_name}`, // Combine code and name for better display
      }));
      setAccounts(formattedAccounts);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, [backendUrl]);

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-8xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Add Disposal
        </h2>

        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-4 w-2/3 mx-auto"></div>
        </div>
      </div>

      <form action="" className="px-28 mt-8">
        <div className="grid grid-cols-3 gap-6 text-[20px]">
          {" "}
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Date Of Disposal</label>
            <input
              type="date"
              name="date_of_disposal"
              value={formData.date_of_disposal}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-700 mb-2">
              Method Of Disposal
            </label>
            <input
              type="text"
              name="method_of_disposal"
              value={"Sold"}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Selling Value</label>
            <input
              type="number"
              name="selling_value"
              value={formData.selling_value}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="account_deposited_to"
              className="block text-gray-500 mb-2"
            >
              Account Deposited To
            </label>
            <ReactSelect
              id="account_deposited_to"
              name="account_deposited_to"
              options={accounts}
              getOptionLabel={(e) => `${e.name}`} // Display name as label
              getOptionValue={(e) => e.id} // Value will be the account id
              onChange={(selectedOption) =>
                setFormData((prevDetails) => ({
                  ...prevDetails,
                  account_deposited_to: selectedOption ? selectedOption.id : "",
                }))
              }
              value={accounts.find(
                (acc) => acc.id === formData.account_deposited_to
              )}
              className="w-full p-2 border rounded-lg"
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="account_credited_to"
              className="block text-gray-500 mb-2"
            >
              Account Credited To
            </label>
            <ReactSelect
              id="account_credited_to"
              name="account_credited_to"
              options={accounts}
              getOptionLabel={(e) => `${e.name}`} // Display name as label
              getOptionValue={(e) => e.id} // Value will be the account id
              onChange={(selectedOption) =>
                setFormData((prevDetails) => ({
                  ...prevDetails,
                  account_credited_to: selectedOption ? selectedOption.id : "",
                }))
              }
              value={accounts.find(
                (acc) => acc.id === formData.account_credited_to
              )}
              className="w-full p-2 border rounded-lg"
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="account_debited_to"
              className="block text-gray-500 mb-2"
            >
              Account Debited To
            </label>
            <ReactSelect
              id="account_debited_to"
              name="account_debited_to"
              options={accounts}
              getOptionLabel={(e) => `${e.name}`} // Display name as label
              getOptionValue={(e) => e.id} // Value will be the account id
              onChange={(selectedOption) =>
                setFormData((prevDetails) => ({
                  ...prevDetails,
                  account_debited_to: selectedOption ? selectedOption.id : "",
                }))
              }
              value={accounts.find(
                (acc) => acc.id === formData.account_debited_to
              )}
              className="w-full p-2 border rounded-lg"
            />
          </div>
        </div>
        <button
          className="px-4 py-2 bg-black text-white rounded-md mt-12 grid justify-items-end"
          type="button"
          onClick={handleSubmit}
        >
          Save
        </button>
      </form>
    </div>
  );
}

export default AddDisposal;
