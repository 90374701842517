import React, { useState, useEffect } from "react";
import Notification from "../../../components/notification/notification";
import FileUpload from "./upload_files";
import axios from "axios";
import { useNavigate } from "react-router-dom"; 

function BudgetVariance() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [budgetData, setBudgetData] = useState([]); // State to store fetched budget data
  const [filters, setFilters] = useState({
    from_month: "November", // Default values
    to_month: "December",
    year: new Date().getFullYear(), // Default to the current year
  });
  const [years, setYears] = useState([]); // State to store years for the dropdown
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const navigate = useNavigate(); 

  // Dynamically generate years for the dropdown
  useEffect(() => {
    const generateYears = () => {
      const startYear = 1990;
      const currentYear = new Date().getFullYear();
      const yearsArray = [];
      for (let year = startYear; year <= currentYear; year++) {
        yearsArray.push(year);
      }
      setYears(yearsArray);
    };

    generateYears();
  }, []);

  // Fetch budget summary data from the backend
  useEffect(() => {
    const fetchBudgetData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/budget/budgetSummary`, {
          params: filters, // Use selected filters
        });

        if (response.data && Array.isArray(response.data)) {
          setBudgetData(response.data);
        } else {
          console.warn("No data received or incorrect data format.");
          setBudgetData([]);
        }
      } catch (error) {
        console.error("Error fetching budget data:", error);
      }
    };

    fetchBudgetData();
  }, [backendUrl, filters]); // Update when filters change

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAddBudget = () => {
    navigate("/finance-management/add-budget"); // This will navigate to /finance/add-budget
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-8xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4">Budget Variation</h2>
        <Notification />

        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-4 w-2/3 mx-auto">
            {/* From Month */}
            <div className="w-1/3">
              <label htmlFor="from_month" className="block text-gray-500 mb-2">
                From Month
              </label>
              <select
                id="from_month"
                name="from_month"
                className="w-full p-2 border rounded-lg"
                value={filters.from_month}
                onChange={(e) =>
                  setFilters({ ...filters, from_month: e.target.value })
                }
              >
                <option value="">-- Select Month --</option>
                {[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ].map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>

            {/* To Month */}
            <div className="w-1/3">
              <label htmlFor="to_month" className="block text-gray-500 mb-2">
                To Month
              </label>
              <select
                id="to_month"
                name="to_month"
                className="w-full p-2 border rounded-lg"
                value={filters.to_month}
                onChange={(e) =>
                  setFilters({ ...filters, to_month: e.target.value })
                }
              >
                <option value="">-- Select Month --</option>
                {[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ].map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>

            {/* Year Dropdown */}
            <div className="w-1/3">
              <label htmlFor="year" className="block text-gray-500 mb-2">
                Year
              </label>
              <select
                id="year"
                name="year"
                className="w-full p-2 border rounded-lg"
                value={filters.year}
                onChange={(e) =>
                  setFilters({ ...filters, year: e.target.value })
                }
              >
                <option value="">-- Select Year --</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Upload Button */}
          <button
            className="px-4 py-2 bg-black text-white rounded-md"
            onClick={handleAddBudget}
          >
            Add New Budget
          </button>
        </div>

        <h3 className="text-2xl font-semibold mb-4">Budget Variation Report</h3>
      </div>

      <div className="flex justify-center items-start h-screen mt-6">
        <div className="w-full max-w-7xl">
          <div className="overflow-x-auto bg-white rounded-lg shadow-md">
            <table className="w-full table-auto border-collapse border border-gray-200">
              <thead className="sticky top-0 bg-gray-50">
                <tr className="border-b border-gray-200">
                  <th className="py-5 px-3 text-left w-1/6 border border-gray-300">
                    Account Code
                  </th>
                  <th className="py-5 px-3 text-left w-1/6 border border-gray-300">
                    Account
                  </th>
                  <th className="py-5 px-3 text-left w-1/6 border border-gray-300">
                    Budget Value
                  </th>
                  <th className="py-5 px-3 text-right w-1/6 border border-gray-300">
                    Actual Value
                  </th>
                  <th className="py-5 px-3 text-right w-1/6 border border-gray-300">
                    Variance
                  </th>
                </tr>
              </thead>
              <tbody>
                {budgetData.length === 0 ? (
                  <tr>
                    <td colSpan="5" className="text-center py-5">
                      No data available for the selected filters.
                    </td>
                  </tr>
                ) : (
                  budgetData.map((item, index) => (
                    <tr key={index} className="border-b border-gray-200">
                      <td className="py-5 px-3 text-left">
                        {item.account_code}
                      </td>
                      <td className="py-5 px-3 text-left">
                        {item.account_name}
                      </td>
                      <td className="py-5 px-3 text-left">
                        {item.budget_value?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) || 0} LKR
                      </td>
                      <td className="py-5 px-3 text-right">
                        {(
                          item.budget_value - item.difference
                        ).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) || 0} LKR
                      </td>
                      <td className="py-5 px-3 text-right">
                        {item.difference?.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) || 0} LKR
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* File Upload Modal */}
      {isModalOpen && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
          <div className="p-8 bg-white rounded-lg">
            <FileUpload
              setUploadedFiles={setUploadedFiles}
              uploadedFiles={uploadedFiles}
              onClose={handleCloseModal}
            />

            <button
              className="p-3 py-2 mt-4 text-white bg-red-500 rounded-lg"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BudgetVariance;
