import React, { useState, useEffect } from 'react';

const AccountsTable = ({ data, level = 0 }) => {
    const [expandedRows, setExpandedRows] = useState({});
    const [totals, setTotals] = useState({ totalDebit: 0, totalCredit: 0 });

    const expandAllRows = (accounts) => {
        const expanded = {};
        const recursivelyExpand = (accountList) => {
            accountList.forEach((account) => {
                expanded[account.account_id] = true;
                if (account.children && account.children.length > 0) {
                    recursivelyExpand(account.children);
                }
            });
        };
        recursivelyExpand(accounts);
        return expanded;
    };

    useEffect(() => {
        setExpandedRows(expandAllRows(data));
    }, [data]);

    useEffect(() => {
        const computeTotals = (accounts) => {
            let totalDebit = 0;
            let totalCredit = 0;

            const firstLevelAccounts = accounts.filter(account => !account.parent_account_id);

            // Aggregate only the first-level accounts
            firstLevelAccounts.forEach((account) => {
                const debit = parseFloat(account.total_debit || 0);
                const credit = parseFloat(account.total_credit || 0);

                if (debit > credit) {
                    totalDebit += debit - credit;
                } else {
                    totalCredit += credit - debit;
                }
            });

            return { totalDebit: totalDebit.toFixed(2), totalCredit: totalCredit.toFixed(2) };
        };

        setTotals(computeTotals(data));
    }, [data]);

    const toggleRowExpansion = (accountId) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [accountId]: !prevState[accountId],
        }));
    };

    const renderRows = (accounts) => {
        return accounts.map((account) => {
            const debit = parseFloat(account.total_debit || 0);
            const credit = parseFloat(account.total_credit || 0);
            const debitValue = debit > credit ? (debit - credit).toFixed(2) : 0;
            const creditValue = credit > debit ? (credit - debit).toFixed(2) : 0;

            return (
                <React.Fragment key={account.account_id}>
                    <tr className="border-b">
                        <td
                            className="px-4 py-2"
                            style={{ width: '50%', paddingLeft: `${level * 20}px` }}
                        >
                            {account.children && account.children.length > 0 && (
                                <button
                                    onClick={() => toggleRowExpansion(account.account_id)}
                                    className="mr-2 focus:outline-none"
                                >
                                    {expandedRows[account.account_id] ? '▼' : '▶'}
                                </button>
                            )}
                            {account.account_code} - {account.account_name}
                        </td>
                        <td className="px-4 py-2 text-right" style={{ width: '25%' }}>
                            {parseFloat(debitValue).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })} LKR
                        </td>
                        <td className="px-4 py-2 text-right" style={{ width: '25%' }}>
                            {parseFloat(creditValue).toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })} LKR
                        </td>
                    </tr>
                    {expandedRows[account.account_id] && account.children && (
                        <tr>
                            <td colSpan="3" className="p-0">
                                <AccountsTable data={account.children} level={level + 1} />
                            </td>
                        </tr>
                    )}
                </React.Fragment>
            );
        });
    };

    return (
        <table className="min-w-full border border-gray-200 text-left bg-white table-fixed">
            {level === 0 && (
                <thead>
                <tr className="bg-gray-100">
                    <th className="py-2 px-4 border-b text-left" style={{ width: '50%' }}>
                        Account Name
                    </th>
                    <th className="py-2 px-4 border-b text-right" style={{ width: '25%' }}>
                        Debit
                    </th>
                    <th className="py-2 px-4 border-b text-right" style={{ width: '25%' }}>
                        Credit
                    </th>
                </tr>
                </thead>
            )}
            <tbody>
            {renderRows(data)}
            {level === 0 && (
                <tr className="bg-gray-200 font-bold">
                    <td className="px-4 py-2 text-left">Total</td>
                    <td className="px-4 py-2 text-right">{parseFloat(totals.totalDebit).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })} LKR</td>
                    <td className="px-4 py-2 text-right">{parseFloat(totals.totalCredit).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })} LKR</td>
                </tr>
            )}
            </tbody>
        </table>
    );
};

export default AccountsTable;
