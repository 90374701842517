import React, { useEffect, useState, useRef } from "react";
import {IoAdd, IoDocumentSharp} from "react-icons/io5";
import { FaCheck, FaEdit, FaTrash } from "react-icons/fa";
import { IoDocumentLockSharp } from "react-icons/io5";
import { FaPlugCircleCheck } from "react-icons/fa6";
import { MdClosedCaptionDisabled } from "react-icons/md";
import axios from "axios";
import Add_payment_entry_popup from "./add_payment_entry_popup";
import Edit_payment_entry_popup from "./edit_payment_entry_popup";
import Check_pdf_preview from "./check_pdf_preview";
import Details from "./details_popup";
import PaymentEntryModalForBulk from "./PaymentEntryModalForBulk";
import usePermissions from "../../../components/permissions/permission";
import Papa from "papaparse";
import jsPDF from "jspdf";
import Cookies from "js-cookie";

const PaymentEntry = () => {
  //const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const backendUrl = `http://localhost:8089/v1/finance`;
  const { hasPermission } = usePermissions();

  const [seletedValue, setSeletedValue] = useState({
    party_type: "",
    entity_id: "",
    payment_type: "",
    approval_status: "",
    start_date: "",
    end_date: "",
    reference_number: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default rows per page
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [viewCheckData, setViewCheckData] = useState(false);
  const [isCrossCheque, setIsCrossCheque] = useState(false);
  const [details, setDetails] = useState(false);
  const [popupDetails, setPopupDetails] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isBulkModalOpen, setIsBulkModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    page_size: 20,
    total: 0,
    total_pages: 0,
  });

  const actionsRef = useRef(null); // Ref for the "actions" element
  const tableRef = useRef(null); // Ref for the table element

  // Handler for the "Actions" button
  const handleActionsClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleRowSelect = (id) => {
    if (selectedRow === id) {
      setSelectedRow(null);
      setDropdownVisible(false);
    } else {
      setSelectedRow(id);
      setDropdownVisible(true);
    }
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const toggleBulkModal = () => {
    setIsBulkModalOpen(!isBulkModalOpen);
    if(!isBulkModalOpen){
      setPopupDetails({})
    }
  };

  const handleBulkPayment = () => {
    console.log("popupDetails: ", popupDetails);
    toggleBulkModal(); // Open Bulk Payment modal
  };

  const editePopup = () => {
    getTableData();
    setIsOpenEdit(!isOpenEdit);
  };

  const detailsPopup = async () => {
    setDetails(!details);

    if (!selectedRow) {
      console.error("No row selected!");
      return;
    }

    try {
      const response = await axios.get(`${backendUrl}/paymentEntry/getPaymentById/${selectedRow.id}`);
      setPopupDetails(response.data);
    } catch (error) {
      console.error("Failed to fetch payment details:", error);
      alert("Unable to fetch payment details. Please try again later.");
    }
  };

  const viewCheckPopup = () => {
    setViewCheckData(!viewCheckData);
  };

  const handleEdit = async () => {
    toggleBulkModal()
    try {
      const response = await axios.get(`${backendUrl}/paymentEntry/getPaymentById/${selectedRow.id}`);
      setPopupDetails(response.data);
    } catch (error) {
      console.error("Failed to fetch payment details:", error);
      alert("Unable to fetch payment details. Please try again later.");
    }
    //setIsOpenEdit(true);
    console.log("Edit row: ", selectedRow);
  };

  const handleDelete = async () => {
    console.log("Delete row: ", selectedRow);
    try {
      const response = await axios.delete(`${backendUrl}/paymentEntry/deletePaymentEntry/${selectedRow.id}`, {
        data: { action_by: Cookies.get("employee_no") }
      });
      setDropdownVisible(false);
      setSelectedRow(null);
      console.log("Payment entry deleted:", response);
      await getTableData();
    } catch (error) {
      console.error("Error deleting journal entry:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeletedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setPagination((prevState) => ({
      ...prevState,
      current_page: 1,
    }));
  };

  // Function to handle fetching table data
  const getTableData = async () => {
    try {
      const params = {
        page_size: rowsPerPage,
        page: pagination.current_page,
        ...seletedValue, // Include your filter parameters
      };

      const response = await axios.get(
        `${backendUrl}/paymentEntry/getAllPaymentsByFilters`,
        { params }
      );

      console.log(response.data);

      if (response.data) {
        setTableData(response.data.data);
        setPagination({
          current_page: response.data.pagination.current_page,
          page_size: response.data.pagination.page_size,
          total: response.data.pagination.total,
          total_pages: response.data.pagination.total_pages,
        });
      } else {
        console.error("Unexpected response format:", response.data);
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching sample details:", error);
      setTableData([]);
    }
  };

  const handleRowsPerPageChange = (newPageSize) => {
    setRowsPerPage(newPageSize);
    setPagination((prev) => ({
      ...prev,
      page_size: newPageSize,
      current_page: 1, // Reset to the first page
    }));
  };

  const PdfExport = () => {
    const doc = new jsPDF();
    doc.text("Payments Overview", 14, 10);

    // AutoTable for tableData
    doc.autoTable({
      startY: 20,
      head: [
        [
          "Party Name", // Change from "Account Code"
          "Status", // Change from "Status"
          "Payment Type", // Change from "Report Type"
          "Posting Date", // Change from "Root Type"
          "Payment Method", // Change from "Account Type ID"
          "Payment Amount",
          "Document",
        ],
      ],
      body: tableData.map((row) => [
        row.entity_id, // Party Name
        row.approval_status === "Approved" ? "Approved" : "Not Approved", // Status
        row.payment_type, // Payment Type
        new Date(row.prepared_date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long", // Posting Date formatted
          day: "2-digit",
        }),
        row.payment_method, // Payment Method
        row.amount, // Payment Amount
        "PDF Available", // Document
      ]),
    });

    doc.save("payments_overview.pdf");
  };

  const ExcelExport = () => {
    if(tableData.length > 0){
      const csvData = Papa.unparse(
          tableData.map((row) => ({
            "Party Name": row.entity_id, // Party Name
            Status:
                row.approval_status === "Approved" ? "Approved" : "Not Approved", // Status
            "Payment Type": row.payment_type, // Payment Type
            "Posting Date": new Date(row.prepared_date).toLocaleDateString(
                "en-US",
                {
                  year: "numeric",
                  month: "long", // Posting Date formatted
                  day: "2-digit",
                }
            ),
            "Payment Method": row.payment_method, // Payment Method
            "Payment Amount": row.amount, // Payment Amount
            Document: "PDF Available", // Document placeholder
          }))
      );

      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "payments_overview.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Function to handle PDF download
  const downloadPDF = async (id) => {
    try {
      const response = await axios.get(
        `${backendUrl}/paymentEntry/downloadPaymentDocument/${id}`,
        {
          responseType: "blob",
        }
      );

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = "paymentEntry";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("Error downloading the PDF:", error);
    }
  };

  const handleApprove = () => {
    console.log("handleApprove");
  };

  const handleDisable = () => {
    console.log("handleDisable");
  };

  const handleChecked = () => {
    console.log("handleChecked");
  };

  const handleCheckView = (type) => {
    if(type === "Cross"){
      setIsCrossCheque(true)
    }
    if(type === "Open"){
      setIsCrossCheque(false)
    }
    setViewCheckData(true);
  };

  // Handler for closing dropdown and deselecting row on outside click
  const handleClickOutside = (event) => {
    if (actionsRef.current && !actionsRef.current.contains(event.target)) {
      setSelectedRow(null);
      setDropdownVisible(false);
    }
  };

  // Close dropdown and deselect row if clicking outside the table
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); //

  useEffect(() => {
    getTableData();
  }, [rowsPerPage, pagination.current_page, pagination.page_size, seletedValue]);

  return (
    <div className="mx-6 mt-5 text-base">
      <div className="overflow-visible">
        <div className="flex items-center justify-between mt-4">
          <div>
            <p className="text-2xl font-semibold text-black">
              Payment Entry
            </p>
          </div>
        </div>

        <div className="mt-4">
          <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
            <div className="flex flex-wrap gap-4 items-center">
              {/*  Party ID */}
              <div className="w-full md:w-48">
                <label
                    htmlFor="document_id"
                    className="block mb-2 text-sm font-medium text-black"
                >
                  Payment ID
                </label>
                <input
                    id="document_id"
                    name="document_id"
                    type="text"
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                    onChange={handleChange}
                />
              </div>

              {/* Reference Number */}
              {/* <div className="w-full md:w-[200px] relative">
                <label
                  htmlFor="reference_number"
                  className="block mb-2 text-sm font-medium text-black"
                >
                  Reference Number
                </label>
                <input
                  id="reference_number"
                  name="reference_number"
                  type="text"
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                  onChange={handleRefNumChange}
                />
              </div> */}

              {/* Payment Type */}
              <div className="w-full md:w-[150px] relative">
                <label
                    htmlFor="payment_type"
                    className="block mb-2 text-sm font-medium text-black"
                >
                  Payment Type
                </label>
                <select
                    id="payment_type"
                    name="payment_type"
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                    onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="Pay">PO Pay</option>
                  <option value="Bill pay">Bill Pay</option>
                  <option value="Receive">Receive</option>
                  <option value="Internal Transfer">Internal Transfer</option>
                </select>
              </div>

              <div className="w-full md:w-[200px] relative">
                <label
                    htmlFor="entity_id"
                    className="block mb-2 text-sm font-medium text-black"
                >
                  Entity ID
                </label>
                <input
                    id="entity_id"
                    name="entity_id"
                    type="text"
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                    onChange={handleChange}
                />
              </div>

              {/* Payment Method */}
              <div className="w-full md:w-[150px] relative">
                <label
                    htmlFor="payment_method"
                    className="block mb-2 text-sm font-medium text-black"
                >
                  Payment Method
                </label>
                <select
                    id="payment_method"
                    name="payment_method"
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                    onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="Cash">Cash</option>
                  <option value="Card">Card</option>
                  <option value="Credit">Credit</option>
                  <option value="Cheque">Cheque</option>
                </select>
              </div>

              {/* Account Status */}
              <div className="w-full md:w-[150px] relative">
                <label
                    htmlFor="approval_status"
                    className="block mb-2 text-sm font-medium text-black"
                >
                  Status
                </label>
                <select
                    id="approval_status"
                    name="approval_status"
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                    onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="Prepared">Prepared</option>
                  <option value="Checked">Checked</option>
                  <option value="Approved">Approved</option>
                  <option value="Rejected">Rejected</option>
                </select>
              </div>

              {/* Start Date */}
              <div className="w-full md:w-[150px] relative">
                <label
                    htmlFor="start_date"
                    className="block mb-2 text-sm font-medium text-black"
                >
                  Start Date
                </label>
                <input
                    id="start_date"
                    name="start_date"
                    type="date"
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                    onChange={handleChange}
                />
              </div>

              {/* End Date */}
              <div className="w-full md:w-[150px] relative">
                <label
                    htmlFor="end_date"
                    className="block mb-2 text-sm font-medium text-black"
                >
                  End Date
                </label>
                <input
                    id="end_date"
                    name="end_date"
                    type="date"
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                    onChange={handleChange}
                />
              </div>
            </div>

            <div
                className="relative flex items-center gap-4 md:mt-0"
                id="actions"
                ref={actionsRef}
            >
              {/* <button
                className="flex items-center px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                onClick={PdfExport}
              >
                <IoAdd className="text-xl" />
                PDF Export
              </button> */}

              <button
                  className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                onClick={ExcelExport}
              >
                <IoAdd className="text-xl" />
                Excel Export
              </button>

              {selectedRow ? (
                // Action Dropdown
                <div className="relative">
                  <button
                    onClick={() => setDropdownVisible(!dropdownVisible)}
                    className="px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                  >
                    Actions
                  </button>
                  {dropdownVisible && (
                    <div className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg w-64">
                      {hasPermission(3082) && (
                        <button
                          onClick={handleEdit}
                          className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                        >
                          <FaEdit className="mr-2" /> Edit
                        </button>
                      )}

                      {hasPermission(3083) && (
                        <button
                          onClick={handleDelete}
                          className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                        >
                          <FaTrash className="mr-2" /> Delete
                        </button>
                      )}

                      {hasPermission(3087) && (
                        <>
                          {selectedRow.payment_method === "Cheque" && (
                            <button
                              onClick={() => {handleCheckView("Cross")}}
                              className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                            >
                              <IoDocumentLockSharp className="mr-2" /> Print as Cross Cheque
                            </button>
                          )}
                        </>
                      )}

                      {hasPermission(3087) && (
                          <>
                            {selectedRow.payment_method === "Cheque" && (
                                <button
                                    onClick={() => {handleCheckView("Open")}}
                                    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                                >
                                  <IoDocumentSharp  className="mr-2" /> Print as Open Cheque
                                </button>
                            )}
                          </>
                      )}

                      {hasPermission(3087) && (
                        <button
                          onClick={detailsPopup}
                          className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                        >
                          <FaPlugCircleCheck className="mr-2" /> Details
                        </button>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                hasPermission(3081) && ( // Correctly remove curly braces from JSX block
                    <div className="relative">
                      <button
                          className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                          onClick={() => handleBulkPayment()}
                      >
                        <IoAdd className="text-xl"/>
                        New
                      </button>
                      {/*{dropdownVisible && (*/}
                      {/*    <div*/}
                      {/*        className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg w-64">*/}
                      {/*      /!* Single Payment Option *!/*/}
                      {/*      /!*<button*!/*/}
                      {/*      /!*    onClick={() => {*!/*/}
                      {/*      /!*      togglePopup(); // This opens the existing single payment modal*!/*/}
                      {/*      /!*      setDropdownVisible(false); // Close the dropdown*!/*/}
                      {/*      /!*    }}*!/*/}
                      {/*      /!*    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"*!/*/}
                      {/*      /!*>*!/*/}
                      {/*      /!*  Single Payment*!/*/}
                      {/*      /!*</button>*!/*/}

                      {/*      /!* Bulk Payment Option *!/*/}
                      {/*      <button*/}
                      {/*          onClick={() => {*/}
                      {/*            handleBulkPayment(); // Open the bulk payment modal*/}
                      {/*            setDropdownVisible(false); // Close the dropdown*/}
                      {/*          }}*/}
                      {/*          className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"*/}
                      {/*      >*/}
                      {/*        Bulk Payment*/}
                      {/*      </button>*/}
                      {/*    </div>*/}
                      {/*)}*/}
                    </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6" ref={tableRef}>
        <div>
          <table className="min-w-full bg-white border border-gray-400">
            <thead className="bg-gray-200 text-md">
            <tr>
              <th className="px-4 py-2 font-semibold text-left text-gray-700">ID</th>
              <th className="px-4 py-2 font-semibold text-left text-gray-700">Payment ID</th>
              <th className="px-4 py-2 font-semibold text-left text-gray-700">Status</th>
              <th className="px-4 py-2 font-semibold text-left text-gray-700">Payment Type</th>
              <th className="px-4 py-2 font-semibold text-left text-gray-700">Entity ID</th>
              <th className="px-4 py-2 font-semibold text-left text-gray-700">Posting Date</th>
              <th className="px-4 py-2 font-semibold text-left text-gray-700">Payment Method</th>
              <th className="px-4 py-2 font-semibold text-left text-gray-700">Payment Amount</th>
              <th className="px-4 py-2 font-semibold text-left text-gray-700">Document</th>
            </tr>
            </thead>
            <tbody>
            {tableData.map((row, index) => (
                <tr
                    key={index}
                    className={`${
                        selectedRow === row ? "bg-gray-200 text-black" : "hover:bg-gray-50"
                    }`}
                    onClick={() => handleRowSelect(row)}
                >
                  <td className="px-4 py-2 border-t">{row.payment_id}</td>
                  <td className="px-4 py-2 border-t">{row.document_id}</td>
                  <td className="px-4 py-2 border-t">
                <span
                    className={`font-semibold ${
                        row.approval_status === "Approved"
                            ? "text-green-500"
                            : "text-red-500"
                    }`}
                >
                  {row.approval_status}
                </span>
                  </td>
                  <td className="px-4 py-2 border-t">{row.payment_type === "Pay"? "PO Pay" : row.payment_type }</td>
                  <td className="px-4 py-2 border-t">{row.entity_id}</td>
                  <td className="px-4 py-2 border-t">
                    {new Date(row.prepared_date).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    })}
                  </td>
                  <td className="px-4 py-2 border-t">{row.payment_method}</td>
                  <td className="px-4 py-2 border-t">
                    {parseFloat(row.amount).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </td>
                  <td className="px-4 py-2 border-t">
                    <button
                        onClick={() => downloadPDF(row.id)}
                        className="px-2 py-1 bg-green-500 text-white text-sm rounded hover:bg-green-600"
                    >
                      Download
                    </button>
                  </td>
                </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between mt-5">
        <div>
          <label className="block mb-2 text-sm font-medium text-black">
            Rows per page:
          </label>
          <select
              value={rowsPerPage}
              onChange={(e) => handleRowsPerPageChange(parseInt(e.target.value))}
              className="p-2 border border-black rounded focus:ring-2 focus:ring-black"
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div>
    <span>
      Page {pagination.current_page} of {pagination.total_pages}
    </span>
          <button
              onClick={() =>
                  setPagination((prev) => ({
                    ...prev,
                    current_page: Math.max(prev.current_page - 1, 1),
                  }))
              }
              disabled={pagination.current_page === 1}
              className="px-4 py-2 ml-2 bg-black text-white rounded hover:bg-gray-800"
          >
            Previous
          </button>
          <button
              onClick={() =>
                  setPagination((prev) => ({
                    ...prev,
                    current_page: Math.min(prev.current_page + 1, prev.total_pages),
                  }))
              }
              disabled={pagination.current_page === pagination.total_pages}
              className="px-4 py-2 ml-2 bg-black text-white rounded hover:bg-gray-800"
          >
            Next
          </button>
        </div>
      </div>


      {isOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
              <Add_payment_entry_popup togglePopup={togglePopup} refreshTable={getTableData}/>
            </div>
          </div>
      )}

      {isOpenEdit && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
              <Edit_payment_entry_popup
                  editePopup={editePopup}
                  row={selectedRow}
              />
            </div>
          </div>
      )}

      {viewCheckData && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
              <Check_pdf_preview
              viewCheckPopup={viewCheckPopup}
              row={selectedRow}
              isCrossCheque = {isCrossCheque}
            />
          </div>
        </div>
      )}

      {details && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <Details detailsPopup={detailsPopup} details={popupDetails} refreshTable={getTableData}/>
          </div>
        </div>
      )}

      {/* Bulk Payment Modal */}
      {isBulkModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
              <PaymentEntryModalForBulk toggleModal={toggleBulkModal} editPaymentData ={popupDetails} refreshTable={getTableData} />
            </div>
          </div>
      )}
    </div>
  );
};

export default PaymentEntry;
