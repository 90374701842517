import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import ReactSelect from "react-select";

function EditCategory({ row,onClose }) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;

  // State to manage form data
  const [formData, setFormData] = useState({
    category_name: "",
    description: "",
    depreciation_account_id: "",
    cumulative_account_id: "",
  });

  const [accounts, setAccounts] = useState([]); // Store all accounts for dropdown
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const userId = Cookies.get("employee_no");

  // Prepopulate formData with the selected row data
  useEffect(() => {
    if (row) {
      setFormData({
        id: row.id || "",
        category_name: row.category_name || "",
        description: row.description || "",
        depreciation_account_id: row.depreciation_account_id || "",
        cumulative_account_id: row.cumulative_account_id || "",
      });
    }
  }, [row]);

  // Fetch accounts from the API
  const fetchAccounts = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/account/getAllAccountsByFilters`
      );
  
      // Check if response is structured correctly
      console.log("Accounts API Response:", response.data);
  
      // Map response to dropdown format
      const formattedAccounts = response.data?.data?.map((item) => ({
        id: item.account_id,
        name: `${item.account_code} - ${item.account_name}`,
      }));
  
      // Update state with fallback for empty data
      setAccounts(formattedAccounts || []);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };
  
  useEffect(() => {
    fetchAccounts();
  }, []);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Validate form data
  const validateFormData = () => {
    const requiredFields = [
      "category_name",
      "description",
      "depreciation_account_id",
      "cumulative_account_id",
    ];
    for (const field of requiredFields) {
      if (!formData[field]) {
        alert(`Please fill in the ${field.replace("_", " ")} field.`);
        return false;
      }
    }
    return true;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (!validateFormData()) return;

    const submissionData = {
      ...formData,
      created_by: userId || "defaultUserId",
    };

    try {
      // Use the correct ID in the URL
      await axios.put(
        `${backendUrl}/assets/assets/updateAssetCategories/${formData.id}`,
        submissionData
      );
      alert("Category updated successfully!");
      window.location.reload(); // Refresh the page
    } catch (error) {
      console.error("Error updating category:", error);
      alert("An error occurred while updating the Category.");
    }
  };

  const handleClose = () => {
    if (onClose) onClose(); 
    
  };

  const handleEditModalClose = (e) => {
    if (e) e.preventDefault();
    if (e) e.stopPropagation();
    if (onClose) onClose(); // Close modal logic
    setIsEditModalOpen(false); // Close the modal
  };
  return (
    <div className="p-6 bg-white rounded shadow">
      <div className="max-w-8xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4 text-center">Edit Category</h2>
      </div>

      <form className="px-28 mt-8">
        <div className="grid grid-cols-3 gap-6 text-[20px]">
          {/* Category Name */}
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Category Name</label>
            <input
              type="text"
              name="category_name"
              value={formData.category_name}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          {/* Description */}
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Description</label>
            <input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          {/* Depreciation Account */}
          <div className="w-full">
            <label
              htmlFor="depreciation_account_id"
              className="block text-gray-500 mb-2"
            >
              Depreciation Account
            </label>
            <ReactSelect
              id="depreciation_account_id"
              name="depreciation_account_id"
              options={accounts}
              getOptionLabel={(e) => `${e.name}`} // Display name as label
              getOptionValue={(e) => e.id} // Value will be the account id
              onChange={(selectedOption) =>
                setFormData((prevDetails) => ({
                  ...prevDetails,
                  depreciation_account_id: selectedOption
                    ? selectedOption.id
                    : "",
                }))
              }
              value={accounts.find(
                (acc) => acc.id === formData.depreciation_account_id
              )}
              className="w-full p-2 border rounded-lg"
            />
          </div>

          {/* Cumulative Account */}
          <div className="w-full">
            <label
              htmlFor="cumulative_account_id"
              className="block text-gray-500 mb-2"
            >
              Cumulative Account
            </label>
            <ReactSelect
              id="cumulative_account_id"
              name="cumulative_account_id"
              options={accounts}
              getOptionLabel={(e) => `${e.name}`} // Display name as label
              getOptionValue={(e) => e.id} // Value will be the account id
              onChange={(selectedOption) =>
                setFormData((prevDetails) => ({
                  ...prevDetails,
                  cumulative_account_id: selectedOption
                    ? selectedOption.id
                    : "",
                }))
              }
              value={accounts.find(
                (acc) => acc.id === formData.cumulative_account_id
              )}
              className="w-full p-2 border rounded-lg"
            />
          </div>
        </div>

        <div className="flex justify-end mt-4">
            
            <button
              type="button"
              className="px-4 py-2 bg-black text-white rounded-md mt-12 grid justify-items-end"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
      </form>
      <div>
        <button onClick={() => setIsModalOpen(true)}></button>
        {isModalOpen && (
          <EditCategory
            id={selectedId} // Ensure `selectedId` is defined
            onClose={handleEditModalClose} // Pass the `handleClose` function
          />
        )}
      </div>
    </div>
  );
}

export default EditCategory;
