import React, { useState, useEffect } from "react";
import ReactSelect from "react-select";
import Notification from "../../../components/notification/notification";
import {IoIosCloseCircleOutline, IoIosCloudUpload} from "react-icons/io";
import axios, {get} from "axios";
import Cookies from "js-cookie";
import PaymentDocumentCard from "./PaymentDocumentCard";
import FileUpload from "./upload_files";

const PaymentEntryModalForBulk = ({
                                      toggleModal,
                                      editPaymentData = null,
                                      refreshTable
                                  }) => {
    const getCurrentDate = () => {
        const today = new Date();
        return today.toISOString().split("T")[0];
    };
    const [paymentEntry, setPaymentEntry] = useState({
        payment_type: "",
        entity_id: "",
        linked_documents: [],
        paid_from_account_id: "",
        paid_to_account_id: "",
        amount: "",
        payment_date: getCurrentDate(),
        payment_method: "",
        is_bulk: "",
        description: "",
        cheque_number: "",
        cheque_date: getCurrentDate(),
        payee_name: "",
    });

    //const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    const backendUrl = `http://localhost:8089/v1/finance`;
    const [documentOptions, setDocumentOptions] = useState([]);
    const [entityOptions, setEntityOptions] = useState([]);
    const [notification, setNotification] = useState({ message: "", type: "" });
    const [allAccounts, setAllAccounts] = useState([]);
    const [unpaidDetails, setUnpaidDetails] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

    useEffect(() => {
        if (editPaymentData && Object.keys(editPaymentData).length > 0) {
            (async () => {
                // Fetch entities based on payment type
                await handleFetchEntities(editPaymentData.payment_type);

                // Fetch document options based on entity and payment type
                const documents = await fetchDocumentIds(
                    editPaymentData.payment_type,
                    editPaymentData.entity_id
                );

                // Set document options
                setDocumentOptions(
                    documents?.data?.map((doc) => ({
                        value: doc.document_id,
                        label: doc.document_id,
                    })) || []
                );

                // Filter linked_documents to only store linked_ids as an array
                const linkedDocumentIds = editPaymentData.linked_documents?.map((doc) => doc.linked_id) || [];

                // Update payment entry with filtered linked_documents
                setPaymentEntry((prev) => ({
                    ...prev,
                    payment_type: editPaymentData.payment_type,
                    entity_id: editPaymentData.entity_id,
                    linked_documents: linkedDocumentIds,
                    paid_from_account_id: editPaymentData.paid_from_account_id,
                    paid_to_account_id: editPaymentData.paid_to_account_id,
                    amount: editPaymentData.amount,
                    payment_date: editPaymentData.payment_date || getCurrentDate(),
                    payment_method: editPaymentData.payment_method,
                    is_bulk: editPaymentData.is_bulk,
                    description: editPaymentData.description || "",
                    cheque_number: editPaymentData.cheque_number || "",
                    cheque_date: editPaymentData.cheque_date || getCurrentDate(),
                    payee_name: editPaymentData.payee_name || "",
                }));
            })();
        }
    }, [editPaymentData]);

    const handleInputChange = (name, value) => {
        setPaymentEntry((prev) => ({ ...prev, [name]: value }));
    };

    const fetchPartiesByType = async (partyTypeId) => {
        try {
            const responsePT = await axios.get(`${backendUrl}/journalEntry/getPartyTypeById/${partyTypeId}`);
            return await axios.get(`${backendUrl}/paymentEntry/getPartyByPartyType`, {
                params: { party_type: responsePT.data.party_type },
            });
        } catch (error) {
            console.error('Error fetching entities:', error);
        }
    };

    const handleFetchEntities = async (paymentType) => {
        let entities = [];
        handleInputChange("entity_id", "");
        handleInputChange("linked_documents", []);
        setEntityOptions([])
        setUnpaidDetails([])
        if (paymentType === "Pay" || paymentType === "Bill pay") {
            entities = await fetchPartiesByType(2);
            setEntityOptions(entities?.data?.data.map((e) => ({ value: e.supplier_id, label: e.supplier_name })));
        } else if (paymentType === "Receive") {
            entities = await fetchPartiesByType(1);
            setEntityOptions(entities?.data?.data.map((e) => ({ value: e.customer_id, label: e.customer_name })));
        }

    };

    const fetchDocumentIds = async (pt, entity_id) => {
        try {
            let payment_type;
            if(pt === 'Pay') payment_type = 'PO'
            if(pt === 'Bill pay') payment_type = 'Bill'
            if(pt === 'Receive') payment_type = 'POS'
            if(pt && entity_id){
                return await axios.get(`${backendUrl}/paymentEntry/getUnpaidsByPaymentTypeAndEntityId/${payment_type}/${entity_id}`);
            }
        } catch (error) {
            console.error('Error fetching unpaid Documents for selected supplier:', error);
        }
    };

    const handleFetchDocuments = async (entityId) => {
        handleInputChange("linked_documents", []);
        setDocumentOptions([]);
        setUnpaidDetails([]);
        if (entityId) {
            const documents = await fetchDocumentIds(paymentEntry.payment_type, entityId);
            setDocumentOptions(documents?.data?.map((doc) => ({ value: doc.document_id, label: doc.document_id })));
        }

    };

    const getAccounts = async () => {
        try {
            const params = { is_enabled: 1 };
            const response = await axios.get(
                `${backendUrl}/account/getAllAccountsByFilters`, { params }
            );

            const formattedData = response.data.data.map((item) => ({
                id: item.account_id,
                code: item.account_code,
                name: item.account_name
            }));

            setAllAccounts(formattedData);
        } catch (error) {
            console.error("Error fetching sample details:", error);
        }
    };

    const fetchUnpaidDetails = async () => {
        if (!paymentEntry.payment_type) return;

        let payment_type;

        if(paymentEntry.payment_type === 'Pay') payment_type = 'PO'
        if(paymentEntry.payment_type === 'Bill pay') payment_type = 'Bill'
        if(paymentEntry.payment_type === 'Receive') payment_type = 'POS'

        try {
            if(paymentEntry.linked_documents.length > 0){
                const response = await axios.post(`${backendUrl}/paymentEntry/getUnpaidsByPaymentTypeAndDocIds`, {
                    payment_type: payment_type,
                    doc_ids: paymentEntry.linked_documents,
                });
                setUnpaidDetails(response.data);
                console.log("response.data", response.data)
            }

        } catch (error) {
            console.error("Error fetching unpaid details:", error);
        }
    };

    const toggleUploadModal = () => {
        setIsUploadModalOpen(!isUploadModalOpen);
    };

    const handleRemoveFile = (index) => {
        setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const saveBulkPayment = async (paymentEntry) => {
        console.log(paymentEntry);

        try {
            const paymentData = {
                ...paymentEntry,
                is_bulk: 1,
                transaction_type: "Payment",
                prepared_by: Cookies.get("employee_no"),
            };

            let response;
            if (editPaymentData && Object.keys(editPaymentData).length > 0) {
                response = await axios.put(
                    `${backendUrl}/paymentEntry/update/${editPaymentData.id}`,
                    paymentData
                );
                setNotification({ message: "Payment entry updated successfully.", type: "success" });
            } else {
                response = await axios.post(`${backendUrl}/paymentEntry/add`, paymentData);
                setNotification({ message: "Payment entry created successfully.", type: "success" });
            }

            setNotification({
                message: response.data.message,
                type: "success",
            });

            toggleModal();
            refreshTable();
            return response
        } catch (error) {
            console.error("Error", error);
            setNotification({
                message: error?.response?.data?.error,
                type: "error",
            });
        }
    };

    const handleSave = async (e) => {
        e.preventDefault();
        const isValid = validatePaymentEntry();
        if (isValid) {
            const paymentResponse = await saveBulkPayment(paymentEntry);
            if (paymentResponse) {
                let id;

                if(editPaymentData && Object.keys(editPaymentData).length === 0){
                    id = paymentResponse.data;
                } else {
                    id = editPaymentData.id
                }

                if (uploadedFiles.length > 0) {
                    const formData = new FormData();
                    uploadedFiles.forEach((file) => formData.append("payment_file", file));
                    formData.append("payment_id", id);
                    formData.append("prepared_by", Cookies.get("employee_no"));

                    await axios.post(`${backendUrl}/paymentEntry/uploadPaymentEntryDocs`, formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                    });
                }

                setNotification({ message: "Payment entry created successfully.", type: "success" });
                toggleModal();
            } else {
                setNotification({ message: "Failed to save bulk payment", type: "error" });
            }
        }
    };

    const isPaymentTypeRequiresLinkedDocuments = (paymentType) => {
        return paymentType === 'Pay' || paymentType === 'Bill pay' || paymentType === 'Receive';
    };

    const validatePaymentEntry = () => {
        if (!paymentEntry.payment_type || !paymentEntry.payment_method || !paymentEntry.amount || !paymentEntry.payment_date) {
            setNotification({ message: "Please fill all required fields", type: "error" });
            return false;
        }
        return true;
    };

    useEffect(() => {
        fetchUnpaidDetails();
    }, [paymentEntry.payment_type, paymentEntry.linked_documents]);

    useEffect(() => {
        const updatedUnpaidDetails = unpaidDetails.filter((doc) =>
            paymentEntry.linked_documents.includes(doc.document_id)
        );
        setUnpaidDetails(updatedUnpaidDetails);
    }, [paymentEntry.linked_documents]);

    useEffect(() => {
        getAccounts();
        console.log("editPaymentData", editPaymentData)
    }, []);

    return (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
                <div className="flex items-center justify-between mb-6">
                    <h2 className="text-2xl font-semibold">
                        {editPaymentData && Object.keys(editPaymentData).length > 0 ? "Edit Bulk Payment" : "Add Bulk Payment"}
                    </h2>
                    <button
                        type="button"
                        onClick={toggleModal}
                        className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
                    >
                        <IoIosCloseCircleOutline/>
                    </button>
                </div>

                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification({message: "", type: ""})}
                />

                <form onSubmit={handleSave}>
                    <div className="grid grid-cols-2 gap-y-8 gap-x-16 text-lg">
                        {/* Payment Type */}
                        <div>
                            <label className="block text-gray-700 font-semibold mb-1">
                                Payment Type
                            </label>
                            <ReactSelect
                                options={[
                                    {value: "Pay", label: "GRN Pay"},
                                    {value: "Bill pay", label: "Bill Pay"},
                                    {value: "Receive", label: "Receive"},
                                    {value: "Internal transfer", label: "Internal transfer"},
                                ]}
                                onChange={(option) => {
                                    handleInputChange("payment_type", option?.value || "");
                                    handleFetchEntities(option?.value || "");
                                }}
                                value={paymentEntry.payment_type ? {
                                    value: paymentEntry.payment_type,
                                    label: paymentEntry.payment_type === 'Pay' ? 'GRN Pay' : paymentEntry.payment_type
                                } : null}
                                isClearable
                                placeholder="Select Payment Type"
                                required
                            />
                        </div>

                        {/* Entity */}
                        {paymentEntry.payment_type !== 'Internal transfer' && (
                            <div>
                                <label className="block text-gray-700 font-semibold mb-1">
                                    Entity
                                </label>
                                <ReactSelect
                                    options={entityOptions}
                                    onChange={(option) => {
                                        handleInputChange("entity_id", option?.value || "");
                                        handleFetchDocuments(option?.value || "");
                                    }}
                                    value={entityOptions?.find((option) => option.value === paymentEntry.entity_id) || null}
                                    isClearable
                                    placeholder="Select Entity"
                                    required={isPaymentTypeRequiresLinkedDocuments(paymentEntry.payment_type)}
                                />
                            </div>
                        )}

                        {/* Linked Documents */}
                        {paymentEntry.payment_type !== 'Internal transfer' && (
                            <div className="col-span-2">
                                <label className="block text-gray-700 font-semibold mb-0">
                                    Linked Documents
                                </label>
                                <ReactSelect
                                    options={documentOptions}
                                    isMulti
                                    onChange={(options) => handleInputChange("linked_documents", options.map((opt) => opt.value))}
                                    value={paymentEntry.linked_documents.map((id) => ({value: id, label: id}))}
                                    placeholder="Select Documents"
                                    required={isPaymentTypeRequiresLinkedDocuments(paymentEntry.payment_type)}
                                />
                            </div>
                        )}

                        {unpaidDetails.length > 0 && (
                            <div className="col-span-2  -mt-5">
                                {/* Total Unsettled Amount */}
                                <div className="font-semibold text-base text-TextColor1 mb-2">
                                    Total unsettled amount of selected{" "}
                                    {paymentEntry.payment_type === "Pay"
                                        ? "GRNs"
                                        : paymentEntry.payment_type === "Bill pay"
                                            ? "Bills"
                                            : paymentEntry.payment_type === "Receive"
                                                ? "POS sales"
                                                    : "Documents"}:{" "}
                                    <span className="text-primary text-TextColor2">{
                                        unpaidDetails.reduce((total, document) => total + parseFloat(document.unsettled_amount || 0), 0).toFixed(2)} LKR
                                    </span>
                                </div>

                                {/* Payment Document Cards */}
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mt-0">
                                    {unpaidDetails.map((document) => (
                                        <PaymentDocumentCard
                                            key={document.document_id}
                                            document={document}
                                            paymentType={paymentEntry.payment_type}
                                        />
                                    ))}
                                </div>
                            </div>

                        )}

                        {/* Payment Method */}
                        <div>
                            <label className="block text-gray-700 font-semibold mb-1">
                                Payment Method
                            </label>
                            <ReactSelect
                                options={[
                                    {value: "Cash", label: "Cash"},
                                    {value: "Card", label: "Card"},
                                    {value: "Cheque", label: "Cheque"},
                                ]}
                                onChange={(option) => handleInputChange("payment_method", option?.value || "")}
                                value={paymentEntry.payment_method ? {
                                    value: paymentEntry.payment_method,
                                    label: paymentEntry.payment_method
                                } : null}
                                isClearable
                                placeholder="Select Payment Method"
                                required
                            />
                        </div>

                        {/* Cheque Details */}
                        {paymentEntry.payment_method === "Cheque" && (
                            <>
                                <div>
                                    <label className="block text-gray-700 font-semibold mb-1">Cheque Number</label>
                                    <input
                                        type="text"
                                        value={paymentEntry.cheque_number}
                                        onChange={(e) => handleInputChange("cheque_number", e.target.value)}
                                        className="w-full p-3 border border-gray-300 rounded-md"
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700 font-semibold mb-1">Cheque Date</label>
                                    <input
                                        type="date"
                                        value={paymentEntry.cheque_date  ? new Date(paymentEntry.cheque_date).toISOString().split("T")[0]
                                            : ""}
                                        onChange={(e) => handleInputChange("cheque_date", e.target.value)}
                                        className="w-full p-3 border border-gray-300 rounded-md"
                                        min={editPaymentData && Object.keys(editPaymentData).length === 0? new Date().toISOString().split('T')[0] : new Date(paymentEntry.cheque_date).toISOString().split('T')[0]}
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700 font-semibold mb-1">Payee Name</label>
                                    <input
                                        type="text"
                                        value={paymentEntry.payee_name}
                                        onChange={(e) => handleInputChange("payee_name", e.target.value)}
                                        className="w-full p-3 border border-gray-300 rounded-md"
                                    />
                                </div>
                            </>
                        )}

                        {/* From and To Accounts */}
                        <div>
                            <label className="block text-gray-700 font-semibold mb-1">
                                Paid From Account
                            </label>
                            <ReactSelect
                                options={allAccounts.map((acc) => ({value: acc.id, label: `${acc.code} - ${acc.name}`}))}
                                onChange={(option) => handleInputChange("paid_from_account_id", option?.value || "")}
                                value= {
                                    paymentEntry.paid_from_account_id
                                        ? allAccounts
                                            .filter((account) => account.id === paymentEntry.paid_from_account_id)
                                            .map((account) => ({
                                                value: account.id,
                                                label: `${account.code} - ${account.name}`
                                            }))[0] // selects the first match
                                        : {}
                                }
                                placeholder="Select Account"
                                required
                            />
                        </div>

                        <div>
                            <label className="block text-gray-700 font-semibold mb-1">
                                Paid To Account
                            </label>
                            <ReactSelect
                                options={allAccounts.map((acc) => ({value: acc.id, label: `${acc.code} - ${acc.name}`}))}
                                onChange={(option) => handleInputChange("paid_to_account_id", option?.value || "")}
                                value= {
                                    paymentEntry.paid_to_account_id
                                        ? allAccounts
                                            .filter((account) => account.id === paymentEntry.paid_to_account_id)
                                            .map((account) => ({
                                                value: account.id,
                                                label: `${account.code} - ${account.name}`
                                            }))[0] // selects the first match
                                        : {}
                                }
                                placeholder="Select Account"
                                required
                            />
                        </div>

                        {/* Amount */}
                        <div>
                            <label className="block text-gray-700 font-semibold mb-1">
                                Amount
                            </label>
                            <input
                                type="text"
                                value={paymentEntry.amount}
                                onChange={(e) => handleInputChange("amount", e.target.value)}
                                className="w-full p-3 border border-gray-300 rounded-md"
                                required
                            />
                        </div>

                        {/* Payment Date */}
                        <div>
                            <label className="block text-gray-700 font-semibold mb-1">
                                Payment Date
                            </label>
                            <input
                                type="date"
                                value={paymentEntry.payment_date ? new Date(paymentEntry.payment_date).toISOString().split("T")[0]
                                    : ""}
                                onChange={(e) => handleInputChange("payment_date", e.target.value)}
                                className="w-full p-3 border border-gray-300 rounded-md"
                                min={editPaymentData && Object.keys(editPaymentData).length === 0? new Date().toISOString().split('T')[0] : new Date(paymentEntry.payment_date).toISOString().split('T')[0]}
                                required
                            />
                        </div>

                        {/* Description */}
                        <div className="col-span-2">
                            <label className="block text-gray-700 font-semibold mb-1">
                                Description
                            </label>
                            <textarea
                                value={paymentEntry.description}
                                onChange={(e) => handleInputChange("description", e.target.value)}
                                className="w-full p-3 border border-gray-300 rounded-md"
                            />
                        </div>
                    </div>

                    {/* Document Upload Section */}
                    {uploadedFiles.length === 0 && (
                        <>
                            <h1 className="text-lg font-semibold mt-2 mb-2">
                                Upload Documents
                            </h1>
                            <button
                                type="button"
                                onClick={toggleUploadModal}
                                className="p-3 border border-green-600 rounded-full text-green-600 hover:bg-green-600 hover:text-white transition-all duration-300"
                            >
                                <div className="flex items-center gap-2"><span className="text-sm font-bold">Click Here to Upload</span>
                                    <IoIosCloudUpload className="text-xl"/>
                                </div>
                            </button>
                        </>
                    )}
                    {/* Display Uploaded Files */}
                    {uploadedFiles.length > 0 && (
                        <div className="mt-5">
                            <h3 className="mb-2 text-xl font-bold">Selected Files</h3>
                            {uploadedFiles.map((file, index) => (
                                <div
                                    key={index}
                                    className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
                                >
                                    <span>{file.name}</span>
                                    <button
                                        onClick={() => handleRemoveFile(index)}
                                        className="px-3 py-1 text-white bg-red-500 rounded hover:bg-red-600 transition-colors duration-200"
                                    >
                                        Remove
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}

                    <div className="mt-6 flex justify-end space-x-4">
                        <button
                            type="button"
                            onClick={toggleModal}
                            className="px-6 py-3 text-white bg-gray-400 rounded-md hover:bg-gray-500"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-6 py-3 text-white bg-blue-600 rounded-md hover:bg-blue-700"
                        >
                            Save Payment
                        </button>
                    </div>
                </form>

                {isUploadModalOpen && (
                    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
                        <div className="p-8 bg-white rounded-lg">
                            <FileUpload
                                setUploadedFiles={setUploadedFiles}
                                uploadedFiles={uploadedFiles}
                                onClose={toggleUploadModal}
                            />
                            <button
                                className="p-3 py-2 mt-4 text-white bg-red-500 rounded-lg"
                                onClick={toggleUploadModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PaymentEntryModalForBulk;
