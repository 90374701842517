import React, { useEffect, useRef, useState } from "react";
import { IoAdd } from "react-icons/io5";
import { IoFilter } from "react-icons/io5";
import { FaEdit } from "react-icons/fa";
import { MdDisabledByDefault } from "react-icons/md";
import { VscActivateBreakpoints } from "react-icons/vsc";
import axios from "axios";
import Add_chat_account_popup from "./add_chat_account_popup";
import Edit_chat_account_popup from "./edit_chat_account_popup";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import usePermissions from "../../../components/permissions/permission";
import Cookies from "js-cookie";
import Notification from "../../../components/notification/notification";
import {tab} from "@testing-library/user-event/dist/tab";

const Chart_account = () => {
  const navigate = useNavigate();

  const handleTreeViewNavigation = () => {
    console.log("Navigating to Tree View");
    navigate("/finance-management/account-tree-view");
  };
  const accountTypes = [
    { id: 1, value: "Accumulated Depreciation" },
    { id: 2, value: "Asset Received But Not Billed" },
    { id: 3, value: "Bank" },
    { id: 4, value: "Cash" },
    { id: 5, value: "Chargeable" },
    { id: 6, value: "Capital Work in Progress" },
    { id: 7, value: "Cost of Goods Sold" },
    { id: 8, value: "Current Asset" },
    { id: 9, value: "Current Liability" },
    { id: 10, value: "Depreciation" },
    { id: 11, value: "Direct Expense" },
    { id: 12, value: "Direct Income" },
    { id: 13, value: "Equity" },
    { id: 14, value: "Expense Account" },
    { id: 15, value: "Expenses Included In Asset Valuation" },
    { id: 16, value: "Expenses Included In Valuation" },
    { id: 17, value: "Fixed Asset" },
    { id: 18, value: "Income Account" },
    { id: 19, value: "Indirect Expense" },
    { id: 20, value: "Indirect Income" },
    { id: 21, value: "Liability" },
    { id: 22, value: "Payable" },
    { id: 23, value: "Receivable" },
    { id: 24, value: "Round Off" },
    { id: 25, value: "Stock" },
    { id: 26, value: "Stock Adjustment" },
    { id: 27, value: "Stock Received But Not Billed" },
    { id: 28, value: "Service Received But Not Billed" },
    { id: 29, value: "Tax" },
    { id: 30, value: "Temporary" },
  ];

  const { hasPermission } = usePermissions();

  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const [seletedValue, setSeletedValue] = useState({
    account_name: "",
    account_code: "",
    root_type: "",
    report_type: "",
    account_type: "",
  });

  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default rows per page
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isExportDropdownOpen, setIsExportDropdownOpen] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    page_size: 20,
    total: 0,
    total_pages: 0,
  });

  // Function to toggle the upload modal
  const toggleUploadModal = () => {
    setIsUploadModalOpen(!isUploadModalOpen);
  };

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // Handle file upload submission
  const handleUploadSubmit = async () => {
    if (!selectedFile) {
      setNotification({
        message: "Please select a file to upload.",
        type: "error",
      });
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("created_by", Cookies.get("employee_no"));

    try {
      const response = await axios.post(
          `${backendUrl}/account/accountBulkUpload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
      );

      setNotification({
        message: response.data.message,
        type: "success",
      });
      toggleUploadModal();
    } catch (error) {
      console.error("Error uploading file:", error);
      setNotification({
        message: error?.response?.data?.error || "File upload failed.",
        type: "error",
      });
    }
  };

  const handleRowSelect = (id) => {
    console.log(id);
    if (selectedRow === id) {
      setSelectedRow(null);
      setDropdownVisible(false);
    } else {
      setSelectedRow(id);
      setDropdownVisible(true);
    }
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const editePopup = () => {
    setIsOpenEdit(!isOpenEdit);
  };

  const handleEdit = () => {
    console.log("Edit row: ", selectedRow);
    setIsOpenEdit(true);
  };

  const handleDisable = async () => {
    console.log("Delete row: ", selectedRow);
    try {
      const response = await axios.put(
        `${backendUrl}/account/${selectedRow.account_id}`,
        { action_by: Cookies.get("employee_no") }
      );
      setNotification({
        message: response.data.message,
        type: "success",
      });
      await getTableData();
    } catch (error) {
      console.error("Error fetching sample IDs:", error);
      setNotification({
        message: error?.response?.data?.error,
        type: "error",
      });
    }
  };

  const handleEnable = async () => {
    try {
      const response = await axios.put(
          `${backendUrl}/account/enableAccountAndChildren/${selectedRow.account_id}`, { action_by: Cookies.get("employee_no") }
      );
      setNotification({
        message: response.data.message,
        type: "success",
      });
      await getTableData();
    } catch (error) {
      console.error("Error fetching sample IDs:", error);
      setNotification({
        message: error?.response?.data?.error,
        type: "error",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeletedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle fetching table data
  const getTableData = async () => {
    try {
      const params = {
        page_size: rowsPerPage,
        page: pagination.current_page,
        ...seletedValue, // Include your filter parameters
      };

      const response = await axios.get(
        `${backendUrl}/account/getAllAccountsByFilters`,
        { params }
      );

      if (response.data) {
        setTableData(response.data.data); // Set the data array
        setPagination({
          current_page: response.data.pagination.current_page,
          page_size: response.data.pagination.page_size,
          total: response.data.pagination.total,
          total_pages: response.data.pagination.total_pages,
        });
      } else {
        console.error("Unexpected response format:", response.data);
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching sample details:", error);
      setTableData([]);
    }
  };

  const handleRowsPerPageChange = (newPageSize) => {
    setRowsPerPage(newPageSize);
    setPagination((prev) => ({
      ...prev,
      page_size: newPageSize,
      current_page: 1, // Reset to the first page
    }));
  };

  const PdfExport = () => {
    if(tableData.length > 0){
      const doc = new jsPDF();

      doc.text("Chart of Accounts", 14, 10);

      // AutoTable for tableData
      doc.autoTable({
        startY: 20,
        head: [
          [
            "Account Code",
            "Account Name",
            "Status",
            "Report Type",
            "Root Type",
            "Account Type ID",
          ],
        ],
        body: tableData.map((row) => [
          row.account_code,
          row.account_name,
          row.is_enabled === 1 ? "Enabled" : "Disabled", // Add status
          row.report_type,
          row.root_type,
          row.account_type_id,
        ]),
      });

      doc.save("chart_of_accounts.pdf");
    }

  };

  const ExcelExport = () => {
    if(tableData.length > 0){
      const csvData = Papa.unparse(
          tableData.map((row) => ({
            "Account Code": row.account_code,
            "Account Name": row.account_name,
            Status: row.is_enabled === 1 ? "Enabled" : "Disabled", // Add status
            "Report Type": row.report_type,
            "Root Type": row.root_type,
            "Account Type ID": row.account_type_id,
          }))
      );

      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "chart_of_accounts.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Handler for closing dropdown and deselecting row on outside click
  const handleClickOutside = (event) => {
    if (actionsRef.current && !actionsRef.current.contains(event.target)) {
      setSelectedRow(null);
      setDropdownVisible(false);
      setIsExportDropdownOpen(false);
    }
  };

  const actionsRef = useRef(null); // Ref for the "actions" element

  // Close dropdown and deselect row if clicking outside the table
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); //

  // Call the API whenever rowsPerPage or currentPage changes
  useEffect(() => {
    getTableData();
  }, [rowsPerPage, pagination.current_page, pagination.page_size, seletedValue]);

  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  return (
    <div className="mx-10 mt-5 text-xl">
      {/* Upload Modal */}
      {isUploadModalOpen && (
          <div
              className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
              style={{ backdropFilter: "blur(2px)" }} // Smooth background blur
          >
            <div
                className="bg-white p-6 rounded shadow-xl"
                style={{
                  zIndex: 60, // Ensure modal content is above backdrop
                }}
            >
              <h3 className="text-lg font-semibold mb-4">Bulk Upload Accounts</h3>
              <input
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                  className="mb-4 border border-gray-300 rounded px-2 py-1"
              />
              <div className="flex justify-end gap-4">
                <button
                    onClick={toggleUploadModal}
                    className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400"
                >
                  Cancel
                </button>
                <button
                    onClick={handleUploadSubmit}
                    className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
      )}

      <div className="overflow-visible">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold text-black">
              Chart of Accounts
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col md:flex-row md:items-center md:gap-3">
              {/* Search Box for Account Name */}
              <div className="w-full mt-4 md:w-[120px]">
                <input
                    type="text"
                    placeholder="Account Name"
                    name="account_name"
                    value={seletedValue.account_name}
                    onChange={handleChange}
                    className="w-full p-1.5 text-sm border border-black rounded focus:ring-2 focus:ring-black"
                />
              </div>

              {/* Search Box for Account Code */}
              <div className="w-full mt-4 md:w-[120px]">
                <input
                    type="text"
                    placeholder="Account Code"
                    name="account_code"
                    value={seletedValue.account_code}
                    onChange={handleChange}
                    className="w-full p-1.5 text-sm border border-black rounded focus:ring-2 focus:ring-black"
                />
              </div>

              {/* Root Type */}
              <div className="w-full md:w-[160px] relative">
                <label
                    htmlFor="root_type"
                    className="block mb-1 text-xs font-medium text-black"
                >
                  Root Type
                </label>
                <select
                    id="root_type"
                    name="root_type"
                    className="w-full p-1.5 text-sm border border-black rounded focus:ring-2 focus:ring-black"
                    value={seletedValue.root_type}
                    onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="Asset">Asset</option>
                  <option value="Liability">Liability</option>
                  <option value="Equity">Equity</option>
                  <option value="Revenue">Revenue</option>
                  <option value="Expense">Expense</option>
                </select>
              </div>

              {/* Report Type */}
              <div className="w-full md:w-[160px] relative">
                <label
                    htmlFor="report_type"
                    className="block mb-1 text-xs font-medium text-black"
                >
                  Report Type
                </label>
                <select
                    name="report_type"
                    className="w-full p-1.5 text-sm border border-black rounded focus:ring-2 focus:ring-black overflow-y-auto max-h-32 scrollbar-thin scrollbar-thumb-black scrollbar-track-gray-100"
                    value={seletedValue.report_type}
                    onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="Balance Sheet">Balance Sheet</option>
                  <option value="Profit & Loss">Profit & Loss</option>
                </select>
              </div>

              {/* Account Type */}
              <div className="w-full md:w-[160px]">
                <label
                    htmlFor="account_type"
                    className="block mb-1 text-xs font-medium text-black"
                >
                  Account Type
                </label>
                <select
                    name="account_type"
                    className="w-full p-1.5 text-sm border border-black rounded focus:ring-2 focus:ring-black"
                    value={seletedValue.account_type}
                    onChange={handleChange}
                >
                  <option value=""></option>
                  {accountTypes.map((type) => (
                      <option key={type.id} value={type.value}>
                        {type.value}
                      </option>
                  ))}
                </select>
              </div>
            </div>


            {/* Right-Side Button or Dropdown */}
            <div
                className="relative flex items-center gap-4 md:mt-0"
                id="actions"
                ref={actionsRef}
            >
              {/* Bulk Upload Button */}
              <button
                  className="flex items-center gap-2 px-4 py-2 text-base text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                  onClick={toggleUploadModal}
              >
                <IoAdd className="text-xl" />
                Bulk Upload
              </button>

              <button
                  className="flex items-center gap-2 px-4 py-2 text-base text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                  onClick={handleTreeViewNavigation}
              >
                <IoFilter className="text-xl" />
                Tree View
              </button>

              {/* Export Dropdown */}
              <div className="relative">
                <button
                    className="flex items-center gap-2 px-4 py-2 text-base text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                    onClick={() => setIsExportDropdownOpen(!isExportDropdownOpen)}
                >
                  <IoAdd className="text-xl" />
                  Export
                </button>

                {isExportDropdownOpen && (
                    <div className="absolute right-0 mt-2 bg-white border border-gray-300 rounded shadow-lg w-36">
                      <button
                          className="w-full px-4 py-2 text-left text-black hover:bg-gray-100"
                          onClick={PdfExport}
                      >
                        PDF Export
                      </button>
                      <button
                          className="w-full px-4 py-2 text-left text-black hover:bg-gray-100"
                          onClick={ExcelExport}
                      >
                        Excel Export
                      </button>
                    </div>
                )}
              </div>

              {selectedRow ? (
                  // Action Dropdown
                  <div className="relative">
                    <button
                        onClick={() => setDropdownVisible(!dropdownVisible)}
                        className="px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                    >
                      Actions
                    </button>
                    {dropdownVisible && (
                        <div className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg">
                          {hasPermission(3073) && (
                              <button
                                  onClick={handleEdit}
                                  className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                              >
                                <FaEdit className="mr-2"/> Edit
                              </button>
                          )}

                          {hasPermission(3074) && (
                              <button
                                  onClick={handleDisable}
                                  className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                              >
                                <MdDisabledByDefault className="mr-2"/> Disable
                              </button>
                          )}

                          {hasPermission(3074) && (
                              <button
                                  onClick={handleEnable}
                                  className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                              >
                                <VscActivateBreakpoints className="mr-2"/> Enable
                              </button>
                          )}
                        </div>
                    )}
                  </div>
              ) : (
                  hasPermission(3072) && ( // Correctly remove curly braces from JSX block
                      <button
                          className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                          onClick={togglePopup}
                      >
                        <IoAdd className="text-xl"/>
                        New
                      </button>
                  )
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Notification Alert */}
      <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
      />

      <div className="mt-6">
        {/* Scrollable Table Container with Increased Height */}
        <div>
          {/* TailwindCSS Table */}
          <table className="min-w-full bg-white border border-gray-400">
            <thead className="bg-gray-200 text-md">
            <tr>
              <th className="px-4 py-2 font-semibold text-left text-gray-700">
                Account Code
              </th>
              <th className="px-4 py-2 font-semibold text-left text-gray-700">
                Account Name
              </th>
              <th className="px-4 py-2 font-semibold text-left text-gray-700">
                Account Type
              </th>
              <th className="px-4 py-2 font-semibold text-left text-gray-700">
                Status
              </th>
              <th className="px-4 py-2 font-semibold text-left text-gray-700">
                Report Type
              </th>
              {/* <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Amount
                </th> */}
              <th className="px-4 py-2 font-semibold text-left text-gray-700">
                Root Type
              </th>
              {/*<th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">*/}
              {/*  Balance*/}
              {/*</th>*/}
            </tr>
            </thead>
            <tbody>
            {tableData.map((row, index) => (
                <tr
                    key={index}
                    className={`${
                        selectedRow === row ? "bg-gray-200 text-black" : "hover:bg-gray-50"
                    }`}
                    onClick={() => handleRowSelect(row)}
                >
                  <td className="px-4 py-2 border-t">
                    {row.account_code}
                  </td>

                  <td className="px-4 py-2 border-t">
                    <Link
                        to={`/finance-management/chart-of-accounts/account-ledger/${String(
                            row.account_id
                        )}`}
                        className="text-blue-500 hover:underline"
                    >
                      {row.account_name}
                    </Link>
                  </td>
                  <td className="px-4 py-2 border-t">
                    {row.account_type}
                  </td>
                  <td className="px-4 py-2 border-t">
                    {row.is_enabled === 1 ? (
                      <span className="text-green-500">Enable</span>
                    ) : (
                      <span className="text-red-500">Disable</span>
                    )}
                  </td>
                  <td className="px-4 py-2 border-t">
                    {row.report_type}
                  </td>
                  {/* <td className="px-6 py-4 border-b border-black">
                    <Link
                      to={`/finance-management/chart-of-accounts/account-ledger/${String(
                        row.account_id
                      )}`}
                      className="text-blue-500 hover:underline"
                    >
                      {row.amount}
                    </Link>
                  </td> */}
                  <td className="px-4 py-2 border-t">
                    {row.root_type}
                  </td>
                  {/* <td className="px-6 py-4 border-b border-black">
                    {row.account_type}
                  </td> */}
                  {/* <td className="px-6 py-4 border-b border-black">
                    <Link
                     to={`/finance-management/chart-of-accounts/account-ledger/${row.account_code}`}
                     className="text-blue-500 hover:underline"
                    >
                     {2000.00}
                    </Link>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between mt-5">
        <div>
          <label className="block mb-2 text-sm font-medium text-black">
            Rows per page:
          </label>
          <select
              value={rowsPerPage}
              onChange={(e) => handleRowsPerPageChange(parseInt(e.target.value))}
              className="p-2 border border-black rounded focus:ring-2 focus:ring-black"
          >
            <option value={20}>20</option>
            <option value={100}>100</option>
            <option value={500}>500</option>
            <option value={2500}>2500</option>
          </select>
        </div>
        <div>
    <span>
      Page {pagination.current_page} of {pagination.total_pages === 0 ? (pagination.total_pages + 1) : pagination.total_pages }
    </span>
          <button
              onClick={() =>
                  setPagination((prev) => ({
                    ...prev,
                    current_page: Math.max(prev.current_page - 1, 1),
                  }))
              }
              disabled={pagination.current_page === 1}
              className="px-4 py-2 ml-2 bg-black text-white rounded hover:bg-gray-800"
          >
            Previous
          </button>
          <button
              onClick={() =>
                  setPagination((prev) => ({
                    ...prev,
                    current_page: Math.min(prev.current_page + 1, prev.total_pages),
                  }))
              }
              disabled={pagination.current_page === pagination.total_pages}
              className="px-4 py-2 ml-2 bg-black text-white rounded hover:bg-gray-800"
          >
            Next
          </button>
        </div>
      </div>


      {/* Add Chart Account Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <Add_chat_account_popup togglePopup={togglePopup} />
          </div>
        </div>
      )}

      {/* Edit Chart Account Popup */}
      {isOpenEdit && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <Edit_chat_account_popup
              editePopup={editePopup}
              row={selectedRow}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Chart_account;
