/** @format */
import React, { useState, useEffect, useRef } from "react";
import QRCode from "react-qr-code";
import JsBarcode from "jsbarcode";
import { useReactToPrint } from "react-to-print";

function ViewMore({ batchItems, onClose }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPrint, setShowPrint] = useState(false);
  const [printType, setPrintType] = useState(""); // Track barcode/QR
  const [selectedBatchID, setSelectedBatchID] = useState(null);
  const printRef = useRef();
  const [printQty, setprintQty] = useState("");

  // Generate barcode dynamically
  const generateBarcode = () => {
    const barcode = document.getElementById("barcode");
    if (barcode && selectedBatchID) {
      JsBarcode(barcode, selectedBatchID, {
        format: "CODE128",
        width: 2,
        height: 50,
      });
    }
  };

  // Handle print functionality
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Batch Label",
    pageStyle: `@page { size: 3in 3in; margin: 0.2in; }`, // 3x3 inches with padding
  });

  useEffect(() => {
    if (batchItems) {
      setLoading(false);
    } else {
      setError(new Error("No batch data found."));
      setLoading(false);
    }
  }, [batchItems]);

  useEffect(() => {
    if (printType === "barcode") generateBarcode();
  }, [printType, selectedBatchID]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!batchItems || batchItems.length === 0)
    return <p>No Batch items found.</p>;

  return (
    <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full ">
      <div className="bg-white p-5 w-[80%] h-[70%] rounded-xl relative overflow-auto">
        <div className="mb-6">
          <p className="font-sans text-[32px] font-bold text-center">
            Batch Items
          </p>
          <div
            className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl overflow-y-auto"
            style={{ maxHeight: "50vh" }}
          >
            <table className="table-auto border-none w-[95%] ">
              <thead>
                <tr className="bg-white">
                  <th className="text-primary px-4 py-2">Item Batch ID</th>
                  <th className="text-primary px-4 py-2">Item Name</th>
                  <th className="text-primary px-4 py-2">Current Qty</th>
                  <th className="text-primary px-4 py-2">Manufacture Date</th>
                  <th className="text-primary px-4 py-2">Expiration Date</th>
                  <th className="text-primary px-4 py-2">Store</th>
                  <th className="text-primary px-4 py-2">Location</th>
                  <th className="text-primary px-4 py-2">Barcode/QR</th>
                </tr>
              </thead>
              <tbody>
                {batchItems.batches.map((item, index) => (
                  <tr key={index}>
                    <td className="bg-white px-4 py-2 text-center">
                      {item.inventory_batch_id}
                    </td>
                    <td className="bg-white px-4 py-2 text-center">
                      {item.inventory_item_description}
                    </td>
                    <td className="bg-white px-4 py-2 text-center">
                      {item.current_qty}
                    </td>
                    <td className="bg-white px-4 py-2 text-center">
                      {item.manufacture_date}
                    </td>
                    <td className="bg-white px-4 py-2 text-center">
                      {item.expiration_date}
                    </td>
                    <td className="bg-white px-4 py-2 text-center">
                      {item.store_id}
                    </td>
                    <td className="bg-white px-4 py-2 text-center">
                      {item.location}
                    </td>
                    <td className="bg-white px-4 py-2 text-center">
                      <button
                        className="text-primary hover:underline mr-2"
                        onClick={() => {
                          setSelectedBatchID(item.inventory_batch_id);
                          setPrintType("barcode");
                          setShowPrint(true);
                        }}
                      >
                        Print Barcode
                      </button>
                      <button
                        className="text-primary hover:underline"
                        onClick={() => {
                          setSelectedBatchID(item.inventory_batch_id);
                          setPrintType("qr");
                          setShowPrint(true);
                        }}
                      >
                        Print QR
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 border-none rounded-[22px] bg-clsDialog w-[30.359px] h-[28px]"
            onClick={onClose}
          >
            <span className="text-white font-bold text-xl">X</span>
          </button>
        </div>
      </div>

      {showPrint && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-[20px]" ref={printRef}>
            <p className="mb-4 font-semibold text-[18px]"> Barcode Preview</p>
            <div className="flex justify-center">
              {printType === "qr" ? (
                <QRCode value={selectedBatchID || ""} size={128} />
              ) : (
                <svg id="barcode"></svg>
              )}
            </div>
            <div className="flex items-center justify-between w-[400px] mt-4">
              <p className="label-2">Print Quantity</p>
              <p className="label-2">:</p>
              <input
                type="number"
                className="w-[200px] h-[50px] rounded-[46px] border border-[#00000036] text-center"
                value={printQty}
                onChange={(e) => setprintQty(e.target.value)}
              />
            </div>

            <div className="flex justify-center mt-10">
              <button
                className="bg-gray-200 px-4 py-2 rounded-md mr-2 font-semibold"
                onClick={() => setShowPrint(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#ab2626] text-white px-4 py-2 font-semibold rounded-md"
                onClick={handlePrint}
              >
                Print
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewMore;
