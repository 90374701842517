/** @format */

import React, { useState, useEffect, useCallback, useMemo } from "react";

const Inventory_Permssion = ({ onPermissionsChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const permissionHierarchy = useMemo(
    () => ({
      2: { children: [110, 111, 112, 113, 114, 115, 116, 117] },
      110: { children: [1200, 1210] },
      111: { children: [1220, 1230, 1240] },

      114: { children: [1270, 1271, 1272] },
      117: { children: [1280, 1281] },

      1200: { children: [1490] },
      1220: { children: [1495] },
      1230: { children: [1500, 1501] },
      1240: { children: [1510, 1610] },
      1270: { children: [1620] },
      // Add the rest of your permissions here
    }),
    []
  );

  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      // Automatically check parent checkboxes if child checkbox is checked
      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (permissionHierarchy[key]?.children.includes(value)) {
            newValues[key] = true;
          }
        });
      }

      // Automatically check child checkboxes if parent checkbox is checked
      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }

      return newValues;
    });
  };

  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    // Ensure HRIS permission ID (2) is included if any HRIS permission is selected
    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[2].children.includes(perm)
      ) &&
      !selectedPermissions.includes(2)
    ) {
      selectedPermissions.push(2);
    }

    onPermissionsChange(selectedPermissions);
  }, [checkedValues, onPermissionsChange, permissionHierarchy]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues, updatePermissions]);

  return (
    <div>
      <div>
        <div>
          <p
            className="font-bold text-[20px] ml-[20%] mt-4 underline font-sans"
            value={2}
          >
            Inventory
          </p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Inventory Purchase
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1200"
                      checked={!!checkedValues[1200]}
                      onChange={() => handleCheckboxChange(1200)}
                    />
                    Inventory Purchases GRN
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1490"
                          checked={!!checkedValues[1490]}
                          onChange={() => handleCheckboxChange(1490, 1200)}
                        />
                        Inventory Purchase (GRN) - view
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1210"
                      checked={!!checkedValues[1210]}
                      onChange={() => handleCheckboxChange(1210)}
                    />
                    Inventory Purchase History Log
                  </label>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Inventory Requests
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1220"
                      checked={!!checkedValues[1220]}
                      onChange={() => handleCheckboxChange(1220)}
                    />
                    Production Item Requests
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1495"
                          checked={!!checkedValues[1495]}
                          onChange={() => handleCheckboxChange(1495, 1220)}
                        />
                        Released production Item Request
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1230"
                      checked={!!checkedValues[1230]}
                      onChange={() => handleCheckboxChange(1230)}
                    />
                    Branch Item Requests
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1500"
                          checked={!!checkedValues[1500]}
                          onChange={() => handleCheckboxChange(1500, 1230)}
                        />
                        History Log of Released Branch Item Requests
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1501"
                          checked={!!checkedValues[1501]}
                          onChange={() => handleCheckboxChange(1501, 1230)}
                        />
                        Released Branch Item Requests
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1240"
                      checked={!!checkedValues[1240]}
                      onChange={() => handleCheckboxChange(1240)}
                    />
                    Mobile POS Item Requests
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1510"
                          checked={!!checkedValues[1510]}
                          onChange={() => handleCheckboxChange(1510)}
                        />
                        Released Mobile POS Item Requests
                      </label>
                    </div>
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1610"
                          checked={!!checkedValues[1610]}
                          onChange={() => handleCheckboxChange(1610)}
                        />
                        History Log of Released Mobile POS Item Requests
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Inventory Dispose
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="112"
                    checked={!!checkedValues[112]}
                    onChange={() => handleCheckboxChange(112)}
                  />
                  Inventory Dispose
                </label>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Disposed Items
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="113"
                    checked={!!checkedValues[113]}
                    onChange={() => handleCheckboxChange(113)}
                  />
                  Disposed Items
                </label>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Inventory Return
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1270"
                      checked={!!checkedValues[1270]}
                      onChange={() => handleCheckboxChange(1270)}
                    />
                    Branch Item Return Request
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="1620"
                          checked={!!checkedValues[1620]}
                          onChange={() => handleCheckboxChange(1620, 1270)}
                        />
                        Released Branch Item Return Requests
                      </label>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1271"
                      checked={!!checkedValues[1271]}
                      onChange={() => handleCheckboxChange(1271)}
                    />
                    Add Mobile POS Item Return
                  </label>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1272"
                      checked={!!checkedValues[1272]}
                      onChange={() => handleCheckboxChange(1272)}
                    />
                    Mobile POS Item Return Request Lists
                  </label>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Inventory Item Adjustment
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="115"
                    checked={!!checkedValues[115]}
                    onChange={() => handleCheckboxChange(115)}
                  />
                  Items Adjustment
                </label>
              </div>
              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Inventory Adjusted Items
                </p>
                <label className="block mb-2 text-blue-500">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value="116"
                    checked={!!checkedValues[116]}
                    onChange={() => handleCheckboxChange(116)}
                  />
                  Adjusted Items
                </label>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  DashBoard
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1280"
                      checked={!!checkedValues[1280]}
                      onChange={() => handleCheckboxChange(1280)}
                    />
                    Inventory Items
                  </label>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="1281"
                      checked={!!checkedValues[1281]}
                      onChange={() => handleCheckboxChange(1281)}
                    />
                    Inventory Expired Items
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Inventory_Permssion;
