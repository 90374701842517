import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import FileUpload from "./upload_files";
import Cookies from "js-cookie";
import Approval1 from "../../hris/approval_process/approval1/approval1";

function AddBudget() {
  const [budgetData, setBudgetData] = useState([]); // Initialize with an empty array
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [approvalStatus, setApprovalStatus] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [actionsVisible, setActionsVisible] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [selectedRows, setSelectedRows] = useState([]); 
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_pages: 0,
    total_records: 0,
    rows_per_page: 10,
  });

  const [debouncedFilters, setDebouncedFilters] = useState({
    approvalStatus: "",
  });
  const userId = Cookies.get("employee_no");
  const actionsRef = useRef(null);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedFilters({
        approvalStatus,
      });
    }, 300); // Debounce delay

    return () => clearTimeout(handler);
  }, [approvalStatus]);

  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;

  const fetchBudgetData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${backendUrl}/budget/getAllBudget`, {
        params: {
          page: pagination.current_page,
          page_size: pagination.rows_per_page,
          approval_status: debouncedFilters.approvalStatus, // Add the approval status filter
        },
      });

      console.log("Response Data:", response.data);

      if (response && response.data) {
        const { data, total_pages, total_records } = response.data;

        console.log("Data Content:", data);

        if (Array.isArray(data)) {
          setBudgetData(data);
          setPagination((prev) => ({
            ...prev,
            total_pages, // Set the total pages from response
            total_records, // Set the total records from response
          }));
        } else {
          console.error("Expected an array but got:", data);
          setBudgetData([]); // Set to empty array if not in expected format
        }
      } else {
        console.error("No data available in the response:", response);
        setBudgetData([]);
      }
    } catch (error) {
      console.error("Error fetching budget data:", error);
      setBudgetData([]); // Fallback to empty array if error occurs
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBudgetData(); // Fetch data when the component is mounted
  }, [
    backendUrl,
    pagination.current_page,
    pagination.rows_per_page,
    debouncedFilters,
  ]); // Re-run the fetch when pagination state changes

  const handlePageChange = async (page) => {
    if (page > 0 && page <= pagination.total_pages) {
      setPagination((prev) => ({ ...prev, current_page: page })); // Update the current page state
    }
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1, // Reset to first page whenever rows per page changes
      rows_per_page: newRowsPerPage, // Update rows_per_page value
    }));
  };

  const handleApprovalStatusChange = (e) => {
    setApprovalStatus(e.target.value);
    setPagination((prev) => ({ ...prev, current_page: 1 }));
  };

  // Handle master checkbox (select/deselect all rows)
// Handle master checkbox (select/deselect all rows)
const handleSelectAll = () => {
  if (selectedRows.length === budgetData.length) {
    setSelectedRows([]); // Deselect all
  } else {
    setSelectedRows(budgetData.map((row) => row.id)); // Select all rows using 'id'
  }
};

// Handle checkbox selection for individual rows
const handleRowSelect = (id) => {
  if (selectedRows.includes(id)) {
    setSelectedRows(selectedRows.filter((rowId) => rowId !== id)); // Deselect this row
  } else {
    setSelectedRows([...selectedRows, id]); // Select this row
  }
};

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };


  const updateBudgetStatus = async (status) => {
    try {
      if (selectedRows.length === 0) {
        setNotification({
          message: "Please select at least one budget to approve.",
          type: "error",
        });
        return;
      }
  
      const payload = {
        approval_status: status,  // Correct approval status (can be dynamic)
        created_by: userId,
        rows: selectedRows,  // Array of selected row IDs
      };
  
      // Log the payload to ensure it contains serializable data
      console.log("Payload being sent:", payload);
  
      setLoading(true);  // Set loading state to true
  
      const response = await axios.post(
        `${backendUrl}/budget/checkOrApproveBudget`,
        payload,
       
      );
  
      if (response.data.success) {
        console.log("Budget status updated successfully:", response.data);
  
        setBudgetData((prevData) =>
          prevData.map((row) =>
            selectedRows.includes(row.id) ? { ...row, approval_status: status } : row
          )
        );
        setNotification({
          message: `Budget status updated to "${status}".`,
          type: "success",
        });
        setSelectedRows([]);  // Reset selected rows after update
      } else {
        setNotification({
          message: response.data.message || "Failed to update budget status.",
          type: "error",
        });
      }
  
    } catch (error) {
      console.error("Error updating budget status:", error);
  
      if (error.response) {
        console.error("Server Response:", error.response.data);
      }
  
      setNotification({
        message: "An error occurred while updating the budget status.",
        type: "error",
      });
      
    } finally {
      setLoading(false);  // Always set loading to false after the request
    }
    
  };
  
  
  

  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="max-w-8xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4">Budget Variation</h2>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-4 w-2/3 mx-auto">
            <button
              className="px-4 py-2 bg-black text-white rounded-md"
              onClick={handleOpenModal}
              type="file"
            >
              Upload Budget File
            </button>
          </div>
        </div>
      </div>

      <h3 className="text-2xl font-semibold mb-4 mt-6">
        Budget Variation Report
      </h3>

      <div className="mt-5">
        <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
          <div className="flex flex-col md:flex-row md:items-center md:gap-4">
            {" "}
            <div className="w-full md:w-[200px]">
              <label htmlFor="approvalStatus" className="block font-bold mb-2">
                Approval Status
              </label>
              <select
                id="approvalStatus"
                className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                value={approvalStatus}
                onChange={handleApprovalStatusChange}
              >
                <option value="">-- Select Approval Status --</option>
                <option value="Approved">Approved</option>
                <option value="Draft">Draft</option>

                <option value="Rejected">Rejected</option>
              </select>
            </div>
          </div>
          <div ref={actionsRef} className="relative">
          <button
  className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800 focus:outline-none"
  onClick={() => updateBudgetStatus("Approved")}
  disabled={selectedRows.length === 0 || loading}  // Disable if no rows are selected
>
  Approve
</button>

            
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-10">
        <table className="w-full bg-white border border-black rounded-lg">
          <thead className="bg-gray-300">
            <tr>
            <th className="py-2 px-4 text-center">
            <input
              type="checkbox"
              onChange={handleSelectAll}
              checked={selectedRows.length === budgetData.length}
            />
                Select
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[250px]">
                Budget ID
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[250px]">
                Account Code
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[250px]">
                Account Name
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                Budget Year
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[150px]">
                January
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                February
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                March
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                April
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                May
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                June
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                July
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[250px]">
                August
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                September
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                October
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                November
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                December
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                Total Estimation
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                Approval Status
              </th>
            </tr>
          </thead>
          <tbody>
          {loading ? (
              <tr>
                <td colSpan="5" className="text-center py-5">Loading...</td>
              </tr>
            ) : budgetData.length === 0 ? (
              <tr>
                <td colSpan="5" className="text-center py-5">No data available</td>
              </tr>
            ) : (
              budgetData.map((budget) => (
                <tr key={budget.id}>
                  <td className="py-2 px-4 text-center">
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(budget.id)}
                    onChange={() => handleRowSelect(budget.id)}
                  />
              </td>
                  <td className="py-5 px-3 text-left">{budget.budget_id}</td>
                  <td className="py-5 px-3 text-left">{budget.account_code}</td>
                  <td className="py-5 px-3 text-left">{budget.account_name}</td>
                  <td className="py-5 px-3 text-left">{budget.budget_year}</td>
                  <td className="py-5 px-3 text-right">
                    {budget.January || 0} LKR
                  </td>
                  <td className="py-5 px-3 text-right">
                    {budget.February || 0} LKR
                  </td>
                  <td className="py-5 px-3 text-right">
                    {budget.March || 0} LKR
                  </td>
                  <td className="py-5 px-3 text-right">
                    {budget.April || 0} LKR
                  </td>
                  <td className="py-5 px-3 text-right">
                    {budget.May || 0} LKR
                  </td>
                  <td className="py-5 px-3 text-right">
                    {budget.June || 0} LKR
                  </td>
                  <td className="py-5 px-3 text-right">
                    {budget.July || 0} LKR
                  </td>
                  <td className="py-5 px-3 text-right">
                    {budget.August || 0} LKR
                  </td>
                  <td className="py-5 px-3 text-right">
                    {budget.September || 0} LKR
                  </td>
                  <td className="py-5 px-3 text-right">
                    {budget.October || 0} LKR
                  </td>
                  <td className="py-5 px-3 text-right">
                    {budget.November || 0} LKR
                  </td>
                  <td className="py-5 px-3 text-right">
                    {budget.December || 0} LKR
                  </td>
                  <td className="py-5 px-3 text-right">
                    {budget.totalEstimation || 0} LKR
                  </td>
                  <td className="py-5 px-3 text-left">
                    {budget.approval_status}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between items-center mt-6 mb-6">
        <div>
          <label htmlFor="rowsPerPage" className="mr-2 text-2xl">
            Rows per page:
          </label>
          <select
            id="rowsPerPage"
            value={rowsPerPage}
            onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
            className="p-1 border border-gray-400 rounded"
          >
         
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div className="flex items-center gap-2">
          {Array.from({ length: pagination.total_pages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)} // Pass the correct page number
              className={`px-3 py-1 rounded ${
                pagination.current_page === index + 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 hover:bg-gray-300"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
          <div className="p-8 bg-white rounded-lg">
            <FileUpload
              setUploadedFiles={setUploadedFiles}
              uploadedFiles={uploadedFiles}
              onClose={handleCloseModal}
            />

            <button
              className="p-3 py-2 mt-4 text-white bg-red-500 rounded-lg"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddBudget;
