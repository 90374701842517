import React, { useState, useEffect } from "react";

const AccountsTable = ({ data, level = 0 }) => {
  const [expandedRows, setExpandedRows] = useState({});

  // Recursively set expanded state for all rows, including children
  const expandAllRows = (records) => {
    const expanded = {};
    const recursiveExpand = (recordList) => {
      recordList.forEach((record) => {
        expanded[record.account_id] = true;
        if (record.children && record.children.length > 0) {
          recursiveExpand(record.children); // Expand children recursively
        }
      });
    };

    recursiveExpand(records);
    return expanded;
  };

  useEffect(() => {
    const expanded = expandAllRows(data); // Expand all rows on initial render
    setExpandedRows(expanded);
  }, [data]);

  const toggleRowExpansion = (accountId) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [accountId]: !prevState[accountId],
    }));
  };

  return (
    <div className="flex justify-center items-center ">
      <div className="w-full max-w-[1200px] ">
        <table className="w-[98%] border border-gray-200 text-left mx-auto">
          {level === 0 && (
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 border-b text-center text-2xl">Chart of Accounts</th>
              </tr>
            </thead>
          )}
          <tbody>
            {data.map((record) => (
              <React.Fragment key={record.account_id}>
                <tr className="border-b">
                  <td
                    className="px-4 py-2"
                    style={{
                      paddingLeft: `${level * 15}px`, // Adjust child row indentation
                    }}
                  >
                    {record.children && record.children.length > 0 && (
                      <button
                        onClick={() => toggleRowExpansion(record.account_id)}
                        className="mr-2 focus:outline-none"
                      >
                        {expandedRows[record.account_id] ? "▼" : "▶"}
                      </button>
                    )}
                    {record.account_code} - {record.account_name}
                  </td>
                </tr>
                {expandedRows[record.account_id] && record.children && (
                  <tr>
                    <td colSpan="2" className="p-0">
                      {/* Recursive rendering of child accounts */}
                      <AccountsTable data={record.children} level={level + 1} />
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AccountsTable;
