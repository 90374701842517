import React, { useState, useEffect } from 'react';

const AccountsTable = ({ data, totals }) => {
    const [expandedRows, setExpandedRows] = useState({});

    // Helper function to recursively expand all rows
    const expandAllRows = (records) => {
        const expanded = {};
        const expandRecursive = (record) => {
            expanded[record.account_id] = true;
            if (record.children && record.children.length > 0) {
                record.children.forEach(expandRecursive);
            }
        };
        records.forEach(expandRecursive);
        return expanded;
    };

    // Initialize expandedRows when the data changes
    useEffect(() => {
        if (data.length > 0) {
            setExpandedRows(expandAllRows(data)); // Ensure rows are expanded after data is fetched
        }
    }, [data]);  // Dependency on data ensures this runs when the data is updated

    const toggleRowExpansion = (accountId) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [accountId]: !prevState[accountId],
        }));
    };

    const calculateDifference = (record) => {
        const difference = record.total_credit - record.total_debit;
        return record.root_type === 'Expense' ? -difference : difference;
    };

    const renderTotalsRow = (label, value) => (
        <tr className="border-t font-bold bg-gray-100">
            <td className="px-4 py-2">{label}</td>
            <td className="px-4 py-2 text-right">{parseFloat(value).toLocaleString()} LKR</td>
        </tr>
    );

    const renderRows = (records, level = 0) =>
        records.map((record) => (
            <React.Fragment key={record.account_id}>
                <tr className="border-b">
                    <td className="px-4 py-2" style={{ paddingLeft: `${level * 20}px` }}>
                        {record.children && record.children.length > 0 && (
                            <button
                                onClick={() => toggleRowExpansion(record.account_id)}
                                className="mr-2 focus:outline-none"
                            >
                                {expandedRows[record.account_id] ? '▼' : '▶'}
                            </button>
                        )}
                        {record.account_code} - {record.account_name}
                    </td>
                    <td className="px-4 py-2 text-right">
                        {calculateDifference(record).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}{' '}
                        LKR
                    </td>
                </tr>
                {expandedRows[record.account_id] &&
                    level < 1 && // Expand only up to the second hierarchy level
                    record.children &&
                    renderRows(record.children, level + 1)}
            </React.Fragment>
        ));

    return (
        <table className="w-full border border-gray-200 text-left">
            <thead>
            <tr className="bg-gray-100">
                <th className="px-4 py-2 border-b">Account</th>
                <th className="px-4 py-2 border-b text-right">Amount</th>
            </tr>
            </thead>
            <tbody>
            {data.map((category, index) => (
                <React.Fragment key={index}>
                    {renderRows(category)}
                    {renderTotalsRow(
                        `Net cash flow from ${['Operating', 'Investing', 'Financing', 'Cash Equivalent'][index]}`,
                        totals[['operating', 'investing', 'financing', 'cashEquivalent'][index]]
                    )}
                </React.Fragment>
            ))}
            </tbody>
        </table>
    );
};

export default AccountsTable;
