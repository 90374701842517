import React, { useEffect, useRef, useState } from "react";
import { IoAdd } from "react-icons/io5";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import { Link, useParams } from "react-router-dom";

const AccountLedger = () => {
  const { account_code } = useParams();

  console.log(account_code);

  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const [seletedValue, setSeletedValue] = useState({
    amount: "",
    account_code: "",
    start_date: "",
    end_date: "",
  });

  const [accountName, setAccountName] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default rows per page
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [pagination, setPagination] = useState({
    current_page: 1,
    page_size: 20,
    total: 0,
    total_pages: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeletedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle fetching table data
  const getTableData = async () => {
    console.log(account_code);

    try {
      const params = {
        page_size: rowsPerPage,
        page: pagination.current_page,
        ...seletedValue, // Include your filter parameters
      };

      const response = await axios.get(
        `${backendUrl}/account/getAccountWiseLedger/${account_code}`,
        { params }
      );

      // Ensure you set both data and pagination state
      if (response.data) {
        setTableData(response.data.data); // Set the data array
        setPagination(response.data.pagination); // Set the pagination info
        setAccountName(response.data.data[0].GLAccountname);
      } else {
        console.error("Unexpected response format:", response.data);
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching sample details:", error);
      setTableData([]);
    }
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1, // Reset to first page whenever rows per page changes
    }));
  };

  const PdfExport = () => {
    const doc = new jsPDF();
    doc.text("Chart of Accounts", 14, 10);

    // AutoTable for tableData
    doc.autoTable({
      startY: 20,
      head: [
        [
          "Account Code",
          "Account Name",
          "Status",
          "Report Type",
          "Root Type",
          "Account Type ID",
        ],
      ],
      body: tableData.map((row) => [
        row.account_code,
        row.account_name,
        row.is_enabled === 1 ? "Enabled" : "Disabled", // Add status
        row.report_type,
        row.root_type,
        row.account_type_id,
      ]),
    });

    doc.save("chart_of_accounts.pdf");
  };

  const ExcelExport = () => {
    const csvData = Papa.unparse(
      tableData.map((row) => ({
        "Posting Date": row.postingDate,
        "Document Date": row.documentDate,
        "GL Account No": row.GLAccountNo,
        "GL Account Name": row.GLAccountname,
        Description: row.description,
        Type: row.type,
        Amount: row.amount,
        "Bal Account No": row.balAmountNo,
        "Bal Account Name": row.balAmountName,
        "Payee Name": row.payeeName,
      }))
    );

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Account_Ledger.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Call the API whenever rowsPerPage or currentPage changes
  useEffect(() => {
    getTableData();
  }, [rowsPerPage, pagination.current_page, seletedValue]);

  return (
    <div className="mx-10 mt-5 text-xl">
      <div className="overflow-visible">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold text-black">
              Account Ledger / {accountName}
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col md:flex-row md:items-center md:gap-4">
              {/* Search Box for Account Name */}
              <div className="w-full mt-6 md:w-[150px]">
                <input
                  type="text"
                  placeholder="Enter amount"
                  name="amount"
                  value={seletedValue.amount}
                  onChange={handleChange}
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                />
              </div>

              {/* Start Date */}
              <div className="w-full md:w-[200px] relative">
                <label
                  htmlFor="Start_date"
                  className="block mb-2 text-sm font-medium text-black"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  placeholder="Enter Date"
                  name="start_date"
                  value={seletedValue.start_date}
                  onChange={handleChange}
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                />
              </div>

              {/* End Date */}
              <div className="w-full md:w-[200px] relative">
                <label
                  htmlFor="root_type"
                  className="block mb-2 text-sm font-medium text-black"
                >
                  End Date
                </label>
                <input
                  type="date"
                  placeholder="Enter Date"
                  name="end_date"
                  value={seletedValue.end_date}
                  onChange={handleChange}
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                />
              </div>
            </div>

            {/* Right-Side Button or Dropdown */}
            <div
              className="relative flex items-center gap-4 md:mt-0"
              id="actions"
            >
              {/* <button
                  className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                  onClick={PdfExport}
                >
                  <IoAdd className="text-xl" />
                  PDF Export
                </button>
               */}

              <button
                className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                onClick={ExcelExport}
              >
                <IoAdd className="text-xl" />
                Excel Export
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="overflow-x-auto mt-5">
  <table className="min-w-[1500px] bg-white border border-black rounded-lg">
    <thead className="bg-gray-300  top-0 z-10">
      <tr>
        <th className="py-1 px-2 text-center bg-gray-300 border-b min-w-[150px]">
          Pos ID
        </th>
        <th className="py-1 px-2 text-center bg-gray-300 border-b min-w-[200px]">
          Posting Date
        </th>
        <th className="py-1 px-2 text-center bg-gray-300 border-b min-w-[200px]">
          Document Date
        </th>
        <th className="py-1 px-2 text-center bg-gray-300 border-b min-w-[200px]">
          GL Account No
        </th>
        <th className="py-1 px-2 text-center bg-gray-300 border-b min-w-[250px]">
          GL Account Name
        </th>
        <th className="py-1 px-2 text-center bg-gray-300 border-b min-w-[300px]">
          Description
        </th>
        <th className="py-1 px-2 text-center bg-gray-300 border-b min-w-[150px]">
          Type
        </th>
        <th className="py-1 px-2 text-center bg-gray-300 border-b min-w-[150px]">
          Amount
        </th>
        <th className="py-1 px-2 text-center bg-gray-300 border-b min-w-[200px]">
          Bal Account No
        </th>
        <th className="py-1 px-2 text-center bg-gray-300 border-b min-w-[250px]">
          Bal Account Name
        </th>
        <th className="py-1 px-2 text-center bg-gray-300 border-b min-w-[250px]">
          External Doc No
        </th>
        <th className="py-1 px-2 text-center bg-gray-300 border-b min-w-[150px]">
          Journal Batch Name
        </th>
        <th className="py-1 px-2 text-center bg-gray-300 border-b min-w-[200px]">
          Payee Name
        </th>
      </tr>
    </thead>
    <tbody>
      {tableData.map((row, index) => (
        <tr
          key={index}
          className={`${
            selectedRow === row
              ? "bg-blue-600 text-white"
              : "hover:bg-gray-100"
          }`}
        >
          <td className="py-1 px-2 text-center border-b">{row.POSId}</td>
          <td className="py-1 px-2 text-center border-b">{row.postingDate}</td>
          <td className="py-1 px-2 text-center border-b">{row.documentDate}</td>
          <td className="py-1 px-2 text-center border-b">{row.GLAccountNo}</td>
          <td className="py-1 px-2 text-center border-b">
            {row.GLAccountname}
          </td>
          <td className="py-1 px-2 text-center border-b">{row.description}</td>
          <td className="py-1 px-2 text-center border-b">{row.type}</td>
          <td className="py-1 px-2 text-center border-b">{row.amount}</td>
          <td className="py-1 px-2 text-center border-b">{row.balAmountNo}</td>
          <td className="py-1 px-2 text-center border-b">
            {row.balAmountName}
          </td>
          <td className="py-1 px-2 text-center border-b">
            {row.externalDocNo}
          </td>
          <td className="py-1 px-2 text-center border-b">
            {row.journalBatchName}
          </td>
          <td className="py-1 px-2 text-center border-b">{row.payeeName}</td>
        </tr>
      ))}
      {tableData.length === 0 && (
        <tr>
          <td colSpan={13} className="py-1 px-2 text-center">
            No data available
          </td>
        </tr>
      )}
    </tbody>
  </table>
</div>

{/* Pagination Controls */}
<div className="flex justify-center items-center mt-8 mb-8 gap-4">
  {/* Rows per page dropdown */}
  <div>
    <label htmlFor="rowsPerPage" className="mr-2 text-md font-medium">
      Rows per page:
    </label>
    <select
      id="rowsPerPage"
      value={rowsPerPage}
      onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
      className="p-1 border border-gray-400 rounded"
    >
      <option value={20}>20</option>
      <option value={50}>50</option>
      <option value={100}>100</option>
    </select>
  </div>

  {/* Page numbers */}
  <div className="flex items-center gap-2">
    {Array.from({ length: pagination.total_pages }, (_, index) => (
      <button
        key={index + 1}
        onClick={() =>
          setPagination((prevPagination) => ({
            ...prevPagination,
            current_page: index + 1,
          }))
        }
        className={`px-3 py-1 rounded ${
          pagination.current_page === index + 1
            ? "bg-blue-500 text-white"
            : "bg-gray-200 hover:bg-gray-300"
        }`}
      >
        {index + 1}
      </button>
    ))}
  </div>
</div>
    </div>
  );
};

export default AccountLedger;
