import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import ReactSelect from "react-select";

function EditDisposal({ row }) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const userId = Cookies.get("employee_no");

  const [accounts, setAccounts] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    date_of_disposal: "",
    method_of_disposal: "Sold",
    selling_value: "",
    account_deposited_to: "",
    account_credited_to: "",
    account_debited_to: "",
  });

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // "yyyy-MM-dd"
  };

  useEffect(() => {
    if (row) {
      // Set form data based on the passed row data
      setFormData({
        id: row.id || "",
        date_of_disposal: formatDate(row.date_of_disposal) || "",
        method_of_disposal: row.method_of_disposal || "Sold",
        selling_value: row.selling_value || "",
        account_deposited_to: row.account_deposited_to || "",
        account_credited_to: row.account_credited_to || "",
        account_debited_to: row.account_debited_to || "",
      });
    }
  }, [row]);

  const fetchAccounts = async () => {
    try {
      const response = await axios.get(`${backendUrl}/account/getAllAccountsByFilters`, {
        params: { is_enabled: 1 },
      });
      const formattedAccounts = response.data.data.map((item) => ({
        id: item.account_id,
        name: `${item.account_code} - ${item.account_name}`,
      }));
      setAccounts(formattedAccounts);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, [backendUrl]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Ensure that the form is validated before submission
    if (!validateFormData()) return;

    const submissionData = {
      ...formData,
      created_by: userId || "defaultUserId",
    };

    if (!formData.id) {
      console.error("ID is missing in formData");
      alert("ID is missing, cannot submit!");
      return;
    }

    const url = `${backendUrl}/assets/assets/updateAssetsDisposals/${formData.id}`;
    console.log("Request URL:", url);

    try {
      await axios.put(url, submissionData);
      alert("Disposal updated successfully!");
      window.location.reload(); // Refresh the page to reflect changes
    } catch (error) {
      console.error("Error updating Disposal:", error);
      alert("An error occurred while updating the Disposal.");
    }
  };

  const validateFormData = () => {
    const requiredFields = [
      "date_of_disposal",
      "selling_value",
      "account_deposited_to",
      "account_credited_to",
      "account_debited_to",
    ];
    for (const field of requiredFields) {
      if (!formData[field]) {
        alert(`Please fill in the ${field.replace("_", " ")} field.`);
        return false;
      }
    }
    return true;
  };

  return (
    <div className="p-20 bg-white rounded shadow">
      <div className="max-w-8xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Edit Disposal
        </h2>
      </div>

      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center space-x-4 w-2/3 mx-auto"></div>
      </div>
      <form action="" className="px-28 mt-8">
        <div className="grid grid-cols-3 gap-6 text-[20px]">
          {" "}
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Date Of Disposal</label>
            <input
              type="date"
              name="date_of_disposal"
              value={formData.date_of_disposal}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-700 mb-2">
              Method Of Disposal
            </label>
            <input
              type="text"
              name="method_of_disposal"
              value={"Sold"}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="w-full">
            <label className="block text-gray-700 mb-2">Selling Value</label>
            <input
              type="number"
              name="selling_value"
              value={formData.selling_value}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="account_deposited_to"
              className="block text-gray-500 mb-2"
            >
              Account Deposited To
            </label>
            <ReactSelect
              id="account_deposited_to"
              name="account_deposited_to"
              options={accounts}
              getOptionLabel={(e) => `${e.name}`} // Display name as label
              getOptionValue={(e) => e.id} // Value will be the account id
              onChange={(selectedOption) =>
                setFormData((prevDetails) => ({
                  ...prevDetails,
                  account_deposited_to: selectedOption ? selectedOption.id : "",
                }))
              }
              value={accounts.find(
                (acc) => acc.id === formData.account_deposited_to
              )}
              className="w-full p-2 border rounded-lg"
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="account_credited_to"
              className="block text-gray-500 mb-2"
            >
              Account Credited To
            </label>
            <ReactSelect
              id="account_credited_to"
              name="account_credited_to"
              options={accounts}
              getOptionLabel={(e) => `${e.name}`} // Display name as label
              getOptionValue={(e) => e.id} // Value will be the account id
              onChange={(selectedOption) =>
                setFormData((prevDetails) => ({
                  ...prevDetails,
                  account_credited_to: selectedOption ? selectedOption.id : "",
                }))
              }
              value={accounts.find(
                (acc) => acc.id === formData.account_credited_to
              )}
              className="w-full p-2 border rounded-lg"
            />
          </div>
          <div className="w-full">
            <label
              htmlFor="account_debited_to"
              className="block text-gray-500 mb-2"
            >
              Account Debited To
            </label>
            <ReactSelect
              id="account_debited_to"
              name="account_debited_to"
              options={accounts}
              getOptionLabel={(e) => `${e.name}`} // Display name as label
              getOptionValue={(e) => e.id} // Value will be the account id
              onChange={(selectedOption) =>
                setFormData((prevDetails) => ({
                  ...prevDetails,
                  account_debited_to: selectedOption ? selectedOption.id : "",
                }))
              }
              value={accounts.find(
                (acc) => acc.id === formData.account_debited_to
              )}
              className="w-full p-2 border rounded-lg"
            />
          </div>
        </div>
        <button
          className="px-4 py-2 bg-black text-white rounded-md mt-12 grid justify-items-end"
          type="button"
          onClick={handleSubmit}
        >
          Save
        </button>
      </form>
    </div>
  );
}

export default EditDisposal;
