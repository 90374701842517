import React, { useState, useEffect } from 'react';

const AccountsTable = ({ data, level = 0, totals = {}, isRoot = false }) => {
    const [expandedRows, setExpandedRows] = useState({});

    const expandAllRows = (records) => {
        const expanded = {};
        const recursiveExpand = (recordList) => {
            recordList.forEach((record) => {
                expanded[record.account_id] = true;
                if (record.children && record.children.length > 0) {
                    recursiveExpand(record.children);
                }
            });
        };
        recursiveExpand(records);
        return expanded;
    };

    useEffect(() => {
        const expanded = expandAllRows(data);
        setExpandedRows(expanded);
    }, [data]);

    const toggleRowExpansion = (accountId) => {
        setExpandedRows((prevState) => ({
            ...prevState,
            [accountId]: !prevState[accountId]
        }));
    };

    const renderTotalsRow = (label, value) => (
        <tr className="border-t font-bold bg-gray-100">
            <td className="px-4 py-2">{label}</td>
            <td className="px-4 py-2 text-right">
                {parseFloat(value).toLocaleString()} LKR
            </td>
        </tr>
    );

    return (
        <table className="w-full border border-gray-200 text-left">
            {level === 0 && (
                <thead>
                <tr className="bg-gray-100">
                    <th className="px-4 py-2 border-b text-center">Account</th>
                    <th className="px-4 py-2 border-b text-center">Amount</th>
                </tr>
                </thead>
            )}
            <tbody>
            {data.map((record, index) => {
                const isLastRevenue =
                    record.root_type === 'Revenue' &&
                    (index === data.length - 1 ||
                        data[index + 1]?.root_type !== 'Revenue');

                const isLastExpense =
                    record.root_type === 'Expense' &&
                    (index === data.length - 1 ||
                        data[index + 1]?.root_type !== 'Expense');

                return (
                    <React.Fragment key={record.account_id}>
                        <tr className="border-b">
                            <td
                                className="px-4 py-2"
                                style={{ paddingLeft: `${level * 20}px` }}
                            >
                                {record.children && record.children.length > 0 && (
                                    <button
                                        onClick={() =>
                                            toggleRowExpansion(record.account_id)
                                        }
                                        className="mr-2 focus:outline-none"
                                    >
                                        {expandedRows[record.account_id] ? '▼' : '▶'}
                                    </button>
                                )}
                                {record.account_code} - {record.account_name}
                            </td>
                            <td className="px-4 py-2 text-right">
                                {parseFloat(record.amount).toLocaleString("en-US", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })} LKR
                            </td>
                        </tr>

                        {expandedRows[record.account_id] && record.children && (
                            <tr>
                                <td colSpan="2" className="p-0">
                                    <AccountsTable
                                        data={record.children}
                                        level={level + 1}
                                        totals={totals}
                                    />
                                </td>
                            </tr>
                        )}

                        {/* Render Total Revenue row below last Revenue account */}
                        {isLastRevenue &&
                            isRoot &&
                            renderTotalsRow(
                                'Total Revenue (Credit)',
                                totals.totalRevenue
                            )}

                        {/* Render Total Expense row below last Expense account */}
                        {isLastExpense &&
                            isRoot &&
                            renderTotalsRow(
                                'Total Expense (Debit)',
                                totals.totalExpense
                            )}
                    </React.Fragment>
                );
            })}

            {/* Show Profit row below all rows */}
            {isRoot && (
                <tr className="border-t-2 font-bold bg-green-100">
                    <td className="px-4 py-2 text-lg">Profit</td>
                    <td className="px-4 py-2 text-right text-lg">
                        {parseFloat(totals.profit).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })} LKR
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    );
};

export default AccountsTable;
