import React, {useEffect, useRef, useState} from "react";
import {IoAdd} from "react-icons/io5";
import {FaEdit, FaTrash} from "react-icons/fa";
import { BiDetail } from "react-icons/bi";
import axios from "axios";
import JournalEntryForm from "./journal_entry_form";
import JournalEntryDetailModal from "./journal_entry_detail_modal";
import Cookies from "js-cookie";
import {IoIosCloseCircleOutline, IoMdAddCircleOutline} from "react-icons/io";
import {TiTick} from "react-icons/ti";
import usePermissions from "../../../components/permissions/permission";
import Notification from "../../../components/notification/notification";

const JournalEntry = () => {
    const entryTypes = [
        'Journal Entry',
        'Inter Company Journal Entry',
        'Bank Entry',
        'Cash Entry',
        'Credit Card Entry',
        'Debit Note',
        'Credit Note',
        'Contra Entry',
        'Excise Entry',
        'Write Off Entry',
        'Opening Entry',
        'Depreciation Entry',
        'Exchange Rate Revaluation',
        'Exchange Gain Or Loss',
        'Deferred Revenue',
        'Deferred Expense'
    ];

    const { hasPermission } = usePermissions();

    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    const [selectedValue, setSelectedValue] = useState();
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState(null); // For storing the selected entry for the modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [notification, setNotification] = useState({ message: "", type: "" });
    const [selectedEntryType, setSelectedEntryType] = useState("");
    const [pagination, setPagination] = useState({
        current_page: 1,
        page_size: 20,
        total: 0,
        total_pages: 0,
    });

    const actionsRef = useRef(null);

    const handleRowSelect = (id) => {
        if (selectedRow === id) {
            setSelectedRow(null);
            setDropdownVisible(false);
        } else {
            setSelectedRow(id);
            setDropdownVisible(true);
        }
    };

    const togglePopup = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setSelectedValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const getTableData = async () => {
        try {
            const params = {
                page_size: rowsPerPage,
                page: pagination.current_page,
                ...selectedValue,
            };
            const response = await axios.get(`${backendUrl}/journalEntry/getJournalEntry`, {params});
            if (response.data) {
                setTableData(response.data.data);
                setPagination(response.data.pagination);
            } else {
                console.error("Unexpected response format:", response.data);
                setTableData([]);
            }
        } catch (error) {
            console.error("Error fetching journal entries:", error);
            setTableData([]);
        }
    };

    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPagination((prevPagination) => ({
            ...prevPagination,
            current_page: 1,
        }));
    };

    const handleFormSubmit = async (formData) => {
        try {
            if (formData.journal_entry_id) {
                await axios.put(`${backendUrl}/journalEntry/edit/${formData.journal_entry_id}`, formData);
            } else {
                await axios.post(`${backendUrl}/journalEntry/add`, formData);
            }
            await getTableData();
        } catch (error) {
            console.error("Error submitting form data:", error);
        }
    };

    const handleAdd = () => {
        setSelectedRow(null);
        togglePopup();
    };

    const handleEdit = () => {
        setDropdownVisible(false);
        // setFormData(row);
        togglePopup();
        console.log("selectedRow", selectedRow)
    };

    const handleDelete = async () => {
        console.log("Delete row: ", selectedRow);
        try {
            const response = await axios.delete(`${backendUrl}/journalEntry/deleteJournalEntry/${selectedRow}`, {
                data: { action_by: Cookies.get("employee_no") }
            });
            setDropdownVisible(false);
            setSelectedRow(null);
            console.log("Journal entry deleted:", response);
            await getTableData(); // Refresh table after deletion
        } catch (error) {
            console.error("Error deleting journal entry:", error);
        }
    };

    const handleDetail = async () => {
        try {
            if (selectedRow) {
                const response = await axios.get(`${backendUrl}/journalEntry/getJournalEntryById`, {
                    params: { id: selectedRow}
                });
                if (response.data) {
                    setSelectedEntry(response.data.journalEntry); // Set the selected entry data
                    setIsModalOpen(true); // Open the modal
                }
            }
        } catch (error) {
            console.error("Error fetching entry details:", error);
        }
    };

    const handleApprovalActions = async (status) => {
        try {
            const response = await axios.put(
                `${backendUrl}/journalEntry/submitOrApproveJournalEntry/${selectedRow}`, {
                    status: status, // Dynamically set the status
                    action_by: Cookies.get("employee_no")
                }
            );
            await getTableData();
            setDropdownVisible(!dropdownVisible);
        } catch (error) {
            console.log(error);
        }
    };


    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Close dropdown when clicking outside
    const handleClickOutside = (event) => {
        if (actionsRef.current && !actionsRef.current.contains(event.target)) {
            setSelectedRow(null);
            setDropdownVisible(false);
        }
    };

    const toggleUploadModal = () => setIsUploadModalOpen(!isUploadModalOpen);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUploadSubmit = async () => {
        if (!selectedFile) {
            setNotification({
                message: "Please select a file to upload.",
                type: "error",
            });
            return;
        }

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("created_by", Cookies.get("employee_no"));
        formData.append("entry_type", selectedEntryType);

        try {
            const response = await axios.post(`${backendUrl}/journalEntry/openingEntriesBulkUpload`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setNotification({
                message: response.data.message,
                type: "success",
            });
            toggleUploadModal();
            setSelectedFile(null);
            await getTableData()
        } catch (error) {
            setSelectedFile(null);
            setNotification({
                message: error?.response?.data?.error || "File upload failed.",
                type: "error",
            });
        }
    };

    const handleCloseNotification = () => {
        setSelectedFile(null);
        setNotification({ message: "", type: "" });
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        getTableData();
    }, [rowsPerPage, pagination.current_page, selectedValue]);

    return (
        <div className="mx-10 mt-5 text-xl">
            <div className="overflow-visible">
                <div className="flex items-center justify-between mt-6">
                    <div>
                        <p className="text-[30px] font-semibold text-black">Journal Entries</p>
                    </div>
                </div>

                <div className="mt-5">
                    <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
                        <div className="flex flex-col md:flex-row md:items-center md:gap-4">
                            {/* Entry Type Filter */}
                            <div className="w-full md:w-[250px]">
                                <label className="block mb-2 text-sm font-medium text-black">Entry Type</label>
                                <select
                                    id="entry_type"
                                    name="entry_type"
                                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                                    onChange={handleChange}
                                >
                                    <option value=""></option>
                                    {entryTypes.map((type) => (
                                        <option key={type} value={type}>
                                            {type}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Status Filter */}
                            <div className="w-full md:w-[200px]">
                                <label className="block mb-2 text-sm font-medium text-black">Status</label>
                                <select
                                    id="status"
                                    name="status"
                                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                                    onChange={handleChange}
                                >
                                    <option value=""></option>
                                    <option value="Draft">Draft</option>
                                    <option value="Submitted">Submitted</option>
                                    <option value="Approved">Approved</option>
                                    <option value="Rejected">Rejected</option>
                                </select>
                            </div>

                            {/* Reference Number Filter */}
                            <div className="w-full md:w-[200px]">
                                <label className="block mb-2 text-sm font-medium text-black">Reference Number</label>
                                <input
                                    id="reference_number"
                                    name="reference_number"
                                    type="text"
                                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        {/* Right-Side Button or Dropdown */}
                        <div className="relative flex items-center gap-4 md:mt-0"
                             id="actions"
                             ref={actionsRef}>
                            {selectedRow ? (
                                // Action Dropdown
                                <div className="relative">
                                    <button
                                        onClick={() => setDropdownVisible(!dropdownVisible)}
                                        className="px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                                    >
                                        Actions
                                    </button>
                                    {dropdownVisible && (
                                        <div
                                            className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg">
                                            {hasPermission(3092) && (
                                                <button
                                                    onClick={handleEdit}
                                                    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                                                >
                                                    <FaEdit className="mr-2"/> Edit
                                                </button>
                                            )}
                                            {hasPermission(3093) && (
                                                <button
                                                    onClick={handleDelete}
                                                    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                                                >
                                                    <FaTrash className="mr-2"/> Delete
                                                </button>
                                            )}

                                            <button
                                                onClick={handleDetail}
                                                className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                                            >
                                                <BiDetail className="mr-2"/> Details
                                            </button>

                                            {hasPermission(3095) && (
                                                <button
                                                    onClick={() => handleApprovalActions('Submitted')}
                                                    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                                                >
                                                    <IoMdAddCircleOutline className="mr-2"/> Submit
                                                </button>
                                            )}

                                            {hasPermission(3096) && (
                                                <button
                                                    onClick={() => handleApprovalActions('Approved')}
                                                    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                                                >
                                                    <TiTick className="mr-2"/> Approve
                                                </button>
                                            )}

                                            {hasPermission(3097) && (
                                                <button
                                                    onClick={() => handleApprovalActions('Rejected')}
                                                    className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                                                >
                                                    <IoIosCloseCircleOutline className="mr-2"/> Reject
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </div>
                            ) : (
                                // Add Button
                                <>
                                    {/* Bulk Upload Button */}
                                    <button
                                        className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                                        onClick={toggleUploadModal}
                                    >
                                        <IoAdd className="text-xl" />
                                        Bulk Upload
                                    </button>
                                    {/* Add Button */}
                                    <button
                                        className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                                        onClick={handleAdd}
                                    >
                                        <IoAdd className="text-xl" />
                                        Add
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>

                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={handleCloseNotification}
                />

                {/* Journal Entry Table */}
                <table className="min-w-full mt-5 border-collapse">
                    <thead>
                    <tr className="bg-gray-200">
                        <th className="px-4 py-2 text-left">Entry Id</th>
                        <th className="px-4 py-2 text-left">Entry Type</th>
                        <th className="px-4 py-2 text-left">Reference Number</th>
                        <th className="px-4 py-2 text-left">Entry Date</th>
                        <th className="px-4 py-2 text-left">Approved Date</th>
                        <th className="px-4 py-2 text-left">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.map((entry) => (
                        <tr
                            key={entry.journal_entry_id}
                            className="hover:bg-gray-100"
                            onClick={() => handleRowSelect(entry.journal_entry_id)}
                        >
                            <td className="px-4 py-2">{entry.journal_entry_id_ai}</td>
                            <td className="px-4 py-2">{entry.entry_type}</td>
                            <td className="px-4 py-2">{entry.reference_number}</td>
                            <td className="px-4 py-2">{new Date(entry.entry_date).toLocaleDateString()}</td>
                            <td className="px-4 py-2">{entry.approved_date ? new Date(entry.approved_date).toLocaleDateString() : ""}</td>
                            <td className="px-4 py-2">{entry.status}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                {/* Pagination Controls */}
                <div className="flex justify-between mt-5">
                    <div>
                        <label className="block mb-2 text-sm font-medium text-black">
                            Rows per page:
                        </label>
                        <select
                            value={rowsPerPage}
                            onChange={(e) => handleRowsPerPageChange(parseInt(e.target.value))}
                            className="p-2 border border-black rounded focus:ring-2 focus:ring-black"
                        >
                            <option value={20}>20</option>
                            <option value={100}>100</option>
                            <option value={500}>500</option>
                            <option value={2500}>2500</option>
                        </select>
                    </div>
                    <div>
            <span>
              Page {pagination.current_page} of {pagination.total_pages}
            </span>
                        <button
                            onClick={() =>
                                setPagination((prev) => ({...prev, current_page: prev.current_page - 1}))
                            }
                            disabled={pagination.current_page === 1}
                            className="px-4 py-2 ml-2 bg-black text-white rounded hover:bg-gray-800"
                        >
                            Previous
                        </button>
                        <button
                            onClick={() =>
                                setPagination((prev) => ({...prev, current_page: prev.current_page + 1}))
                            }
                            disabled={pagination.current_page === pagination.total_pages}
                            className="px-4 py-2 ml-2 bg-black text-white rounded hover:bg-gray-800"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                    <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
                        <JournalEntryForm
                            isOpen={isOpen}
                            togglePopup={togglePopup}
                            onSubmit={handleFormSubmit}
                            initialData={selectedRow ? tableData.find((item) => item.journal_entry_id === selectedRow) : null}
                        />
                    </div>
                </div>
            )}
            {/* Modal for details */}
            <JournalEntryDetailModal
                entry={selectedEntry}
                isOpen={isModalOpen}
                onClose={closeModal}
            />
            {/* Bulk Upload Modal */}
            {isUploadModalOpen && (
                <div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                    style={{ backdropFilter: "blur(2px)" }}
                >
                    <div
                        className="bg-white p-6 rounded shadow-xl"
                        style={{zIndex: 60}}
                    >
                        <h3 className="text-2xl font-semibold mb-6">Bulk Upload Journal Entries</h3>
                        <div>
                            <label className="block mb-2 text-sm font-normal text-black">
                                Entry Type:
                            </label>
                            <select
                                className="w-full p-2 mb-5 border border-gray-300 rounded focus:ring-2 focus:ring-black"
                                value={selectedEntryType}
                                onChange={(e) => setSelectedEntryType(e.target.value)}
                            >
                                <option value="">Select Entry Type</option>
                                {entryTypes.map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <input
                            type="file"
                            accept=".csv"
                            onChange={handleFileChange}
                            className="mb-4 border border-gray-300 rounded px-2 py-1"
                        />
                        <div className="flex justify-end gap-4">
                            <button
                                onClick={toggleUploadModal}
                                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleUploadSubmit}
                                className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                            >
                                Upload
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default JournalEntry;
