import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { HiDocumentCheck } from "react-icons/hi2";
import { RiDraftFill } from "react-icons/ri";
import EditDisposal from "./edit_disposal"

function DisposalTable() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const userId = Cookies.get("employee_no");
  const actionsRef = useRef(null);

  // State to store the disposals data
  const [disposals, setDisposals] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state
  const [error, setError] = useState(null); // Track error state
  const [selectedRow, setSelectedRow] = useState(null); 
  const [actionsVisible, setActionsVisible] = useState(false); // Dropdown visibility
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [tableData, setTableData] = useState([]);
  
  const [formData, setFormData] = useState({
    date_of_disposal: "",
    method_of_disposal: "Sold",
    selling_value: "",
    account_deposited_to: "",
    account_credited_to: "",
    account_debited_to: "",
  });

  // Fetch disposals data from the backend
  const fetchDisposals = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/assets/assets/getAssetDisposals`
      );
      console.log("API Response:", response.data); // Log the response to confirm data structure

      // Handle API response data structure
      const categoriesData = Array.isArray(response.data)
        ? response.data
        : response.data?.data || [];
      setDisposals(categoriesData); // Set the data to disposals state
    } catch (error) {
      console.error("Error fetching disposals:", error);
      setError("An error occurred while fetching disposals.");
    } finally {
      setLoading(false); // Set loading to false after the API call finishes
    }
  };

  useEffect(() => {
    fetchDisposals();
  }, [backendUrl]); // Only re-fetch data if backend URL changes

  const updateRowStatus = async (status) => {
    if (!selectedRow || !selectedRow.id) {
      console.error("No row selected or missing ID.");
      return;
    }

    const url = `${backendUrl}/assets/assets/ApproveAssetDisposal/${selectedRow.id}`;
    console.log("API URL:", url);

    try {
      const response = await axios.put(url, {
        approval_status: status,
        action_by: userId,
      });

      console.log("API Response:", response.data);

      if (response.data.success) {
        setNotification({
          message: `Status updated to "${status}"`,
          type: "success",
        });

        // Update local table data with the new status
        setDisposals((prevData) =>
          prevData.map((row) =>
            row.id === selectedRow.id
              ? { ...row, approval_status: status }
              : row
          )
        );
      } else {
        setNotification({
          message: response.data.message || "Failed to update status.",
          type: "error",
        });
      }
      window.location.reload()
    } catch (error) {
      console.error(
        "Error updating status:",
        error.response?.data || error.message
      );
      setNotification({ message: "Error updating status.", type: "error" });
    }
  };

  // Handle action click
  const handleActionClick = (action) => {
    console.log(`Action "${action}" executed for Asset ID: ${selectedRow?.id}`);

    // Map actions to their respective statuses
    const statusMap = {
      Approve: "Approved",
      Draft: "Draft",
      Submitted: "Submitted",
      Reject: "Rejected",
    };

    if (statusMap[action]) {
      updateRowStatus(statusMap[action]);
    }

    setActionsVisible(false);
  };

  const getAvailableActions = (status) => {
    switch (status) {
      case "Draft":
        return ["Approve", "Reject"];
      case "Submitted":
        return ["Approve", "Reject"];
      case "Rejected":
        return ["Draft"];
      default:
        return [];
    }
  };

  const handleRowClick = (row) => {
    if (selectedRow?.id === row.id) {
      setSelectedRow(null); // Deselect row
    } else {
      setSelectedRow(row); // Set selected row
    }
    setActionsVisible(true);
  };
  

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setActionsVisible(false);
        setSelectedRow(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const editePopup = () => {
    setIsOpenEdit(!isOpenEdit);
  };

  /*const handleCloseEditPopup = () => {
    setIsOpenEdit(false); // Ensures the modal is closed
    window.history.replaceState(null, null, "/finance-management/assets");
  };*/

  const handleEdit = () => {
    console.log("Edit row: ", selectedRow);
    if (selectedRow) {
      setIsOpenEdit(true);
    } else {
      alert("Please select a row to edit.");
    }
  };
  
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="">
        <h2 className="text-3xl  mt-8 flex justify-center font-bold">
          Disposal Table
        </h2>
      </div>

      <div className="mt-5">
        <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
          <div className="flex flex-col md:flex-row md:items-center md:gap-4 ">


          <div ref={actionsRef} className="relative ">
            <button
              className="flex justify-end px-4 py-2 bg-black text-white rounded hover:bg-gray-800 focus:outline-none  "
              onClick={() => setActionsVisible(!actionsVisible)}
              disabled={!selectedRow}
            >
              Actions
            </button>

            {actionsVisible && selectedRow && (
              <div
                className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg"
                style={{ width: "150px" }}
              >
                {/* Always show Edit and Delete */}
                <button
                  className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                  onClick={() => handleEdit(selectedRow.id)}
                >
                  <FaEdit className="mr-2" />
                  Edit
                </button>
           

           
                {/* Render dynamic actions based on the approval_status */}
                {getAvailableActions(selectedRow.approval_status).map(
                  (action) => (
                    <button
                      key={action}
                      className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                      onClick={() => handleActionClick(action)}
                    >
                      {action === "Approve" && <TiTick className="mr-2" />}
                      {action === "Draft" && <RiDraftFill className="mr-2" />}
                      {action === "Submitted" && (
                        <HiDocumentCheck className="mr-2" />
                      )}
                      {action === "Reject" && (
                        <IoIosCloseCircleOutline className="mr-2" />
                      )}
                      {action}
                    </button>
                  )
                )}
              </div>
            )}
          </div>
          </div>
        </div>
      </div>

      {/* Show loading indicator or error message if something goes wrong */}
      {loading && <p className="text-center text-gray-500">Loading...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}

      {/* Disposal Table */}
      <div className="overflow-x-auto mt-10">
        <table className="w-full bg-white border border-black rounded-lg">
          <thead className="bg-gray-300">
            <tr>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[250px]">
                Assets ID
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                Asset Name
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[150px]">
                Date Of Disposal
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                Approval Status
              </th>
              <th className="py-2 px-4 text-center bg-gray-300 border-b whitespace-nowrap w-[200px]">
                Method Of Disposal
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Map through the disposals data to display rows */}
            {disposals.length > 0 ? (
              disposals.map((row) => (
                <tr
                  key={row.id}
                  onClick={() => handleRowClick(row)}
                  className={`text-center cursor-pointer ${selectedRow?.id === row.id ? "bg-gray-100" : ""}`}
                >
                  <td className="py-2 px-4 text-center border-b">{row.assets_id}</td>
                  <td className="py-2 px-4 text-center border-b">{row.asset_name}</td>
                  <td className="py-2 px-4 text-center border-b">
                    {row.date_of_disposal
                      ? new Date(row.date_of_disposal).toLocaleDateString()
                      : ""}
                  </td>
                  <td className="py-2 px-4 text-center border-b">
                    {row.approval_status}
                  </td>
                  <td className="py-2 px-4 text-center border-b">
                    {row.method_of_disposal}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center py-4">
                  No disposals found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
      {isOpenEdit && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg relative">
            <button
              type="button"
              onClick={editePopup}
              className="absolute text-4xl top-4 right-4 text-gray-500 hover:text-black"
            >
              <IoIosCloseCircleOutline />
            </button>
            <EditDisposal editePopup={editePopup} row={selectedRow} />
          </div>
        </div>
      )}
    </div>
  );
}

export default DisposalTable;
