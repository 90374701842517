import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import AccountsTable from './AccountsTable';

const TrialBalance = () => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    const [data, setData] = useState([]);
    const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filterDates, setFilterDates] = useState({
        start: new Date(new Date().getFullYear(), 0, 1),
        end: new Date()
    });

    const fetchTrialBalanceData = async () => {
        try {
            const response = await axios.get(`${backendUrl}/financeReports/getTrialBalance`, {
                params: { start_date: filterDates.start, end_date: filterDates.end },
            });
            setData(response.data);

            // Filter first-level accounts and calculate totals
            const firstLevelAccounts = response.data.filter(account => !account.parent_account_id);

            const totalDebit = firstLevelAccounts.reduce(
                (sum, account) => sum + parseFloat(account?.total_debit || 0),
                0
            );

            const totalCredit = firstLevelAccounts.reduce(
                (sum, account) => sum + parseFloat(account?.total_credit || 0),
                0
            );

            setTotalDebit(totalDebit.toFixed(2));
            setTotalCredit(totalCredit.toFixed(2));
        } catch (err) {
            console.error("Error fetching Trial Balance data", err);
        }
    };

    useEffect(() => {
        fetchTrialBalanceData();
    }, [filterDates]);

    const applyDateFilter = () => {
        setFilterDates({ start: startDate, end: endDate });
    };

    const flattenData = (data) => {
        const flatData = [];

        const recursivelyFlatten = (items) => {
            items.forEach((item) => {
                flatData.push({
                    account_code: item.account_code,
                    account_name: item.account_name,
                    debit: item.total_debit,
                    credit: item.total_credit,
                });

                if (item.children && item.children.length > 0) {
                    recursivelyFlatten(item.children);  // Flatten child accounts recursively
                }
            });
        };

        recursivelyFlatten(data);  // Start flattening from the root

        return flatData;
    };

    const downloadReportAsExcel = () => {
        // Flatten the data
        const flattenedData = flattenData(data);

        // Calculate totals for the whole hierarchy
        const totalDebit = flattenedData.reduce(
            (sum, account) => sum + parseFloat(account.debit || 0),
            0
        );
        const totalCredit = flattenedData.reduce(
            (sum, account) => sum + parseFloat(account.credit || 0),
            0
        );

        // Add a total row at the end
        flattenedData.push({
            account_code: 'Total',
            account_name: 'Total',
            debit: totalDebit.toFixed(2),
            credit: totalCredit.toFixed(2),
        });

        // Create worksheet and workbook
        const worksheet = XLSX.utils.json_to_sheet(flattenedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'TrialBalance');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'TrialBalanceReport.xlsx');
    };


    return (
        <div className="max-w-7xl mx-auto px-6 py-8">
            <h1 className="text-3xl font-semibold text-gray-800 mb-6">Trial Balance</h1>

            <div className="flex gap-4 mb-6">
                <div className="bg-white shadow-md rounded-lg p-4 w-1/2">
                    <h3 className="font-medium text-gray-700">Total Debit</h3>
                    <p className="text-2xl font-bold">{parseFloat(totalDebit).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })} LKR</p>
                </div>
                <div className="bg-white shadow-md rounded-lg p-4 w-1/2">
                    <h3 className="font-medium text-gray-700">Total Credit</h3>
                    <p className="text-2xl font-bold">{parseFloat(totalCredit).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })} LKR</p>
                </div>
            </div>

            <div className="flex gap-4 mb-6">
                <input
                    type="date"
                    onChange={(e) => setStartDate(e.target.value)}
                    className="border border-gray-300 rounded-lg p-2"
                />
                <input
                    type="date"
                    onChange={(e) => setEndDate(e.target.value)}
                    className="border border-gray-300 rounded-lg p-2"
                />
                <button
                    onClick={applyDateFilter}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                >
                    Apply Filter
                </button>
                <button
                    onClick={downloadReportAsExcel}
                    className="px-4 py-2 bg-green-700 text-white rounded-lg hover:bg-green-600 ml-auto"
                >
                    Download as Excel
                </button>
            </div>

            <div className="bg-white shadow-md rounded-lg p-4 mb-6">
                <AccountsTable data={data} />
            </div>
        </div>
    );
};

export default TrialBalance;
