import React, { useState, useEffect } from "react";
import axios from "axios";
import AccountsTable from "./AccountsTable"; // Import the AccountsTable component

const AccountTreeView = () => {
  const [hierarchicalData, setHierarchicalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const apiUrl = `${process.env.REACT_APP_BACKEND_URL_FINANCE}/account/getAllAccountsByFilters`;

  // Function to build a hierarchical structure from flat data
  const buildHierarchy = (data) => {
    const map = {};
    const roots = [];

    // Initialize a map to store references to each account
    data.forEach((item) => {
      map[item.account_id] = { ...item, children: [] };
    });

    // Build the hierarchy by linking children to parents
    data.forEach((item) => {
      if (item.parent_account_id === null || !map[item.parent_account_id]) {
        // Top-level accounts (no parent or parent doesn't exist)
        roots.push(map[item.account_id]);
      } else {
        // Nested accounts
        map[item.parent_account_id].children.push(map[item.account_id]);
      }
    });

    return roots;
  };

  // Fetch data from the API
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(apiUrl); // Fetch data from API
      console.log("API Response:", response.data); // Debugging: Log the API response
      const accounts = response.data.data || []; // Ensure accounts array exists
      if (accounts.length === 0) {
        console.warn("No accounts found in API response.");
      }
      const hierarchy = buildHierarchy(accounts); // Build tree structure
      console.log("Hierarchical Data:", hierarchy); // Debugging: Log the hierarchy
      setHierarchicalData(hierarchy);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching accounts:", err);
      setError("Failed to load account data.");
      setLoading(false);
    }
  };

  // Fetch the account data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Tree View</h1>
      {loading ? (
        <p>Loading accounts...</p>
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : hierarchicalData.length > 0 ? (
        <AccountsTable data={hierarchicalData} />
      ) : (
        <p>No parent or child account data available.</p>
      )}
    </div>
  );
};

export default AccountTreeView;
